import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { AuthenticatedTemplate } from '@azure/msal-react';
import PageHeader from '../components/common/PageHeader';
import { getCurrentAccountRep } from '../api/ContactUsService';
import AccountContext from '../contexts/AccountContext';
import { ACCOUNT_TYPES } from '../utils/applicationConstants';

const ContactUs = () => {
  const [repInformation, setRepInformation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { account } = useContext(AccountContext);
  useEffect(async () => {
    const repInformationResponse = await getCurrentAccountRep();
    setRepInformation(repInformationResponse);
    setIsLoading(false);
  }, []);
  if (isLoading) {
    return <CircularProgress />;
  }
  function renderContactRep() {
    if (account.accountType === ACCOUNT_TYPES.HCP
        || account.accountType === ACCOUNT_TYPES.AccountManager) {
      return (
        <div>
          <PageHeader>Contact Your Rep</PageHeader>
          <p>
            Below you will find the name of the Grifols Rep aligned to your primary
            zip code. If you&apos;ve reviewed the FAQs in the Learning Center and have
            not found an answer to your question about how to best use the
            AlphaID Results Portal, please contact your Grifols Rep using the
            information below.
          </p>
          <p>
            Your Rep&apos;s Name Is:
            {' '}
            {repInformation.name}
          </p>
          <p>
            Email Address:
            {' '}
            <a href={`mailto:${repInformation.email}?subject=AlphaID%20Results%20Portal%20-%20%5BYour%20Topic%5D`}>
              {repInformation.email}
            </a>
          </p>
        </div>
      );
    }
    return null;
  }
  return (
    <>
      <div>
        <PageHeader>Contact the Lab</PageHeader>
        <p>
          If you&apos;ve reviewed the FAQs in the Learning Center and have not
          found an answer to your question, please contact Trillium
          using one of the options below.
        </p>
        <p>
          Email:
          {' '}
          <a href="mailto:alphaid@trillium-health.com?subject=AlphaID Results Portal- [Your Topic]">alphaid@trillium-health.com</a>
        </p>
        <p>
          Phone Number: 1-888-261-2812
          <a href="tel:8882612812">(888-261-2812)</a>
        </p>
      </div>
      <div>
        <PageHeader>Contact a Portal Administrator</PageHeader>
        <p>
          Email:
          {' '}
          <a href="mailto:alphaidportal@grifols.com?subject=AlphaID Results Portal- [Your Topic]">alphaidportal@grifols.com</a>
        </p>
      </div>
      <AuthenticatedTemplate>
        {renderContactRep()}
      </AuthenticatedTemplate>
    </>
  );
};

export default ContactUs;
