import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useTableStylesHcp } from '../styles/TableStylesHcp';
import { COLORS } from '../utils/applicationConstants';
import { SortIcon } from '../utils/Icons';

const useStyles = makeStyles(({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  columnHeader: {
    color: COLORS.WHITE,
    textTransform: 'none',
    height: '64px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    width: '100%',
    '&:disabled': {
      color: COLORS.WHITE,
    },
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  row: {
    width: '100% !important',
  },
  managerViewRenderingZone: {
    width: '100% !important',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const PracticeTable = (props) => {
  const classes = useTableStylesHcp();
  const styleClasses = useStyles();
  const history = useHistory();
  const {
    results, handlePageChangeHandler,
    isTableLoading,
  } = props;
  const { pageNumber, pageSize, totalRecords } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;
  function TableSortIcon() {
    const hcpTableStyles = useTableStylesHcp();
    return (
      <span
        className={hcpTableStyles.statusIcon}
        style={{
          marginTop: '13px',
        }}
      >
        <SortIcon color={COLORS.WHITE} />
      </span>
    );
  }
  function CustNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.noRowsOverlay}>
          <SearchIcon fontSize="large" />
          <Typography className={styleClasses.noRowsHeader}>
            Sorry, there are no results for your search.
          </Typography>
          <Typography className={styleClasses.nowRowsParagraph}>
            Please make sure you have entered your search correctly. If you
            have entered the correct format and cannot find your result, please
            visit the Learning Center to learn about shelf life and turnaround time.
            If you continue to experience issues, visit Contact Us.
          </Typography>
        </div>
      </GridOverlay>
    );
  }

  function CustLoadingOverlay() {
    return (
      <GridOverlay>
        { /* eslint-disable-next-line object-curly-newline */}
        <div className={styleClasses.loadingOverlay}>
          <CircularProgress fontSize="large" />
        </div>
      </GridOverlay>
    );
  }

  function goToPracticeView(currentRow) {
    if (currentRow.row.practiceId) {
      history.push(`/practices/${currentRow.row.practiceId}`, {
        currentRow: currentRow.row,
      });
    }
  }
  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const columns = [
    {
      field: 'acctManagerFirstName',
      headerName: 'Account Manager First Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>First Name</p>
        </Button>
      ),
    },
    {
      field: 'acctManagerLastName',
      headerName: 'Account Manager Last Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>Last Name</p>
        </Button>
      ),
    },
    {
      field: 'acctManagerEmail',
      headerName: 'Email Address',
      sortable: false,
      width: 555,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p> Email Address </p>
        </Button>
      ),
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>Practice Name</p>
        </Button>
      ),
    },
  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChangeHandler}
          hideFooterPagination
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          disableColumnResize
          disableExtendRowFullWidth
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: `${classes.row} ${styleClasses.pointer}`,
            columnHeader: classes.columnHeader,
          }}
          rows={results}
          columns={columns}
          components={{
            ColumnSortedDescendingIcon: TableSortIcon,
            ColumnSortedAscendingIcon: TableSortIcon,
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          onRowDoubleClick={goToPracticeView}
        />
      </div>
    </>
  );
};
export default PracticeTable;
