import React from 'react';
import { makeStyles,
  Modal,
  Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  modal: {
    overflow: 'scroll',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  thRoot: {
    borderRadius: '15px',
  },
}));

const MissingLocationModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { modalOpen, setModalOpen } = props;

  function handleModalClose() {
    setModalOpen(false);
  }

  function redirectToMyPractice() {
    history.push('/my-practice');
  }

  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
      className={classes.modal}
    >
      <Paper className={classes.modalBackground}>
        <span className={classes.title}>
          You have not yet assigned any locations to your practice.
        </span>
        <p>
          It is important that you add at least one location to your practice to
          ensure you receive your practice&apos;s test results correctly. Please
          go to the My Practice tab and click on the Add Location button, located
          above the My Practice Locations table, to search for and add your practice&apos;s
          locations.
        </p>
        <ButtonComponent
          onClick={redirectToMyPractice}
        >
          Go to My Practice
        </ButtonComponent>
      </Paper>
    </Modal>
  );
};

export default MissingLocationModal;
