import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, MenuList,
  MenuItem, Tabs, Tab,
  AppBar } from '@material-ui/core';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';
import { ACCOUNT_TYPES, COLORS, UTILITY_NAVIGATION } from '../utils/applicationConstants';
import Logo from '../images/logo.svg';
import { loginRequest } from '../utils/authConfig';
import AccountContext from '../contexts/AccountContext';
import { getMainNavigation } from '../utils/NavigationLinksByRole';
import InterstitialPrompt from './common/InterstitialPrompt';
import { activeTabHandler } from '../utils/helpers';

const DesktopNavigation = () => {
  const { account } = useContext(AccountContext);
  const useStyles = makeStyles(() => ({
    utilityMenuRoot: {
      backgroundColor: COLORS.AZURE,
    },
    appBarRoot: {
      backgroundColor: COLORS.WHITE,
      boxShadow: 'none',
      border: '1px solid #DDDEE6',
    },
    appBarContentContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      height: '110px',
      flexFlow: 'row',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    utilityNavRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    utilityNavItem: {
      color: COLORS.WHITE,
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
    },
    tabListRoot: {
      height: '100%',
      '& .MuiTabs-indicator': {
        backgroundColor: COLORS.AZURE_RAD,
        height: '4px',
      },
    },
    tabListFlexContainer: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    tabRoot: {
      color: COLORS.ABBEY,
      height: '100%',
      minWidth: account.accountType === ACCOUNT_TYPES.AccountManager ? '105px' : '120px',
      '&:not(:last-child)': {
        marginRight: '47px',
      },
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [utilityNavigationModal, setUtilityNavigationModal] = useState(false);
  const { instance } = useMsal();
  const currentMainNavigation = getMainNavigation(account.accountType);
  const windowLoc = useLocation();
  const [showMainNav, setShowMainNav] = useState(false);
  useEffect(() => {
    if (window.location.pathname === '/details') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/details/success') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/denied') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/success') {
      setShowMainNav(false);
      return;
    }
    setShowMainNav(true);
  }, [windowLoc]);

  useEffect(() => {
    setValue(activeTabHandler(currentMainNavigation));
  }, [currentMainNavigation, windowLoc]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  function handleLogout() {
    instance.logoutRedirect(loginRequest);
  }

  if (!showMainNav) {
    return (
      <>
        <div className={classes.utilityMenuRoot}>
          <MenuList classes={{
            root: classes.utilityNavRoot,
          }}
          >
            {UTILITY_NAVIGATION.map((item) => {
              if (item.interstitial) {
                return (
                  <MenuItem
                    classes={{
                      root: classes.utilityNavItem,
                    }}
                    key={item.name}
                    onClick={() => setUtilityNavigationModal(true)}
                  >
                    {item.name}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  classes={{
                    root: classes.utilityNavItem,
                  }}
                  to={item.link}
                  component={Link}
                  key={item.name}
                >
                  {item.name}
                </MenuItem>
              );
            })}
            <AuthenticatedTemplate>
              <MenuItem
                classes={{
                  root: classes.utilityNavItem,
                }}
                onClick={handleLogout}
              >
                Log Out
              </MenuItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <MenuItem
                classes={{
                  root: classes.utilityNavItem,
                }}
                onClick={handleLogin}
              >
                Login
              </MenuItem>
            </UnauthenticatedTemplate>
          </MenuList>
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        <div className={classes.utilityMenuRoot}>
          <MenuList classes={{
            root: classes.utilityNavRoot,
          }}
          >
            {UTILITY_NAVIGATION.map((item) => {
              if (item.interstitial) {
                return (
                  <MenuItem
                    classes={{
                      root: classes.utilityNavItem,
                    }}
                    key={item.name}
                    onClick={() => setUtilityNavigationModal(true)}
                  >
                    {item.name}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  classes={{
                    root: classes.utilityNavItem,
                  }}
                  to={item.link}
                  component={Link}
                  key={item.name}
                >
                  {item.name}
                </MenuItem>
              );
            })}
            <AuthenticatedTemplate>
              <MenuItem
                classes={{
                  root: classes.utilityNavItem,
                }}
                onClick={handleLogout}
              >
                Log Out
              </MenuItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <MenuItem
                classes={{
                  root: classes.utilityNavItem,
                }}
                onClick={handleLogin}
              >
                Login
              </MenuItem>
            </UnauthenticatedTemplate>
          </MenuList>
        </div>
        <div>
          <div>
            <AppBar
              classes={{
                root: classes.appBarRoot,
              }}
              position="relative"
            >
              <div className={classes.appBarContentContainer}>
                <UnauthenticatedTemplate>
                  <a href="/">
                    <img src={Logo} alt="Navigation Logo" />
                  </a>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <a href={currentMainNavigation[0]?.link}>
                    <img src={Logo} alt="Navigation Logo" />
                  </a>
                  <Tabs
                    classes={{
                      root: classes.tabListRoot,
                      flexContainer: classes.tabListFlexContainer,
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                  >
                    {currentMainNavigation.map((item, index) => (
                      <Tab
                        key={item.name}
                        classes={{
                          root: classes.tabRoot,
                        }}
                        component={Link}
                        label={item.name}
                        fullWidth={false}
                        to={item.link}
                        {...a11yProps(index)}
                      />
                    ))}

                  </Tabs>
                </AuthenticatedTemplate>
              </div>
            </AppBar>
          </div>
        </div>
      </div>
      <InterstitialPrompt
        modalOpen={utilityNavigationModal}
        setModalOpen={setUtilityNavigationModal}
        siteUrl="https://www.prolastin.com/en/hcp"
        copy='You will be directed to a product website to learn more about a treatment option for Alpha-1 antitrypsin deficiency. If you would like to continue to the site, click "OK."If you do not wish to leave this site, select "Close."'
        title="You are leaving the AlphaID Results Portal."
      />
    </>
  );
};

export default DesktopNavigation;
