import { axiosInstance } from '../utils/AxiosConfiguration';

async function getPractices(pageNumber, pageSize, emailFilter) {
  let response;
  try {
    response = await axiosInstance.post('GetPractices', {
      paginationFilter: {
        pageNumber,
        pageSize,
      },
      emailFilter,
    });
  } catch (e) {
    Error(e);
  }
  response.data.data.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    item.id = index + 1;
  });
  return response.data;
}

async function getPractice(practiceId) {
  let response;
  try {
    response = await axiosInstance.get('/GetPractice', {
      params: {
        id: practiceId,
      },
    });
  } catch (e) {
    Error(e);
  }
  response.data.tableRows.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    item.id = index + 1;
  });
  response.data.practiceLocations.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    item.id = index + 1;
  });

  return response.data;
}

async function addLocationToPractice(uiLocationId, isUnifiedLocation, practiceId) {
  let response;
  try {
    response = await axiosInstance.post('/AddLocationToPractice', {
      uiLocationId,
      isUnifiedLocation,
      practiceId,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function willLocationRemovalHideResults(uiLocationId, isUnifiedLocation, practiceId) {
  let response;
  try {
    response = await axiosInstance.post('/WillLocationRemovalHideResults', {
      uiLocationId,
      isUnifiedLocation,
      practiceId,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function removeLocationFromPractice(uiLocationId, isUnifiedLocation, practiceId) {
  let response;
  try {
    response = await axiosInstance.post('/RemoveLocationFromPractice', {
      uiLocationId,
      isUnifiedLocation,
      practiceId,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function searchLocations(addressLine1 = '', addressLine2 = '', city = '', state = '', zipCode = '') {
  let response;
  try {
    response = await axiosInstance.post('/SearchLocations', {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function getUnifiedLocationIdToLocationIdMap(unifiedLocationId) {
  let response;
  try {
    response = await axiosInstance.get('/GetUnifiedLocationIdToLocationIdMapForAccountCreation', {
      params: {
        unifiedLocationId,
      },
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function getNpiInformation(NPI) {
  let response;
  try {
    response = await axiosInstance.post('/CheckHCPExistsForNPI', {
      NPI,
    });
  } catch (e) {
    Error(e);
  }
  return response.data;
}

async function isNpiInUse(NPI) {
  let response;
  try {
    response = await axiosInstance.post('/IsNpiInUse', null, {
      params: {
        NPI,
      },
    });
  } catch (e) {
    Error(e);
    return e.response;
  }
  return response;
}

async function addHcpToPractice(NPI, HcpId, PracticeId) {
  let response;
  try {
    response = await axiosInstance.post('/AddHCPToPractice', {
      NPI,
      HcpId,
      PracticeId,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function removeHcpFromPractice(PracticeId, NPI) {
  let response;
  try {
    response = await axiosInstance.post('/RemoveHCPFromPractice', {
      PracticeId,
      NPI,
    });
  } catch (e) {
    Error(e);
  }
  return response;
}

export default function createGhostPractice(file) {
  const bodyFormData = new FormData();
  bodyFormData.append('practice_creation', file);
  return axiosInstance.post('CreateGhostPractice', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function updatePracticeName(practiceId, newName) {
  let response;
  try {
    response = await axiosInstance.post('/UpdatePracticeName', {
      practiceId,
      practiceName: newName,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function getPracticeIdForCurrentAccountManager(acctMgrId) {
  let response;
  try {
    response = await axiosInstance.get('/GetPracticeIdForCurrentAccountManager', {
      params: {
        id: acctMgrId,
      },
    });
    return response.data;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function addNewManagedAccountToPractice(practiceId, npi, firstName, lastName, zip) {
  let response;
  try {
    response = await axiosInstance.post('/AddNewManagedAccountToPractice', {
      practiceId,
      npi,
      firstName,
      lastName,
      zip,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function accountManagerHasLocations() {
  let response;
  try {
    response = await axiosInstance.get('/AccountManagerHasLocations', {
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function updateAccountMgrLocations(locationAccepted, isUnifiedId, uiLocationId, practiceId) {
  let response;
  try {
    response = await axiosInstance.post('/CreateAccountMgrLocationsReviewed', {
      locationAccepted,
      isUnifiedId,
      uiLocationId,
      practiceId,
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

async function getPracticeRecommendedLocations() {
  let response;
  try {
    response = await axiosInstance.get('/GetPracticeRecommendedLocations', {
    });
    return response;
  } catch (e) {
    Error(e);
    return e.response;
  }
}

export {
  getPractices,
  getPractice,
  addLocationToPractice,
  willLocationRemovalHideResults,
  removeLocationFromPractice,
  searchLocations,
  getUnifiedLocationIdToLocationIdMap,
  getNpiInformation,
  addHcpToPractice,
  removeHcpFromPractice,
  createGhostPractice,
  isNpiInUse,
  updatePracticeName,
  getPracticeIdForCurrentAccountManager,
  addNewManagedAccountToPractice,
  accountManagerHasLocations,
  updateAccountMgrLocations,
  getPracticeRecommendedLocations,
};
