import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import PracticeView from '../components/PracticeView';
import AccountContext from '../contexts/AccountContext';
import { getPracticeIdForCurrentAccountManager } from '../api/PracticesService';

const MyPractice = () => {
  const { account } = useContext(AccountContext);
  const [practiceId, setPracticeId] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    if (account.accountId) {
      const id = await getPracticeIdForCurrentAccountManager(account.accountId);
      setPracticeId(id);
      setLoaded(true);
    }
  }, [account.accountId]);

  if (!loaded) {
    return <CircularProgress />;
  }
  return <PracticeView practiceId={practiceId} />;
};
export default MyPractice;
