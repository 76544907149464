import { axiosInstance } from '../utils/AxiosConfiguration';

export default async function GetPendingAccounts(pageNumber, pageSize) {
  let response;
  try {
    response = await axiosInstance.post('PendingAccounts', {
      pageNumber,
      pageSize,
    });
  } catch (e) {
    Error(e);
  }
  return response.data;
}
