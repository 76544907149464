import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  canvasWrapper: {
    width: '500px',
    height: '486px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  legendText: {
    textAlign: 'center',
    color: COLORS.ABBEY,
  },
}));

const HorizontalBarChart = (props) => {
  const classes = useStyles();
  const { dataSet } = props;
  const { data, title } = dataSet;
  const chartId = 'bar';
  const canvasRef = useRef();
  function checkIfMobile() {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    return viewportWidth <= 500;
  }

  Chart.register(...registerables);
  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    canvasRef.current.style.backgroundColor = 'transparent';
    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // eslint-disable-next-line no-unused-vars
              callback(val, index, labels) {
                // eslint-disable-next-line react/no-this-in-sfc
                const label = this.getLabelForValue(val);
                if (checkIfMobile()) {
                  return label.substring(0, label.length - 1).split('(');
                }
                return label;
              },
              font: {
                weight: 'bold',
                family: 'Roboto',
              },
            },
          },
        },
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: title,
            font: {
              family: 'Roboto Condensed',
              weight: 'normal',
              size: '22px',
            },
          },
          tooltip: {
            callbacks: {
              label(context) {
                return `${context.formattedValue}% ${context.label}`;
              },
            },
          },
        },
      },
    });
  }, [chartId]);

  return (
    <div>
      <div className={classes.canvasWrapper}>
        <canvas ref={canvasRef} id={chartId} />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
