import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AcctDetailsSide from '../images/AcctDetailsSide.svg';
import AdditionalDetailsForm from '../components/AdditionalDetailsForm';

const useStyles = makeStyles({
  outerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
  },
  sidePanelImg: {
    backgroundColor: '#E2EFFD',
    height: 'form.offsetHeight',
  },
});

const AdditionalDetails = () => {
  const classes = useStyles();
  const query = useMediaQuery('(min-width:768px)');
  const [isDesktop, setIsDesktop] = useState(true);
  useEffect(() => {
    if (query !== isDesktop) {
      setIsDesktop(query);
    }
  }, [query]);

  if (isDesktop) {
    return (
      <>
        <div className={classes.flexRow}>
          <div className={classes.sidePanelImg}>
            <img alt="" src={AcctDetailsSide} />
          </div>
          <div className={classes.outerContainer}>
            <AdditionalDetailsForm />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.outerContainer}>
        <AdditionalDetailsForm />
      </div>
    </>
  );
};

export default AdditionalDetails;
