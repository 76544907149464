import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { COLORS } from '../../utils/applicationConstants';

const useStyles = makeStyles(() => ({
  primaryButton: {
    backgroundColor: COLORS.AZURE_RAD,
    border: 'none',
    width: '100%',
    color: COLORS.WHITE,
    borderRadius: '30px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    textAlign: 'center',
    margin: '15px 0',
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
  secondaryButton: {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.AZURE_RAD}`,
    width: '100%',
    color: COLORS.AZURE_RAD,
    borderRadius: '30px',
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0',
    '&:hover': {
      backgroundColor: COLORS.AZURE,
      color: COLORS.WHITE,
    },
  },
  abbeyButton: {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.ABBEY}`,
    width: '100%',
    color: COLORS.ABBEY,
    borderRadius: '30px',
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0',
    '&:hover': {
      backgroundColor: COLORS.ABBEY,
      color: COLORS.WHITE,
    },
  },
}));

const ButtonComponent = (props) => {
  const { children, type, ...other } = props;
  const classes = useStyles();
  function getButtonType() {
    if (type === 'primary') {
      return classes.primaryButton;
    }
    if (type === 'secondary') {
      return classes.secondaryButton;
    }
    if (type === 'abbey') {
      return classes.abbeyButton;
    }
    return classes.primaryButton;
  }

  return (
    <Button
      classes={{
        root: getButtonType(type),
      }}
      {...other}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
