import React, { useEffect, useState, useContext } from 'react';
import { Button, Menu, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTableStyles } from '../styles/TableStyles';
import { ClockIcon, FlagIcon, SortIcon, ClearFilterIcon } from '../utils/Icons';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import Tooltip from './tooltip';
import getFormattedDate, { getInProcessFormattedDate } from '../utils/dateFormatter';
import AccountContext from '../contexts/AccountContext';
import UpdateResultPriority from '../api/UpdatePriority';
import { getActionReqText } from '../utils/statusHelpers';
import PriorityButton from './PriorityButton';
import { abnormalGenotypeRenderer, abnormalRenderer, checkIfLocationEmpty, CustLoadingOverlay, CustNoRowsOverlay, fullReportRender, getFullName } from '../utils/dataTableHelpers';

let tableHasAnEmptySerumLevel = false;
function isAbnormalSerumLevel(currentRow) {
  if ((currentRow.serumLevel > 90 && currentRow.serumLevel < 200) || currentRow.serumLevel === '--') {
    if (currentRow.serumLevel === '--') {
      tableHasAnEmptySerumLevel = true;
    }
    return false;
  }
  return true;
}

function abnormalSerumLevelRenderer(params) {
  if (isAbnormalSerumLevel(params.row)) {
    return (
      abnormalRenderer(params)
    );
  }
  return params.value;
}

const DataTable = (props) => {
  const classes = useTableStyles();
  // eslint-disable-next-line max-len
  const { results, handlePageChangeHandler, queryFilters, handleQueryFilters, filterLists, isTableLoading, incrementPriority, updateRowPriorityRendering, headersList, handleHeaderList } = props;
  const { totalRecords, pageSize, pageNumber } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const { account } = useContext(AccountContext);
  const cellHeight = 54;

  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const handleSortChange = (sort) => {
    if (sort === 'patientName') {
      switch (queryFilters.patientNameOrder) {
        case null: handleQueryFilters({
          ...queryFilters,
          patientNameOrder: 'desc',
          collectionDateOrder: null,
          DOBOrder: null,
        }); break;
        case undefined: handleQueryFilters({
          ...queryFilters,
          patientNameOrder: 'desc',
          collectionDateOrder: null,
          DOBOrder: null,
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          patientNameOrder: 'desc',
          collectionDateOrder: null,
          DOBOrder: null,
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          patientNameOrder: 'asc',
          collectionDateOrder: null,
          DOBOrder: null,
        }); break;
        default: break;
      }
    }
    if (sort === 'DOB') {
      switch (queryFilters.DOBOrder) {
        case null: handleQueryFilters({
          ...queryFilters,
          DOBOrder: 'desc',
          collectionDateOrder: null,
          patientNameOrder: null,
        }); break;
        case undefined: handleQueryFilters({
          ...queryFilters,
          DOBOrder: 'desc',
          collectionDateOrder: null,
          patientNameOrder: null,
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          DOBOrder: 'desc',
          collectionDateOrder: null,
          patientNameOrder: null,
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          DOBOrder: 'asc',
          collectionDateOrder: null,
          patientNameOrder: null,
        }); break;
        default: break;
      }
    }
    if (sort === 'collectionDate') {
      switch (queryFilters.collectionDateOrder) {
        case null: handleQueryFilters({
          ...queryFilters,
          collectionDateOrder: 'desc',
          DOBOrder: null,
          patientNameOrder: null,
        }); break;
        case undefined: handleQueryFilters({
          ...queryFilters,
          collectionDateOrder: 'desc',
          DOBOrder: null,
          patientNameOrder: null,
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          collectionDateOrder: 'desc',
          DOBOrder: null,
          patientNameOrder: null,
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          collectionDateOrder: 'asc',
          DOBOrder: null,
          patientNameOrder: null,
        }); break;
        default: break;
      }
    }
  };
  const [anchorSerumLevel, setAnchorSerumLevel] = useState();
  const [anchorTestType, setAnchorTestType] = useState();
  const [anchorGenotype, setAnchorGenotype] = useState();
  const [anchorLocation, setAnchorLocation] = useState();
  const [anchorStatus, setAnchorStatus] = useState();
  const [anchorHcp, setAnchorHcp] = useState();

  const handleCloseMenus = () => {
    setAnchorStatus(null);
    setAnchorSerumLevel(null);
    setAnchorTestType(null);
    setAnchorGenotype(null);
    setAnchorLocation(null);
  };
  const handleAnchorSerumLevel = (event) => {
    setAnchorSerumLevel(event.currentTarget);
  };
  const handleFilterClose = (filter) => {
    if (filter === 'serumLevel') {
      setAnchorSerumLevel(null);
    }
    if (filter === 'testType') {
      setAnchorTestType(null);
    }
    if (filter === 'locationAddress') {
      setAnchorLocation(null);
    }
    if (filter === 'genotype') {
      setAnchorGenotype(null);
    }
    if (filter === 'status') {
      setAnchorStatus(null);
    }
    if (filter === 'hcp') {
      setAnchorHcp(null);
    }
  };
  const handleFilterChange = (e, filter) => {
    if (filter === 'serumLevel') {
      handleQueryFilters({
        ...queryFilters,
        serumLevel: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        serumLevel: true,
      });
    }
    if (filter === 'testType') {
      handleQueryFilters({
        ...queryFilters,
        testType: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        testType: true,
      });
    }
    if (filter === 'locationAddress') {
      handleQueryFilters({
        ...queryFilters,
        locationAddress: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        locationAddress: true,
      });
    }
    if (filter === 'genotype') {
      handleQueryFilters({
        ...queryFilters,
        genotype: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        genotype: true,
      });
    }
    if (filter === 'status') {
      handleQueryFilters({
        ...queryFilters,
        status: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        status: true,
      });
    }
    if (filter === 'hcp') {
      handleQueryFilters({
        ...queryFilters,
        hcp: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        hcp: true,
      });
    }
  };
  const handleAnchorLocation = (event) => {
    setAnchorLocation(event.currentTarget);
  };
  const handleAnchorGenotype = (event) => {
    setAnchorGenotype(event.currentTarget);
  };
  const handleAnchorStatus = (event) => {
    setAnchorStatus(event.currentTarget);
  };
  const handleAnchorHcp = (event) => {
    setAnchorHcp(event.currentTarget);
  };
  const StatusRendererDesktop = (params) => {
    const { row } = params;
    const {
      id,
      status,
      exceptionMessage,
      labReceiptDate,
      isPriority,
    } = row;
    const mobileIconDisplay = true;
    function getLabDate(date) {
      return `Date received: ${getInProcessFormattedDate(date)}`;
    }
    switch (status) {
      case 'In Process':
        return (
          <>
            <p className={mobileIconDisplay ? 'mobile-status' : 'desktop-status'}>{status}</p>
            <span className="status-icon">
              <Tooltip content={getLabDate(labReceiptDate)} icon="clock">
                <ClockIcon color={COLORS.ABBEY} />
              </Tooltip>
            </span>
            <PriorityButton updateRowPriorityRendering={updateRowPriorityRendering} handleFilterCard={incrementPriority} isPriority={isPriority} rowId={id} display="priority-desktop" />
          </>
        );
      case 'Action Required':
        return (
          <>
            <p className={mobileIconDisplay ? 'mobile-status' : 'desktop-status'}>{status}</p>
            <span className="status-icon">
              <Tooltip
                content={getActionReqText(exceptionMessage)}
                icon="flag"
                style={{ zIndex: 20 }}
              >
                <FlagIcon color={COLORS.CARDINAL} />
              </Tooltip>
            </span>
            <PriorityButton updateRowPriorityRendering={updateRowPriorityRendering} handleFilterCard={incrementPriority} isPriority={isPriority} rowId={id} display="priority-desktop" />
          </>
        );
      default:
        return (
          <>
            <p className={mobileIconDisplay ? 'mobile-status' : 'desktop-status'}>{status}</p>
            <PriorityButton updateRowPriorityRendering={updateRowPriorityRendering} handleFilterCard={incrementPriority} isPriority={isPriority} rowId={id} display="priority-desktop" />
          </>
        );
    }
  };
  // #endregion

  const columns = [
    {
      field: 'patientName',
      headerName: 'Patient Name',
      width: 175,
      sortable: false,
      valueGetter: getFullName,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={() => handleSortChange('patientName')}>
          <p> Patient Name </p>
          <div className={classes.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
    },
    {
      field: 'patientDob',
      headerName: 'DOB',
      width: 100,
      sortable: false,
      type: 'date',
      valueFormatter: getFormattedDate,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={() => handleSortChange('DOB')}>
          <p> DOB </p>
          <div className={classes.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
    },
    {
      field: 'collectionDate',
      sortable: false,
      width: 155,
      type: 'date',
      valueFormatter: getFormattedDate,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={() => handleSortChange('collectionDate')}>
          <p> Collection Date </p>
          <div className={classes.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
    },
    {
      field: 'associatedHCP',
      width: 111.7,
      sortable: false,
      headerClassName: headersList.hcp ? classes.headerFilterOn
        : classes.headerFilterOff,
      hide: account.accountType === ACCOUNT_TYPES.HCP,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorHcp}>
          <p> HCP </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
    },
    {
      field: 'locationAddress',
      width: 200,
      sortable: false,
      headerClassName: headersList.locationAddress ? classes.headerFilterOn
        : classes.headerFilterOff,
      valueGetter: checkIfLocationEmpty,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorLocation}>
          <p> Location </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
    },
    {
      field: 'genotype',
      headerName: 'Genotype',
      width: 120,
      sortable: false,
      headerClassName: headersList.genotype ? classes.headerFilterOn
        : classes.headerFilterOff,
      renderCell: abnormalGenotypeRenderer,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorGenotype}>
          <p> Genotype </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
    },
    {
      field: 'serumLevel',
      headerName: 'Serum Level',
      headerClassName: headersList.serumLevel ? classes.headerFilterOn
        : classes.headerFilterOff,
      width: 120,
      sortable: false,
      renderCell: abnormalSerumLevelRenderer,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorSerumLevel}>
          Serum Level
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
    },
    {
      field: 'labName',
      headerName: 'Lab',
      width: 100,
      sortable: false,
      filterable: false,
    },
    {
      field: 'fullReport',
      headerName: 'Full Report',
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: fullReportRender,
      renderHeader: () => (
        <Button disabled className={classes.headerButton}>
          Full Report
        </Button>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      cellClassName: 'status-col',
      sortable: false,
      renderCell: StatusRendererDesktop,
      headerClassName: headersList.status ? `status-header ${classes.headerFilterOn}`
        : `status-header ${classes.headerFilterOff}`,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorStatus}>
          <p> Status </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
    },
  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChangeHandler}
          hideFooterPagination
          checkboxSelection
          selectionModel={results.map((res) => (res.isPriority ? res.id : 0))}
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            columnHeader: classes.columnHeader,
          }}
          rows={results}
          columns={columns}
          components={{
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          disableSelectionOnClick
          onRowSelected={(e) => UpdateResultPriority(e.data.id)}
        />
        <Menu
          id="serumLevel-menu"
          anchorEl={anchorSerumLevel}
          open={Boolean(anchorSerumLevel)}
          onClose={() => handleFilterClose('serumLevel')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="serumLevelFilter"
              name="serum Level Filter"
              value={queryFilters.serumLevel}
              onChange={(e) => handleFilterChange(e, 'serumLevel')}
            >
              {filterLists.serumLevelFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '') ? 'None' : option}
                />
              ))}
              {tableHasAnEmptySerumLevel && (
                <FormControlLabel
                  value="--"
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label="None"
                />
              )}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'serumLevel');
                handleHeaderList({
                  ...headersList,
                  serumLevel: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="testType-menu"
          anchorEl={anchorTestType}
          open={Boolean(anchorTestType)}
          onClose={() => handleFilterClose('testType')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="testTypeFilter"
              name="test Type Filter"
              value={queryFilters.testType}
              onChange={(e) => handleFilterChange(e, 'testType')}
            >
              {filterLists.testTypeFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'testType');
                handleHeaderList({
                  ...headersList,
                  testType: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="hcp-menu"
          anchorEl={anchorHcp}
          open={Boolean(anchorHcp)}
          onClose={() => handleFilterClose('hcp')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="hcpFilter"
              name="hcp Filter"
              value={queryFilters.hcp}
              onChange={(e) => handleFilterChange(e, 'hcp')}
            >
              {filterLists.hcpFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '--') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'hcp');
                handleHeaderList({
                  ...headersList,
                  hcp: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="location-menu"
          anchorEl={anchorLocation}
          open={Boolean(anchorLocation)}
          onClose={() => handleFilterClose('locationAddress')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="locationFilter"
              name="location Filter"
              value={queryFilters.locationAddress}
              onChange={(e) => handleFilterChange(e, 'locationAddress')}
            >
              {filterLists.locationFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '--') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'location');
                handleHeaderList({
                  ...headersList,
                  location: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="genotype-menu"
          anchorEl={anchorGenotype}
          open={Boolean(anchorGenotype)}
          onClose={() => handleFilterClose('genotype')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="genotypeFilter"
              name="genotype Filter"
              value={queryFilters.genotype}
              onChange={(e) => handleFilterChange(e, 'genotype')}
            >
              {filterLists.genotypeFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  // eslint-disable-next-line max-len
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '--') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'genotype');
                handleHeaderList({
                  ...headersList,
                  genotype: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="status-menu"
          anchorEl={anchorStatus}
          open={Boolean(anchorStatus)}
          onClose={() => handleFilterClose('status')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="statusFilter"
              name="status Filter"
              value={queryFilters.status}
              onChange={(e) => handleFilterChange(e, 'status')}
            >
              {filterLists.statusFilterList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'status');
                handleHeaderList({
                  ...headersList,
                  status: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
      </div>
    </>
  );
};
export default DataTable;
