import { axiosInstance } from '../utils/AxiosConfiguration';

export default function UpdateRepMapping(file) {
  const bodyFormData = new FormData();
  bodyFormData.append('rep_mapping', file);
  return axiosInstance.post('UpdateRepMapping', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
