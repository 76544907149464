import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { useTableStylesHcp } from '../styles/TableStylesHcp';
import { COLORS, ACCOUNT_TYPES } from '../utils/applicationConstants';
import AccountContext from '../contexts/AccountContext';
import { isGhostNpi } from '../utils/helpers';

const useStyles = makeStyles(({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  columnHeader: {
    color: COLORS.WHITE,
    textTransform: 'none',
    height: '64px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    width: '100%',
    '&:disabled': {
      color: COLORS.WHITE,
    },
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  row: {
    width: '100% !important',
  },
  managerViewRenderingZone: {
    width: '100% !important',
  },
  removeButton: {
    color: 'red',
    fontFamily: 'Roboto',
  },
  editButton: {
    color: COLORS.NAVY,
    fontFamily: 'Roboto',
    paddingLeft: '32px',
  },
}));

const HcpManagerTable = (props) => {
  const { account } = useContext(AccountContext);
  const classes = useTableStylesHcp();
  const styleClasses = useStyles();
  const {
    results, handlePageChangeHandler,
    isTableLoading,
    deleteModalHandler,
    editModalHandler,
  } = props;
  const { pageNumber } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;

  function CustNoRowsOverlay() {
    if (account.accountType === ACCOUNT_TYPES.GrifolsAdmin) {
      return (
        <GridOverlay>
          <div className={styleClasses.noRowsOverlay}>
            <Typography className={styleClasses.noRowsHeader}>
              There are currently no HCPs aligned to this practice.
            </Typography>
            <Typography className={styleClasses.nowRowsParagraph}>
              To start building this practice, click &quot;Add an HCP to this Practice&quot; above.
            </Typography>
          </div>
        </GridOverlay>
      );
    }
    return (
      <GridOverlay>
        <div className={styleClasses.noRowsOverlay}>
          <Typography className={styleClasses.noRowsHeader}>
            There are currently no HCPs aligned to your practice.
          </Typography>
          <Typography className={styleClasses.nowRowsParagraph}>
            To start building your practice, please contact the portal
            administrator by visiting Contact Us above. Provide in the
            email a list of names and NPIs of all HCPs who you would like
            to add to your practice. Please bear in mind an HCP cannot be
            added to your practice until their portal account is active.
          </Typography>
        </div>
      </GridOverlay>
    );
  }

  function CustLoadingOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.loadingOverlay}>
          <CircularProgress fontSize="large" />
        </div>
      </GridOverlay>
    );
  }
  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p> First Name </p>
        </Button>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p> Last Name </p>
        </Button>
      ),
    },
    {
      field: 'email',
      headerName: 'E-Mail',
      sortable: false,
      width: 300,
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p> E-Mail </p>
        </Button>
      ),
    },
    {
      field: 'npi',
      headerName: 'NPI',
      sortable: false,
      width: 151,
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p> NPI </p>
        </Button>
      ),
    },
    {
      field: 'remove',
      headerName: 'Remove',
      sortable: false,
      width: 175,
      renderCell: (params) => {
        const { row } = params;

        return (
          <>
            <Button className={styleClasses.removeButton} onClick={() => deleteModalHandler(row)}>
              Remove
            </Button>
            {
            isGhostNpi(row.email)
              && (
              <Button className={styleClasses.editButton} onClick={() => editModalHandler(row)}>
                Edit
              </Button>
              )
            }
          </>
        );
      },
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p>Modify</p>
        </Button>
      ),
    },
  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          rowsPerPageOptions={[10, 15, 20]}
          pageSize={100}
          onPageChange={(newPage) => handlePageChangeHandler(newPage)}
          pagination
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          disableColumnResize
          disableExtendRowFullWidth
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            renderingZone: styleClasses.managerViewRenderingZone,
            columnHeader: classes.columnHeader,
          }}
          sortingOrder={['desc', 'asc']}
          rows={results}
          columns={columns}
          components={{
            ColumnSortedDescendingIcon: () => <></>,
            ColumnSortedAscendingIcon: () => <></>,
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};
export default HcpManagerTable;
