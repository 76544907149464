export const csvHcpHeaders = [
  {
    label: 'Last Name', key: 'lastName',
  },
  {
    label: 'First Name', key: 'firstName',
  },
  {
    label: 'DOB', key: 'patientDob',
  },
  {
    label: 'Collection date', key: 'collectionDate',
  },
  {
    label: 'Location', key: 'location',
  },
  {
    label: 'Genotype', key: 'genotype',
  },
  {
    label: 'Serum Level', key: 'serumLevel',
  },
  {
    label: 'Status', key: 'status',
  },
];

export const csvAccountManagerHeaders = [
  {
    label: 'Last Name', key: 'lastName',
  },
  {
    label: 'First Name', key: 'firstName',
  },
  {
    label: 'DOB', key: 'patientDob',
  },
  {
    label: 'collectionDate', key: 'collectionDate',
  },
  {
    label: 'HCP', key: 'hcp',
  },
  {
    label: 'Location', key: 'location',
  },
  {
    label: 'Genotype', key: 'genotype',
  },
  {
    label: 'Serum Level', key: 'serumLevel',
  },
  {
    label: 'Status', key: 'status',
  },
];

export const csvRepHeaders = [
  {
    label: 'Last Name', key: 'lastName',
  },
  {
    label: 'First Name', key: 'firstName',
  },
  {
    label: 'Account Manager', key: 'accountManager',
  },
  {
    label: 'Zip', key: 'zip',
  },
  {
    label: 'Account Type', key: 'accountTypeDescription',
  },
  {
    label: 'Account Status', key: 'accountStatus',
  },
  {
    label: 'Quantity Action Required', key: 'quantityActionReq',
  },
  {
    label: 'Quantity Posted', key: 'quantityPosted',
  },
];

export const activeAccountsHeaders = [
  {
    label: 'Last Name', key: 'lastName',
  },
  {
    label: 'First Name', key: 'firstName',
  },
  {
    label: 'Email', key: 'email',
  },
  {
    label: 'NPI', key: 'npi',
  },
  {
    label: 'Zip Code', key: 'zip',
  },
  {
    label: 'Account Type', key: 'accountTypeDescription',
  },
  {
    label: 'Account Status', key: 'accountStatus',
  },
  {
    label: 'Account Manager', key: 'accountManager',
  },
  {
    label: 'Representative', key: 'representativeName',
  },
];

export const migrationHeaders = [
  {
    label: 'Email Addredd', key: 'emailAddress',
  },
  {
    label: 'Temp Password', key: 'tempPassword',
  },
];
