import React, { useState } from 'react';
import { Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import Interweave from 'interweave';
import { COLORS } from '../../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionText: {
    color: COLORS.ABBEY,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  accordionHeader: {
    color: COLORS.ABBEY,
    fontWeight: 'bold',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
const AccordionComponent = ({ title, copy }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <Accordion
      classes={{
        root: classes.accordionRoot,
      }}
      expanded={expanded}
      onChange={handleChange(!expanded)}
    >
      <AccordionSummary
        expandIcon={expanded ? <Remove /> : <Add />}
        aria-controls="panel1d-content"
      >
        <p className={classes.accordionHeader}>{title}</p>
      </AccordionSummary>
      <AccordionDetails>
        <p className={classes.accordionText}><Interweave content={copy} /></p>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
