import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../utils/authConfig';
import AcctDetailsSide from '../images/AcctDetailsSide.svg';
import ButtonComponent from '../components/common/ButtonComponent';
import DeleteDeniedAccount from '../api/DeleteDeniedAccount';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px',
    },
    width: '30%',
    display: 'flex',
    paddingLeft: '7rem',
    flexDirection: 'column',
    paddingTop: '3rem',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
  },
  sidePanelImg: {
    backgroundColor: '#E2EFFD',
    height: 'form.offsetHeight',
  },
  header: {
    fontWeight: 400,
    fontSize: '32px',
    fontFamily: 'Roboto Condensed',
  },
  subheader: {
    marginTop: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto Condensed',
  },
  paragraph: {
    marginTop: '1rem',
  },
  agreementText: {
    marginTop: '1rem',
    fontStyle: 'italic',
    fontSize: '12px',
  },
  mobileContainer: {
    paddingTop: '7rem',
    width: '100%',
  },
}));

const AccountDenied = () => {
  const { instance } = useMsal();
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:768px)');

  async function handleAccountDelete() {
    const res = await DeleteDeniedAccount();
    if (res.status === 200) {
      instance.logoutRedirect(loginRequest);
    }
  }

  const TextBlock = () => (
    <div className={classes.container}>
      <div>
        <Typography className={classes.header}>AlphaID™ </Typography>
      </div>
      <Typography className={classes.header}>Results Portal</Typography>
      <Typography className={classes.subheader}>
        Your request for a new account in the AlphaID Results Portal
        has been denied by a Grifols admin.
      </Typography>
      <Typography className={classes.paragraph}>
        It is possible that you either did not select the correct role type
        during Account Creation or that your NPI number did not pass
        the Grifols Admin NPI registry validation. If you believe that your request
        was denied in error, please reference “Contact Us” above to
        contact the Grifols Admin and seek clarity.
      </Typography>
      <Typography className={classes.paragraph}>
        Should you need to try to sign up for an account again, you
        can do so by clicking &quot;I Understand&quot; below and starting over from
        the portal landing page. After a new attempt, the Grifols Admin will see your
        request in their approvals queue.
      </Typography>
      <Typography className={classes.agreementText}>
        By clicking &quot;I Understand&quot; below, you acknowledge that you have
        read this message in its entirety and authorize the AlphaID Results Portal
        to delete your pending account.
      </Typography>
      <ButtonComponent
        onClick={() => handleAccountDelete()}
      >
        I Understand
      </ButtonComponent>
    </div>
  );

  if (isDesktop) {
    return (
      <>
        <div className={classes.flexRow}>
          <div className={classes.sidePanelImg}>
            <img alt="" src={AcctDetailsSide} />
          </div>
          <TextBlock />
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.mobileContainer}>
        <TextBlock />
      </div>
    </>
  );
};

export default AccountDenied;
