import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress } from '@material-ui/core';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    overflowX: 'visible',
  },
  row: {
    background: COLORS.MERCURY,
    borderRadius: '15px',
    border: 'none',
    height: '184px',
    padding: '0 24px',
  },
  thRoot: {
    borderRadius: '15px',
  },
  priorityPaperRoot: {
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  tableRoot: {
    borderCollapse: 'inherit !important',
    borderSpacing: '2px',
  },
  p: {
    display: 'inline-block',
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  mobileButtonGridRootContainer: {
    marginBottom: '24px',
  },
  buttonGroup: {
    color: COLORS.ABBEY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '24px',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
  approve: {
    backgroundColor: COLORS.AZURE_RAD,
    color: 'white',
    marginRight: '0.5rem',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
  deny: {
    marginTop: '7px',
  },
}));

const PendingAccountMobile = (props) => {
  const classes = useStyles();
  const {
    results,
    isTableLoading,
    handleViewHandler,
    totalRecords,
    pageSize,
    toggleDenyModal,
    toggleApproveModal,
  } = props;

  if (isTableLoading) {
    return <CircularProgress />;
  }

  function renderRepMobileTable() {
    return (
      <TableContainer className={classes.root} component={Paper}>
        <Table
          classes={{
            root: classes.tableRoot,
          }}
          aria-label="simple table"
        >
          <TableBody>
            {(pageSize > 0
              ? results.slice(0, pageSize)
              : results
            ).map((item) => (
              <TableRow
                key={item.id}
                classes={{
                  root: classes.row,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  classes={{
                    root: classes.thRoot,
                  }}
                >
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Name:
                          {' '}
                          {item.name}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          NPI(s):
                          {' '}
                          {item.npi}
                          {item.npi !== '' && item.npIs !== null && ','}
                          {item.npIs}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Zip:
                          {' '}
                          {item.zip}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Phone:
                          {' '}
                          {item.phone}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Email:
                          {' '}
                          {item.email}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Role:
                          {' '}
                          {item.role}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Practice Locations:
                        </p>
                        <div>
                          {item.locations.map((loc) => (
                            <li key={loc}>
                              {loc}
                            </li>
                          ))}
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Action:
                          {' '}
                          <div>
                            <Button
                              className={classes.approve}
                              onClick={() => toggleApproveModal(
                                {
                                  id: item.id,
                                  name: item.name,
                                },
                              )}
                            >
                              Approve
                            </Button>
                            <Button
                              className={classes.btn}
                              onClick={() => toggleDenyModal(
                                {
                                  id: item.id,
                                  name: item.name,
                                },
                              )}
                            >
                              Deny
                            </Button>
                          </div>
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.buttonGroup}>
          <div>
            <span>
              {' '}
              {totalRecords}
              {' '}
              results
              {' '}
            </span>
          </div>
          <div>
            <span className={classes.viewText}>View:</span>
            <button
              type="button"
              className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
              onClick={() => handleViewHandler(15)}
            >
              15
            </button>
            <button
              type="button"
              className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
              onClick={() => handleViewHandler(30)}
            >
              30
            </button>
            <button
              type="button"
              className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
              onClick={() => handleViewHandler(45)}
            >
              45
            </button>
          </div>
        </div>
        {renderRepMobileTable()}
      </div>
    </>
  );
};
export default PendingAccountMobile;
