/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, TextField, MenuItem, CircularProgress } from '@material-ui/core';
import PageHeader from '../components/common/PageHeader';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import DoughnutChart from '../components/common/charts/Doughnut';
import HorizontalBarChart from '../components/common/charts/HorizontalBar';
import AccountContext from '../contexts/AccountContext';
import LineChart from '../components/common/charts/LineChart';
// eslint-disable-next-line no-unused-vars
import GetInsights from '../api/GetInsights';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    borderBottom: `1px solid ${COLORS.ATHENS_GREY}`,
  },
  subHeader: {
    marginBottom: '48px',
    fontSize: '22px',
    fontWeight: '300',
    color: COLORS.ABBEY,
    marginTop: '0',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
    },
  },
  flexContainerChart: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '25px',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
    },
  },
  yearDropdownRoot: {
    width: '70px',
    border: `1px solid ${COLORS.SILVER}`,
    padding: '0 24px',
    borderRadius: '15px',
    '& :before': {
      display: 'none',
    },
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '32px',
    },
  },
  dropdownLabel: {
    marginRight: '16px',
    color: COLORS.ABBEY,
  },
  chartContainer: {

  },
  chartHeader: {
    fontWeight: 'bold',
    fontFamily: 'Roboto Condensed',
    fontSize: '32px',
    lineHeight: '37px',
    color: COLORS.ABBEY,
    marginTop: '80px',
    marginBottom: '48px',
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
    },
  },
  spinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
}));

function getHcpOrAcctManagerData(data) {
  return {
    genoTypeBreakDownBar: {
      data: {
        labels: data.barChartLabels,
        datasets: [{
          label: 'Abnormal Results',
          weight: 10,
          data: data.barChartValues,
          backgroundColor: ['#0e7aef'],
          hoverBackgroundColor: [
            '#0e7aef',
          ],
        }],
      },
      title: 'Breakdown of Abnormal Results',
    },
    totalNumberOfResults: {
      data: {
        labels: data.lineChartLabels,
        datasets: [{
          label: 'Total Number of Results',
          weight: 10,
          data: data.lineChartValues,
          backgroundColor: ['#0e7aef'],
          borderColor: ['#e2effd'],
          hoverBackgroundColor: [
            '#0e7aef',
          ],
        }],
      },
      title: 'Total Number of Results',
    },
    genoTypeBreakdownPie: {
      data: {
        labels: data.pieChartLabels,
        datasets: [{
          weight: 10,
          data: data.pieChartValues,
          backgroundColor: ['#0e7aef', '#e2effd'],
          hoverBackgroundColor: [
            '#0e7aef',
            '#e2effd',
          ],
        }],
      },
      title: 'Abnormal vs Normal Results',
    },
  };
}

function getRepData(data) {
  return {
    totalNumberOfResults: {
      data: {
        labels: data.lineChartLabels,
        datasets: [{
          label: 'Total Number of Kit Returns for Your Territory',
          weight: 10,
          data: data.lineChartValues,
          backgroundColor: ['#0e7aef'],
          borderColor: ['#e2effd'],
          hoverBackgroundColor: [
            '#0e7aef',
          ],
        }],
      },
      title: 'Total Number of Kit Returns for Your Territory',
    },
  };
}

const Insights = () => {
  const classes = useStyles();
  const [year, setYear] = useState(new Date().getFullYear());
  const [currentDataSet, setCurrentDataSet] = useState(null);
  const [insightYearList, setInsightYearList] = useState('');
  const [isInitialLoad, setisInitialLoad] = useState(true);
  const [subHeader, setSubHeader] = useState('practice');
  const [isLoading, setIsLoading] = useState(true);
  const { account } = useContext(AccountContext);
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  useEffect(async () => {
    if (account) {
      try {
        setIsLoading(true);
        const data = await GetInsights(year);
        if (account.accountType === ACCOUNT_TYPES.HCP) {
          setCurrentDataSet(getHcpOrAcctManagerData(data));
          setSubHeader('practice');
        }
        if (account.accountType === ACCOUNT_TYPES.AccountManager) {
          setCurrentDataSet(getHcpOrAcctManagerData(data));
          setSubHeader('practice');
        }
        if (account.accountType === ACCOUNT_TYPES.GrifolsRep) {
          setCurrentDataSet(getRepData(data));
          setSubHeader('territory');
        }
        setInsightYearList(data.yearOptions);
        setIsLoading(false);
        setisInitialLoad(false);
      } catch (e) {
        Error(e);
      }
    }
  }, [account, year]);
  if (!currentDataSet) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }
  function isRep() {
    return account.accountType === ACCOUNT_TYPES.GrifolsRep;
  }

  if (isInitialLoad) {
    return (
      <>
      </>
    );
  }
  return (
    <>
      <div className={classes.headerContainer}>
        <PageHeader>
          Your Insights
        </PageHeader>
        <div className={classes.flexContainer}>
          <p className={classes.subHeader}>
            Below you will find some overall trends
            in your {subHeader} using AlphaID
          </p>
          <div className={classes.inputContainer}>
            <span className={classes.dropdownLabel}>Insights for:</span>
            <TextField
              select
              classes={{
                root: classes.yearDropdownRoot,
              }}
              value={year}
              onChange={handleChange}
            >
              {insightYearList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <div className={classes.chartContainer}>
          <h2 className={classes.chartHeader}>
            { /* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {isRep() ? (
              <>
                {year} Testing: Total Kit Returns
              </>
            ) : (
              <>
                {year} Testing: Percent(%) by Genotype
              </>
            )}
          </h2>
          {isRep() ? null : (
            <div className={classes.flexContainerChart}>
              <DoughnutChart dataSet={currentDataSet?.genoTypeBreakdownPie} />
              <HorizontalBarChart dataSet={currentDataSet?.genoTypeBreakDownBar} />
            </div>
          )}
          <LineChart dataSet={currentDataSet?.totalNumberOfResults} />
        </div>
      )}
    </>
  );
};

export default Insights;
