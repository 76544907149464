import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Button, CircularProgress } from '@material-ui/core';
import PriorityButton from './PriorityButton';
import { useMobileTableStyles } from '../styles/MobileTableStyles';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import { ClearFilterIcon } from '../utils/Icons';

const RepDataTableMobile = (props) => {
  const classes = useMobileTableStyles();
  const {
    results,
    handleViewHandler,
    isTableLoading,
    totalRecords,
    incrementPriority,
    updateRowPriorityRendering,
    pageSize,
    handleClearFilters,
  } = props;
  if (isTableLoading) {
    return <CircularProgress />;
  }
  function isPriorityStyling(isItemPriority) {
    if (!isItemPriority) {
      return classes.row;
    }
    return `${classes.row} ${classes.priorityPaperRoot}`;
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.buttonGroup}>
          <div>
            <div>
              <span>
                {' '}
                {totalRecords}
                {' '}
                results
                {' '}
              </span>
            </div>
            <div>
              <span className={classes.viewText}>View:</span>
              <button
                type="button"
                className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(15)}
              >
                15
              </button>
              <button
                type="button"
                className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(30)}
              >
                30
              </button>
              <button
                type="button"
                className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(45)}
              >
                45
              </button>
            </div>
          </div>
          <Button
            disableRipple
            className={classes.clearButton}
            disabled={isTableLoading}
            onClick={handleClearFilters}
          >
            <p className={classes.clearText}>RESET</p>
            <ClearFilterIcon color={COLORS.ABBEY} />
          </Button>
        </div>
        <TableContainer className={classes.root} component={Paper}>
          <Table
            classes={{
              root: classes.tableRoot,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {(pageSize > 0
                ? results.slice(0, pageSize)
                : results
              ).map((item) => (
                <TableRow
                  key={item.id}
                  classes={{
                    root: classes.row,
                  }}
                >
                  <TableCell
                    classes={{
                      root: isPriorityStyling(item.isPriority),
                    }}
                    component="th"
                    scope="row"
                  >
                    <Grid container spacing={7}>
                      <PriorityButton updateRowPriorityRendering={updateRowPriorityRendering} handleFilterCard={incrementPriority} rowId={item.id} isPriority={item.isPriority} display="priority-mobile" />
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            {item.lastName}
                            ,
                            {' '}
                            {item.firstName}
                          </p>
                          <p className={classes.pAsLabel}>
                            AM:
                            {' '}
                            {item.accountManager || '--'}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs>
                        <p className={classes.pAsLabel}>
                          Account:
                          {' '}
                          {item.accountStatus}
                        </p>
                        <p className={classes.pAsLabel}>
                          Type:
                          {' '}
                          {item.accountType === ACCOUNT_TYPES.HCP ? 'HCP' : 'Account Manager'}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <p className={classes.pAsLabel}>
                          Quantity In Process:
                          {' '}
                          {item.quantityInProcess}
                        </p>
                        <p className={classes.pAsLabel}>
                          Quantity Posted:
                          {' '}
                          {item.quantityPosted}
                        </p>
                        <p className={classes.pAsLabel}>
                          Quantity Action Required:
                          {' '}
                          {item.quantityActionReq}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className={classes.pAsLabel}>
                          Zip:
                          {' '}
                          {item.zip}
                        </p>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
      </div>
    </>
  );
};
export default RepDataTableMobile;
