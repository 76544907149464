/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Drawer, Button, AccordionDetails, Accordion, AccordionSummary, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Input, InputAdornment, InputLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import { ClearFilterIcon } from '../utils/Icons';
import { COLORS } from '../utils/applicationConstants';

// eslint-disable-next-line max-len
const MobileFiltering = ({ mobileFilterDrawerOpen, toggleFilterDrawer, queryFilters, filterLists, handleQueryFilters }) => {
  const useStyles = makeStyles(({
    cancelButton: {
      textTransform: 'none',
      fontSize: '1.25rem',
    },
    drawerPad: {
    },
    drawer: {
      marginTop: '1rem',
    },
    clearButton: {
      textTransform: 'none',
    },
    clearText: {
      marginRight: '0.3rem',
      fontSize: '1.1rem',
    },
    filterHeader: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    filterHeaderContainer: {
      padding: '0.5rem',
      paddingTop: '1rem',
    },
    filterHeaderTextContainer: {
      paddingBottom: '2rem',
      paddingTop: '0.5rem',
    },
    divider: {
      borderTop: `1px solid ${COLORS.MISCHKA}`,
      padding: '0.5rem',
    },
    accordion: {
      borderBottom: `1px solid ${COLORS.MISCHKA}`,
      padding: '0.5rem',
    },
    radio: {
      '&$checked': {
        color: '#0B7AEF',
      },
    },
    checked: {
    },
    accordionContainer: {
      padding: '1rem',
    },
    applyFilteringButton: {
      backgroundColor: COLORS.AZURE_RAD,
      color: COLORS.WHITE,
      padding: '1rem 3.5rem',
      borderRadius: '1.75rem',
      alignSelf: 'center',
      marginBottom: '1rem',

      '&:hover': {
        backgroundColor: COLORS.AZURE,
        color: COLORS.WHITE,
        padding: '1rem 3.5rem',
        borderRadius: '1.75rem',
        alignSelf: 'center',
      },
    },
    applyFilteringText: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
    searchInput: {
      backgroundColor: COLORS.WILD_SAND,
      margin: '1rem',
      marginBottom: '2rem',
      borderRadius: '30px',
      height: '50px',
      paddingLeft: '32px',
      '&::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
    searchIconRoot: {
      paddingRight: '32px',
    },
    inputLabel: {
      marginLeft: '1rem',
      marginRight: '0.5rem',
    },
  }));
  const classes = useStyles();
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    ...queryFilters,
    location: undefined,
  });
  useEffect(() => {
    setSelectedFilters({
      ...queryFilters,
      location: undefined,
    });
  }, [queryFilters]);
  const changeExpandedFilter = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : false);
  };
  const applyFiltering = (e) => {
    toggleFilterDrawer(e, null);
    handleQueryFilters(
      selectedFilters,
    );
  };
  const handleFilterChange = (e, filter) => {
    if (filter === 'location') {
      setSelectedFilters({
        ...selectedFilters,
        location: e.target.value,
      });
    } else
    if (filter === 'genotype') {
      setSelectedFilters({
        ...selectedFilters,
        genotype: e.target.value,
      });
    } else
    if (filter === 'serumLevel') {
      setSelectedFilters({
        ...selectedFilters,
        serumLevel: e.target.value,
      });
    } else
    if (filter === 'status') {
      setSelectedFilters({
        ...selectedFilters,
        status: e.target.value,
      });
    } else
    if (filter === 'clear') {
      setSelectedFilters({
        ...selectedFilters,
        status: null,
        testType: null,
        serumLevel: null,
        genotype: null,
        location: undefined,
      });
      handleQueryFilters({
        ...selectedFilters,
        status: null,
        testType: null,
        serumLevel: null,
        genotype: null,
        location: undefined,
      });
      toggleFilterDrawer(e, null);
    }
  };
  const handleSortChange = (e, sort) => {
    if (sort === 'patientName') {
      setSelectedFilters({
        ...selectedFilters,
        patientNameOrder: e.target.value,
      });
    }
    if (sort === 'DOB') {
      setSelectedFilters({
        ...selectedFilters,
        DOBOrder: e.target.value,
        collectionDateOrder: null,
        patientNameOrder: null,
      });
    }
    if (sort === 'collectionDate') {
      setSelectedFilters({
        ...selectedFilters,
        collectionDateOrder: e.target.value,
        patientNameOrder: null,
        DOBOrder: null,
      });
    }
    if (sort === 'clear') {
      setSelectedFilters({
        ...selectedFilters,
        collectionDateOrder: null,
        patientNameOrder: null,
        DOBOrder: null,
      });
      handleQueryFilters({
        ...selectedFilters,
        collectionDateOrder: null,
        patientNameOrder: null,
        DOBOrder: null,
      });
      toggleFilterDrawer(e, null);
    }
  };

  const handleSearchChange = (e, search) => {
    if (search === 'search') {
      setSelectedFilters({
        ...selectedFilters,
        search: e.target.value,
      });
    }
    if (search === 'clear') {
      setSelectedFilters({
        ...selectedFilters,
        search: '',
      });
      handleQueryFilters({
        ...selectedFilters,
        search: '',
      });
      toggleFilterDrawer(e, null);
    }
  };

  const statusOptions = ['Posted', 'Action Required', 'Corrected'];
  const serumLevelOptions = ['<20', '20-90', '90-200', '>200'];
  return (
    <>
      <Drawer className={classes.drawer} anchor="bottom" open={mobileFilterDrawerOpen === 'filter'} onClose={(e) => toggleFilterDrawer(e, null)}>
        <div className={classes.filterHeaderContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.filterHeaderTextContainer}
          >
            <Grid
              item
              xs={4}
            >
              <Button
                className={classes.cancelButton}
                onClick={(e) => toggleFilterDrawer(e, null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={4}
            >
              <Typography className={classes.filterHeader}>Filter by:</Typography>
            </Grid>
            <Grid
              item
              xs={4}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button disableRipple className={classes.clearButton} onClick={(e) => handleFilterChange(e, 'clear')}>
              <p className={classes.clearText}>Clear Filters</p>
              <ClearFilterIcon color={COLORS.ABBEY} />
            </Button>
          </Grid>
        </div>
        <div className={classes.accordionContainer}>
          <Accordion className={classes.accordion} expanded={expandedFilter === 'locationAddress'} elevation={0} onChange={changeExpandedFilter('locationAddress')}>
            <AccordionSummary expandIcon={expandedFilter === 'locationAddress' ? <RemoveIcon /> : <AddIcon />}>
              <Typography className={classes.filterHeader}>Location</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="locationFilter"
                  name="location Filter"
                  value={selectedFilters.locationAddress}
                  onChange={(e) => handleFilterChange(e, 'locationAddress')}
                >
                  {filterLists.locationFilterList.map((option) => (
                    <FormControlLabel
                      value={option}
                      key={option}
                      control={(
                        <Radio classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        />
                      )}
                      label={(option === null || option === '--') ? 'None' : option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion} expanded={expandedFilter === 'genotype'} elevation={0} onChange={changeExpandedFilter('genotype')}>
            <AccordionSummary
              expandIcon={expandedFilter === 'genotype' ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography className={classes.filterHeader}>Genotype</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="genotypeFilter"
                  name="Genotype Filter"
                  value={selectedFilters.genotype}
                  onChange={(e) => handleFilterChange(e, 'genotype')}
                >
                  {filterLists.genotypeFilterList.map((option) => (
                    <FormControlLabel
                      value={option}
                      key={option}
                      control={(
                        <Radio classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        />
                      )}
                      label={(option === null || option === '--') ? 'None' : option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion} expanded={expandedFilter === 'serumLevel'} elevation={0} onChange={changeExpandedFilter('serumLevel')}>
            <AccordionSummary
              expandIcon={expandedFilter === 'serumLevel' ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography className={classes.filterHeader}>Serum Level (mg/dL)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="serumLevelFilter"
                  name="Serum Level Filter"
                  value={selectedFilters.serumLevel}
                  onChange={(e) => handleFilterChange(e, 'serumLevel')}
                >
                  {serumLevelOptions.map((option) => (
                    <FormControlLabel
                      value={option}
                      key={option}
                      control={(
                        <Radio classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        />
                      )}
                      label={(option === null || option === '--') ? 'None' : option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion} expanded={expandedFilter === 'status'} elevation={0} onChange={changeExpandedFilter('status')}>
            <AccordionSummary
              expandIcon={expandedFilter === 'status' ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography className={classes.filterHeader}>Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="status"
                  name="Status Filter"
                  value={selectedFilters.status}
                  onChange={(e) => handleFilterChange(e, 'status')}
                >
                  {statusOptions.map((option) => (
                    <FormControlLabel
                      value={option}
                      key={option}
                      control={(
                        <Radio classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        />
                      )}
                      label={(option === null || option === '--') ? 'None' : option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

        </div>
        <Button
          onClick={applyFiltering}
          className={classes.applyFilteringButton}
        >
          <Typography className={classes.applyFilteringText}>Apply Filtering</Typography>
        </Button>
      </Drawer>

      <Drawer anchor="bottom" open={mobileFilterDrawerOpen === 'sort'} onClose={(e) => toggleFilterDrawer(e, null)}>
        <div className={classes.filterHeaderContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.filterHeaderTextContainer}
          >
            <Grid
              item
              xs={4}
            >
              <Button
                className={classes.cancelButton}
                onClick={(e) => toggleFilterDrawer(e, null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={4}
            >
              <Typography className={classes.filterHeader}>Sort by:</Typography>
            </Grid>
            <Grid
              item
              xs={4}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button disableRipple className={classes.clearButton} onClick={(e) => handleSortChange(e, 'clear')}>
              <p className={classes.clearText}>Clear Sort </p>
              <ClearFilterIcon color={COLORS.ABBEY} />
            </Button>
          </Grid>
        </div>
        <div className={classes.accordionContainer}>
          <Accordion className={classes.accordion} expanded={expandedFilter === 'patientName'} elevation={0} onChange={changeExpandedFilter('patientName')}>
            <AccordionSummary expandIcon={expandedFilter === 'patientName' ? <RemoveIcon /> : <AddIcon />}>
              <Typography className={classes.filterHeader}>Patient Name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="patientNameFilter"
                  name="Patient Name Filter"
                  value={selectedFilters.patientNameOrder}
                  onChange={(e) => handleSortChange(e, 'patientName')}
                >
                  <FormControlLabel
                    value="asc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion} expanded={expandedFilter === 'DOB'} elevation={0} onChange={changeExpandedFilter('DOB')}>
            <AccordionSummary
              expandIcon={expandedFilter === 'DOB' ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography className={classes.filterHeader}>DOB</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="DOBFilter"
                  name="DOB Filter"
                  value={selectedFilters.DOBOrder}
                  onChange={(e) => handleSortChange(e, 'DOB')}
                >
                  <FormControlLabel
                    value="asc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion} expanded={expandedFilter === 'collectionDate'} elevation={0} onChange={changeExpandedFilter('collectionDate')}>
            <AccordionSummary
              expandIcon={expandedFilter === 'collectionDate' ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography className={classes.filterHeader}>Collection Date</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="collectionDateFilter"
                  name="Collection Date Filter"
                  value={selectedFilters.collectionDateOrder}
                  onChange={(e) => handleSortChange(e, 'collectionDate')}
                >
                  <FormControlLabel
                    value="asc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                    )}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

        </div>
        <Button
          onClick={applyFiltering}
          className={classes.applyFilteringButton}
        >
          <Typography className={classes.applyFilteringText}>Apply Sorting</Typography>
        </Button>
      </Drawer>

      <Drawer anchor="bottom" open={mobileFilterDrawerOpen === 'search'} onClose={(e) => toggleFilterDrawer(e, null)}>
        <div className={classes.filterHeaderContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.filterHeaderTextContainer}
          >
            <Grid
              item
              xs={4}
            >
              <Button
                className={classes.cancelButton}
                onClick={(e) => toggleFilterDrawer(e, null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={4}
            >
              <Typography className={classes.filterHeader}>Search by:</Typography>
            </Grid>
            <Grid
              item
              xs={4}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button disableRipple className={classes.clearButton} onClick={(e) => handleSearchChange(e, 'clear')}>
              <p className={classes.clearText}>Clear Search </p>
              <ClearFilterIcon color={COLORS.ABBEY} />
            </Button>
          </Grid>
        </div>
        <InputLabel className={classes.inputLabel}>
          Search by first name, last name, or date of birth (MM/DD/YYYY):
        </InputLabel>
        <Input
          placeholder=""
          className={classes.searchInput}
          value={selectedFilters.search}
          onChange={(e) => handleSearchChange(e, 'search')}
          endAdornment={(
            <InputAdornment
              position="end"
            >
              {' '}
              <Search classes={{
                root: classes.searchIconRoot,
              }}
              />
              {' '}
            </InputAdornment>
          )}
        />
        <Button
          onClick={applyFiltering}
          className={classes.applyFilteringButton}
        >
          <Typography className={classes.applyFilteringText}>Search</Typography>
        </Button>
      </Drawer>
    </>
  );
};

export default MobileFiltering;
