import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { axiosInstance } from '../utils/AxiosConfiguration';
import AccountContext from '../contexts/AccountContext';
import { ACCOUNT_TYPES } from '../utils/applicationConstants';
import { getUser } from '../api/AccountService';

const useStyles = makeStyles(() => ({
  successPageRoot: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const Success = () => {
  const classes = useStyles();
  const [loaded, setLoadedState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [hasApiRes, setHasApiRes] = useState(false);
  const { account, setAccount } = useContext(AccountContext);
  const { instance, inProgress, accounts } = useMsal();
  // eslint-disable-next-line consistent-return
  useEffect(async () => {
    const accessTokenRequest = {
      scopes: [''],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      if (accounts) {
        let accessTokenResponse;
        try {
          accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
        } catch (error) {
          Error(error);
        }
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessTokenResponse?.idToken}`;
        setLoadedState(true);
        if (account === '') {
          const accountResponse = await getUser();
          setAccount(accountResponse);
        }
        setHasApiRes(true);
      }
    }
  }, [instance, accounts, inProgress]);

  if (loaded && hasApiRes && account !== null && account !== undefined) {
    if (account.accountType === ACCOUNT_TYPES.GrifolsAdmin) {
      return <Redirect to="/admin-home" />;
    }
    if (account.accountType === ACCOUNT_TYPES.Approver) {
      return <Redirect to="/account-requests" />;
    }
    if (account.accountStatus === 'Denied') {
      return <Redirect to="/denied" />;
    }
    if (account.accountStatus === 'Active') {
      return <Redirect to="/dashboard" />;
    }
    if (account.accountStatus === 'Pending') {
      return <Redirect to="/details/success" />;
    }
    if (account.accountType === 0) {
      return <Redirect to="/details" />;
    }
  }

  return (
    <>
      <div className={classes.successPageRoot}>
        <CircularProgress />
      </div>
    </>
  );
};
export default Success;
