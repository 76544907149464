import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../utils/applicationConstants';

export const useDashboardStyles = makeStyles((theme) => ({
  welcomeRow: {
    marginTop: '72px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      margin: '32px 0',
    },
  },
  welcomeHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    margin: '0',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  subHeader: {
    color: COLORS.ABBEY,
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: '300',
    marginTop: '10px',
  },
  dashboardRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  searchContainer: {
    display: 'flex',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginBottom: '24px',
    },
  },
  viewButtonsContainer: {
    color: COLORS.ABBEY,
    display: 'flex',
    width: '52%',
  },
  searchInput: {
    font: '13px',
    backgroundColor: COLORS.WILD_SAND,
    borderRadius: '30px',
    width: '100%',
    height: '50px',
    paddingLeft: '32px',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  searchIconRoot: {
    paddingRight: '32px',
  },
  mobileSearchToggle: {
    border: 'none',
    borderRadius: '15px',
    padding: '15px',
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
  filterCardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      marginBottom: '32px',
    },
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  mobileButtonGridRootContainer: {
    marginBottom: '24px',
  },
  viewText: {
    marginTop: '14px',
    '& .view': {
      marginLeft: '15px',
    },
  },
  globalSpinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
  clearButton: {
    width: '100%',
    minWidth: '120px',
    height: '50px',
    paddingLeft: '32px',
    justifyContent: 'flex-end',
    color: COLORS.ABBEY,
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'inherit',
    },
  },
}));
