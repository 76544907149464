import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Input, InputAdornment, Hidden, CircularProgress, Button, Grid } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import { Search } from '@material-ui/icons';
import querystring from 'query-string';
import { useHistory } from 'react-router-dom';
import DataTable from '../components/data-grid';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import MobileDataTable from '../components/data-grid-mobile';
import getResults from '../api/getResults';
import TablePagination from '../components/Pagination';
import MobileFiltering from '../components/MobileFiltering';
import FilterCard from '../components/common/FilterCard';
import { ClearFilterIcon } from '../utils/Icons';
import AccountContext from '../contexts/AccountContext';
import { csvAccountManagerHeaders, csvHcpHeaders } from '../utils/headers';
import ExportButton from '../components/ExportButton';
import MissingLocationModal from '../components/MissingLocationModal';
import { accountManagerHasLocations } from '../api/PracticesService';
import RecommendedLocationsPopupModal from '../components/RecommendedLocationsPopupModal';

const useStyles = makeStyles((theme) => ({
  welcomeRow: {
    marginTop: '72px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      margin: '32px 0',
    },
  },
  welcomeHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    margin: '0',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  subHeader: {
    color: COLORS.ABBEY,
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: '300',
    marginTop: '10px',
  },
  dashboardRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  searchContainer: {
    display: 'flex',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginBottom: '24px',
    },
  },
  viewButtonsContainer: {
    color: COLORS.ABBEY,
    display: 'flex',
    width: '52%',
  },
  searchInput: {
    font: '13px',
    backgroundColor: COLORS.WILD_SAND,
    borderRadius: '30px',
    width: '100%',
    height: '50px',
    paddingLeft: '32px',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  searchIconRoot: {
    paddingRight: '32px',
  },
  mobileSearchToggle: {
    border: 'none',
    borderRadius: '15px',
    padding: '15px',
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
  filterCardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      marginBottom: '32px',
    },
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  mobileButtonGridRootContainer: {
    marginBottom: '24px',
  },
  viewText: {
    marginTop: '14px',
    '& .view': {
      marginLeft: '15px',
    },
  },
  globalSpinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
  clearButton: {
    width: '100%',
    minWidth: '120px',
    height: '50px',
    paddingLeft: '32px',
    justifyContent: 'flex-end',
    color: COLORS.ABBEY,
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'inherit',
    },
  },
  clearText: {
    fontSize: '1rem',
    display: 'inline',
  },
}));

const HCPMngrDashboard = () => {
  const history = useHistory();
  function parseUrlString() {
    const { search } = window.location;
    const parsedQuery = querystring.parse(search);
    return parsedQuery;
  }
  const classes = useStyles();
  const [searchBar, setSearchBar] = useState(
    parseUrlString().search ? parseUrlString().search : '',
  );
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [statusCounts, setStatusCounts] = useState({
  });
  const [showMissingLocationPrompt, setShowMissingLocationPrompt] = useState(false);
  const [userWelcomeInformation, setUserWelcomeInformation] = useState(null);
  const [filterLists, setFilterLists] = useState({
    testTypeFilterList: ['DBS', 'Swab'],
    serumLevelFilterList: ['<20', '20-90', '90-200', '>200'],
    statusFilterList: ['Posted', 'Action Required', 'Corrected'],
    accountStatus: ['Pending', 'Active', 'Denied'],
    role: ['HCP', 'Account Manager'],
    genotypeFilterList: null,
    locationFilterList: null,
    hcpFilterList: null,
  });
  const [mobileFilterDrawerOpen, setMobileFilterDrawerOpen] = useState(null);
  const [popUpLocationAccepted, setPopUpLocationAccepted] = useState(null);
  const toggleFilterDrawer = (event, open) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileFilterDrawerOpen(open);
  };
  const [queryFilters, setQueryFilters] = useState({
    ...parseUrlString(),
  });
  const { account } = useContext(AccountContext);
  const [exportData, setExportData] = useState([]);
  const [headersList, setHeadersList] = useState({
    location: false,
    testType: false,
    genotype: false,
    serumLevel: false,
    status: false,
    hcp: false,
  });
  /**
   * Querying the table results,
   * forEach loop is a one liner to remove any falsey object from the queryfilters
   * before setting it to the current url
   */
  // eslint-disable-next-line no-shadow
  async function getTableResults(pageNumber, pageSize, queryFilters, accountType) {
    const queryFilterClone = {
      ...queryFilters,
    };
    let response;
    try {
      response = await getResults(pageNumber, pageSize, queryFilters, accountType);
    } catch (e) {
      Error(e);
    }
    // eslint-disable-next-line max-len
    Object.keys(queryFilterClone).forEach((k) => !queryFilterClone[k] && delete queryFilterClone[k]);
    const newQueryString = querystring.stringify(queryFilterClone);
    history.push(`?${newQueryString}`);
    return response != null ? response.data : [];
  }

  function updateRowPriorityRendering(id) {
    let currentRow = results.find((row) => row.id === id);
    currentRow = {
      ...currentRow, isPriority: !currentRow.isPriority,
    };
    const index = results.findIndex((row) => row.id === id);
    const tempResults = [].concat(results);
    tempResults.splice(index, 1, currentRow);
    setResults(tempResults);
    return currentRow;
  }

  useEffect(async () => {
    // Do not display prompt if it has already been shown in this session
    if (window.sessionStorage.getItem('showLocationPrompt') === 'false') {
      setShowMissingLocationPrompt(false);
      return;
    }
    let response;
    try {
      response = await accountManagerHasLocations();
    } catch (e) {
      Error(e);
    }
    // Show prompt if account manager does not have locations
    if (!response.data && response.status !== 401) {
      setShowMissingLocationPrompt(true);
    }
    window.sessionStorage.setItem('showLocationPrompt', 'false');
  }, []);

  useEffect(async () => {
    if (account.accountType !== undefined) {
      setIsTableLoading(true);
      setUserWelcomeInformation({
        prefix: account.prefix,
        lastName: account.lastName,
      });
      const response = await getTableResults(page + 1, pageSize, queryFilters, account.accountType);
      // eslint-disable-next-line max-len
      setResults(response.paginatedResponse.data);
      setTotalPages(response.paginatedResponse.totalPages);
      setTotalRecords(response.paginatedResponse.totalRecords);
      setStatusCounts(response.statusCounts);
      setExportData(response.unpaginatedResponse.data);
      setFilterLists({
        ...filterLists,
        genotypeFilterList: response.filterLists.genotypeFilterList,
        locationFilterList: response.filterLists.locationFilterList,
        hcpFilterList: response.filterLists.hcpFilterList,
      });
      setIsTableLoading(false);
      setIsPageLoading(false);
    }
  }, [page, pageSize, queryFilters, account, popUpLocationAccepted]);

  function handlePagination(selectedPage) {
    setPage(selectedPage);
  }
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleQueryFilters = (params) => {
    setQueryFilters({
      ...params,
    });
  };

  const handleHeaderList = (params) => {
    setHeadersList(params);
  };

  function handleViewButton(viewSize) {
    setPage(0);
    setPageSize(viewSize);
  }
  function currentSearchInputHandler(event) {
    setSearchBar(event.target.value);
  }

  async function handleSearch() {
    setQueryFilters({
      ...queryFilters,
      search: searchBar,
    });
  }

  const handleEnterToSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };
  async function handleClearFilters() {
    setQueryFilters({
      patientNameOrder: null,
      DOBOrder: null,
      collectionDateOrder: null,
      location: null,
      testType: null,
      genotype: null,
      serumLevel: null,
      status: null,
      search: null,
      searchMode: null,
      actionRequired: false,
      priority: false,
      abnormal: false,
      hcp: null,
      accountStatus: null,
      role: null,
      hcpOrManagerNameOrder: null,
      accountManagerNameOrder: null,
    });
    setHeadersList({
      location: false,
      testType: false,
      genotype: false,
      serumLevel: false,
      status: false,
      hcp: false,
    });
    setSearchBar('');
  }

  function quickFilterHandler(quickFilterType) {
    const newFilters = {
      ...queryFilters,
    };
    newFilters[quickFilterType] = !newFilters[quickFilterType];
    if (!newFilters[quickFilterType]) {
      delete newFilters[quickFilterType];
    }
    setQueryFilters({
      ...newFilters,
    });
  }
  function getFirstRowNumber() {
    if (page === 0) {
      return 1;
    }
    if (results.length < pageSize) {
      return (pageSize * page) + 1;
    }
    return (results.length * page) + 1;
  }
  function getLastRowNumber() {
    if (page === 0) {
      if (results.length < pageSize) {
        return results.length;
      }
      return pageSize;
    }
    return (getFirstRowNumber() + results.length) - 1;
  }
  function incrementPriority(inc) {
    setStatusCounts(
      {
        actionReqCount: statusCounts.actionReqCount,
        priorityCount: statusCounts.priorityCount + inc,
        abnormalCount: statusCounts.abnormalCount,
      },
    );
  }

  if (isPageLoading) {
    return (
      <div className={classes.globalSpinnerContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className={classes.welcomeRow}>
        <h1 className={classes.welcomeHeader}>
          Welcome
          {' '}
          {userWelcomeInformation.prefix}
          {' '}
          {userWelcomeInformation.lastName}
        </h1>
        <p className={classes.subHeader}>
          Browse through your recent AlphaID test results.
        </p>
      </div>

      <div>
        <div className={classes.filterCardRow}>
          <FilterCard
            isActive={queryFilters.actionRequired}
            count={statusCounts.actionReqCount}
            filterHandler={() => quickFilterHandler('actionRequired')}
            type="actionRequired"
          />
          <FilterCard
            isActive={queryFilters.priority}
            count={statusCounts.priorityCount}
            filterHandler={() => quickFilterHandler('priority')}
            type="priority"
          />
          <FilterCard
            isActive={queryFilters.abnormal}
            count={statusCounts.abnormalCount}
            filterHandler={() => quickFilterHandler('abnormal')}
            type="abnormal"
          />
          <Hidden smDown>
            <FilterCard type="insights" />
          </Hidden>
        </div>

        <div className={classes.dashboardRow}>
          <ExportButton
            headers={account === ACCOUNT_TYPES.AccountManager
              ? csvAccountManagerHeaders : csvHcpHeaders}
            data={exportData}
            text="EXPORT RESULTS"
          />
          <div className={classes.searchContainer}>
            <Hidden smDown>
              <Input
                placeholder="Search by Patient First Name, Last Name, or DOB (MM/DD/YYYY)"
                className={classes.searchInput}
                value={searchBar}
                onChange={currentSearchInputHandler}
                onKeyPress={handleEnterToSubmit}
                endAdornment={(
                  <InputAdornment
                    position="end"
                    onClick={handleSearch}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    <Search classes={{
                      root: classes.searchIconRoot,
                    }}
                    />
                    {' '}
                  </InputAdornment>
                )}
              />
            </Hidden>
          </div>
        </div>
        <Hidden smDown>
          <div className={classes.dashboardRow}>
            <div className={classes.viewButtonsContainer}>
              <div className={classes.viewText}>
                <span>
                  {' '}
                  {totalRecords}
                  {' '}
                  results
                  {' '}
                </span>
                <span className="view">View:</span>
              </div>
              <button
                type="button"
                className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(15)}
              >
                15
              </button>
              <button
                type="button"
                className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(30)}
              >
                30
              </button>
              <button
                type="button"
                className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(45)}
              >
                45
              </button>
            </div>
            <div className={classes.searchContainer}>
              <Button
                className={classes.clearButton}
                disabled={isTableLoading}
                disableRipple
                onClick={() => handleClearFilters()}
              >
                <p className={classes.clearText}>RESET ALL SEARCH, FILTERS, & SORTING</p>
                <ClearFilterIcon color={COLORS.ABBEY} />
              </Button>
            </div>
          </div>
          <DataTable
            results={results}
            handleViewHandler={handleViewButton}
            handlePageChangeHandler={handlePageChange}
            handleQueryFilters={handleQueryFilters}
            handleHeaderList={handleHeaderList}
            headersList={headersList}
            queryFilters={queryFilters}
            filterLists={filterLists}
            accountType={account}
            incrementPriority={incrementPriority}
            isTableLoading={isTableLoading}
            updateRowPriorityRendering={updateRowPriorityRendering}
          />
          <TablePagination
            selectedPage={page}
            numbers={totalPages}
            handlePageChange={handlePagination}
            records={totalRecords}
            firstRow={getFirstRowNumber()}
            lastRow={getLastRowNumber()}
          />
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            classes={{
              root: classes.mobileButtonGridRootContainer,
            }}
          >
            <Grid
              item
              xs={9}
            >
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'filter')}>
                Filter
                <KeyboardArrowDownIcon />
              </Button>
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'sort')}>
                Sort
                <KeyboardArrowDownIcon />
              </Button>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="flex-end"
            >
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'search')}><SearchIcon /></Button>
            </Grid>
          </Grid>
          <MobileDataTable
            results={results}
            handleViewHandler={handleViewButton}
            handlePageChangeHandler={handlePageChange}
            queryFilters={queryFilters}
            filterLists={filterLists}
            isTableLoading={isTableLoading}
            totalRecords={totalRecords}
            incrementPriority={incrementPriority}
            pageSize={pageSize}
            updateRowPriorityRendering={updateRowPriorityRendering}
            handleClearFilters={handleClearFilters}
          />
          <TablePagination
            type="Mobile"
            selectedPage={page}
            numbers={totalPages}
            handlePageChange={handlePagination}
            records={totalRecords}
            firstRow={getFirstRowNumber()}
            lastRow={getLastRowNumber()}
          />
          <MobileFiltering
            mobileFilterDrawerOpen={mobileFilterDrawerOpen}
            toggleFilterDrawer={toggleFilterDrawer}
            queryFilters={queryFilters}
            filterLists={filterLists}
            handleQueryFilters={handleQueryFilters}
          />
        </Hidden>
        <MissingLocationModal
          modalOpen={showMissingLocationPrompt}
          setModalOpen={setShowMissingLocationPrompt}
        />
        <RecommendedLocationsPopupModal
          autoOpenOnHcpMngrDashboard
          openModalOnClick={false}
          setPopUpLocationAccepted={setPopUpLocationAccepted}
        />
      </div>
    </>
  );
};

export default HCPMngrDashboard;
