import React, { useState } from 'react';
import {
  InputLabel,
  InputBase,
  makeStyles,
  Modal,
  Select,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
} from '@material-ui/core';
import { COLORS, LIST_OF_US_STATES } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';
import { searchLocations, getUnifiedLocationIdToLocationIdMap } from '../api/PracticesService';
import { isValidZipCode } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  modal: {
    overflow: 'scroll',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '100%',
  },
  inputLabel: {
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    color: '#58595B',
  },
  inputStyle: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
  error: {
    marginTop: '6px',
    color: 'red',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  thRoot: {
    borderRadius: '15px',
  },
}));

const AddLocationForPendingAccountModal = (props) => {
  const classes = useStyles();
  const { modalOpen,
    setModalOpen,
    addLocationIdsToList,
    locationIds,
    addUiLocationToSelectedList,
    unifiedLocationToLocationsMapping,
    setUnifiedLocationToLocationsMapping } = props;
  const [addToPracticeClicked, setAddToPracticeClicked] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [locationState, setLocationState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [areInputFieldsEmpty, setAreInputFieldsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [isConfirmationError, setIsConfirmationError] = useState(false);
  const [confirmationError, setConfirmationError] = useState('');

  const [searchResults, setSearchResults] = useState(null);

  function handleAddressLine1OnChange(event) {
    setAddressLine1(event.target.value);
  }
  function handleAddressLine2OnChange(event) {
    setAddressLine2(event.target.value);
  }
  function handleZipCodeOnChange(event) {
    setZipCode(event.target.value);
  }
  function handleCityOnChange(event) {
    setCity(event.target.value);
  }
  function handleLocationStateOnChange(event) {
    setLocationState(event.target.value);
  }
  async function searchLocation() {
    if (!isValidZipCode(zipCode) && zipCode !== '') {
      setIsZipCodeValid(false);
      return;
    }
    if (addressLine1 === '' && locationState === '' && zipCode === '' && city === '') {
      setAreInputFieldsEmpty(true);
      setIsZipCodeValid(true);
      setSearchClicked(false);
      return;
    }
    setAreInputFieldsEmpty(false);
    setIsZipCodeValid(true);
    const searchResultsResponse = await searchLocations(addressLine1,
      addressLine2, city, locationState, zipCode);
    if (searchResultsResponse.status !== 200) {
      setIsError(true);
      setError('There was an error processing your request. Please try again');
      return;
    }
    setError('');
    setIsError(false);
    setSearchResults(searchResultsResponse.data);
    setSearchClicked(true);
  }
  function triggerAddLocationConfirmation(e) {
    setSelectedLocation(e);
    setAddToPracticeClicked(true);
  }
  function handleNotAddingToPracticeClick() {
    setSelectedLocation(null);
    setIsConfirmationError(false);
    setConfirmationError('');
    setAddToPracticeClicked(false);
  }
  function handleModalClose() {
    setAddToPracticeClicked(false);
    setSearchClicked(false);
    setModalOpen(false);
    setAddressLine1('');
    setAddressLine2('');
    setCity('');
    setZipCode('');
    setLocationState('');
    setSelectedLocation(null);
    setError('');
    setIsError(false);
  }
  async function addLocationToPendingAccountHook() {
    let newLocationIds = [];
    if (selectedLocation.isUnifiedLocation) {
      let isUnifiedLocationAlreadyAdded = false;
      const unifiedLocationToLocationMapResponse = await
      getUnifiedLocationIdToLocationIdMap(selectedLocation.uiLocationId);
      if (unifiedLocationToLocationMapResponse.status !== 200) {
        setConfirmationError('There was an error processing your request. Please try again');
        setIsConfirmationError(true);
        return;
      }
      if (unifiedLocationToLocationsMapping.length !== 0) {
        unifiedLocationToLocationsMapping.forEach((value) => {
          if (value.unifiedLocationId === selectedLocation.uiLocationId) {
            setConfirmationError('Error: Location is already in your practice.');
            setIsConfirmationError(true);
            isUnifiedLocationAlreadyAdded = true;
          }
        });
      }
      if (!isUnifiedLocationAlreadyAdded) {
        setUnifiedLocationToLocationsMapping([
          ...unifiedLocationToLocationsMapping, unifiedLocationToLocationMapResponse.data]);
        newLocationIds = unifiedLocationToLocationMapResponse.data.locationIds;
        addUiLocationToSelectedList(selectedLocation);
      }
    } else {
      if (locationIds.indexOf(selectedLocation.uiLocationId) > -1) {
        setConfirmationError('Error: Location is already in your practice.');
        setIsConfirmationError(true);
        return;
      }
      newLocationIds = [selectedLocation.uiLocationId];
      addUiLocationToSelectedList(selectedLocation);
    }
    if (newLocationIds.length !== 0) {
      setError('');
      setIsError(false);
      addLocationIdsToList(newLocationIds);
      handleModalClose();
    }
  }
  function renderSearchResults() {
    if (searchClicked && searchResults.length !== 0) {
      return (
        <TableContainer className={classes.root} component={Paper}>
          <Table
            classes={{
              root: classes.tableRoot,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {searchResults.map((item) => (
                <TableRow
                  key={item.uiLocationId}
                  classes={{
                    root: classes.row,
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{
                      root: classes.thRoot,
                    }}
                  >
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Address:
                            {' '}
                            {item.address}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            City:
                            {' '}
                            {item.city}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Zip Code:
                            {' '}
                            {item.zipCode}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            State:
                            {' '}
                            {item.state}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <ButtonComponent
                          onClick={() => triggerAddLocationConfirmation(item)}
                        >
                          Add to my practice
                        </ButtonComponent>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    if (searchResults.length === 0 && searchClicked) {
      return (
        <>
          <p>
            There are no locations matching the search criteria.
            Please edit the information and try again.
            You may also try searching by zip code, city, or state only.
          </p>
        </>
      );
    }
    return (
      <></>
    );
  }

  function renderConfirmation() {
    if (addToPracticeClicked) {
      return (
        <>
          <p>
            You are about to add a location
            to your practice.
          </p>
          <p>{selectedLocation.addressLine1}</p>
          <p>{selectedLocation.city}</p>
          <p>{selectedLocation.state}</p>
          <p>{selectedLocation.zipCode}</p>
          <p>
            Do you wish to proceed with claiming this location?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={addLocationToPendingAccountHook}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleNotAddingToPracticeClick}
            >
              no
            </ButtonComponent>
          </div>
          {isConfirmationError && <p className={classes.error}>{confirmationError}</p>}
        </>
      );
    }
    return null;
  }

  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
      className={classes.modal}
    >
      <Paper className={classes.modalBackground}>
        <span className={classes.title}>
          Fill out at least one field below to search locations:
        </span>
        <div className={classes.inputContainer}>
          <InputLabel htmlFor="addressLine1" className={classes.inputLabel}>Street Address:</InputLabel>
          <InputBase
            name="addressLine1"
            value={addressLine1}
            onChange={handleAddressLine1OnChange}
            className={classes.inputStyle}
          />
          <InputLabel htmlFor="addressLine2" className={classes.inputLabel}>Unit/Suite/Apt:</InputLabel>
          <InputBase
            name="addressLine2"
            value={addressLine2}
            onChange={handleAddressLine2OnChange}
            className={classes.inputStyle}
          />
          <InputLabel htmlFor="city" className={classes.inputLabel}>City:</InputLabel>
          <InputBase
            name="city"
            value={city}
            onChange={handleCityOnChange}
            type="string"
            className={classes.inputStyle}
          />
          <InputLabel htmlFor="zipCode" className={classes.inputLabel}>Zip Code:</InputLabel>
          <InputBase
            name="zipCode"
            value={zipCode}
            onChange={handleZipCodeOnChange}
            type="number"
            className={classes.inputStyle}
          />
          <InputLabel id="locationState" className={classes.inputLabel}>State:</InputLabel>
          <Select
            labelId="locationState"
            value={locationState}
            onChange={handleLocationStateOnChange}
            className={classes.inputStyle}
            placeholder="Select a state"
          >
            {Object.entries(LIST_OF_US_STATES)
              .map(([abbr, item]) => <MenuItem value={abbr} key={abbr}>{item}</MenuItem>)}
          </Select>
        </div>
        <ButtonComponent
          onClick={searchLocation}
        >
          Search for Location
        </ButtonComponent>
        {!isZipCodeValid && <p>Please enter a valid 5-digit zip code.</p>}
        {areInputFieldsEmpty
        && <p className={classes.error}>Additional input required to perform location search</p>}
        {isError && <p className={classes.error}>{error}</p>}
        {searchClicked && !addToPracticeClicked && renderSearchResults()}
        {addToPracticeClicked && renderConfirmation()}
      </Paper>
    </Modal>
  );
};

export default AddLocationForPendingAccountModal;
