import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../utils/applicationConstants';

const useTableStylesHcp = makeStyles(() => ({
  root: {
    marginTop: '18px',
    border: 'none',
    width: 'inherit',
    '& .MuiDataGrid-main': {
      width: 'inherit !important',
      overflow: 'visible',
    },
    '& .MuiDataGrid-windowContainer': {
      height: '96%',
      width: '100%',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderRadius: '15px',
      background: COLORS.AZURE,
      position: 'absolute',
      color: COLORS.WHITE,
      fontSize: '13px',
    },
    '& .MuiDataGrid-root .MuiDataGrid-withBorder': {
      borderRight: 'none',
    },

    '& .MuiDataGrid-viewport': {
      overflow: 'unset',
    },
    '& MuiDataGrid-iconButtonContainer ': {
      display: 'none',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
      color: COLORS.WHITE,
      opacity: '1',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
      overflow: 'auto',
      '&:focus': {
        outline: 'none',
      },
      maxHeight: 'fit-content !important',
      lineHeight: '16px !important',
      display: 'flex !important',
      alignItems: 'left',
      paddingTop: '18px !important',
      paddingBottom: '10px !important',
    },
    '& .MuiDataGrid-row, .MuiDataGrid-renderingZone': {
      maxHeight: 'fit-content !important',
    },

    '& .status-col': {
      borderRight: 'none',
      borderRadius: '15px',
    },
    '& .MuiDataGrid-menuIcon': {
      display: 'none',
    },
    '& .desktop-status': {
      display: 'inline-block',
    },

    '& .MuiDataGrid-renderingZone': {
      marginTop: '3px',
      '&:focus': {
        background: '#F4F4F4 !important',
      },
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
      display: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      background: COLORS.MERCURY,
      fontWeight: '700',
      color: COLORS.BLACK,
      '&:hover': {
        background: COLORS.MERCURY,
      },
    },
    '& .MuiDataGrid-columnHeader--sortable': {
      padding: '0px !important',
    },
  },
  cell: {
    border: 'none',
    '&:not(:last-child)': {
      borderRight: `2px solid ${COLORS.MISCHKA}`,
    },
    fontSize: '13px',
    position: 'relative',
  },
  row: {
    background: COLORS.WILD_SAND,
    borderRadius: '15px',
    color: COLORS.ABBEY,
    marginTop: '2px',
    width: '100% !important',
    overflow: 'visible',
  },
  columnHeader: {
    '&:not(:last-child)': {
      borderRight: `2px solid ${COLORS.WHITE}`,
    },
    padding: '0px !important',
  },
  viewText: {
    marginLeft: '15px',
  },
}));

export { useTableStylesHcp };
