import React from 'react';
import { Hidden } from '@material-ui/core';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const Navigation = () => (
  <nav>
    <Hidden smDown>
      <DesktopNavigation />
    </Hidden>
    <Hidden mdUp>
      <MobileNavigation />
    </Hidden>
  </nav>
);

export default Navigation;
