import React from 'react';
import { Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import { ExportIcon } from '../utils/Icons';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles(() => ({
  csvExportDiv: {
    width: '52%',
    display: 'flex',
  },
  csvButton: {
    background: COLORS.AZURE_RAD,
    borderRadius: '30px',
    width: '225px',
    height: '50px',
  },
  adminCsvButton: {
    backgroundColor: COLORS.AZURE_RAD,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
  csvLink: {
    position: 'relative',
    top: '28%',
    marginLeft: '12%',
    fontSize: '16px',
    fontWeight: '700',
    font: 'inherit',
    textDecoration: 'none',
    color: COLORS.WHITE,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  exportIcon: {
    position: 'absolute !important',
    marginLeft: '16px',
  },
  adminCsvButtonText: {
    color: COLORS.WHITE,
    textDecoration: 'none',
  },
}));

const ExportButton = (props) => {
  const classes = useStyles();
  const { headers, data, caller, text } = props;
  const csvReport = {
    data,
    headers,
    filename: 'AlphaID_Report.csv',
  };
  const accountsData = {
    data,
    headers,
    filename: 'AlphaID_accounts.csv',
  };
  return caller === ACCOUNT_TYPES.GrifolsAdmin || caller === ACCOUNT_TYPES.Approver
    ? (
      <Button variant="contained" className={classes.adminCsvButton} component="span">
        <CSVLink
          className={classes.adminCsvButtonText}
          {...accountsData}
        >
          {text}
        </CSVLink>
      </Button>
    )
    : (
      <div className={classes.csvExportDiv}>
        <Hidden smDown>
          <div className={classes.csvButton}>
            <CSVLink
              className={classes.csvLink}
              {...csvReport}
            >
              {text}
              <span className={classes.exportIcon}>
                <ExportIcon size={23} color={COLORS.WHITE} />
              </span>
            </CSVLink>
          </div>
        </Hidden>
      </div>
    );
};

export default ExportButton;
