import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../utils/applicationConstants';
import { getInProcessFormattedDate } from '../utils/dateFormatter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    overflowX: 'visible',
  },
  row: {
    background: COLORS.MERCURY,
    borderRadius: '15px',
    border: 'none',
    height: '184px',
    padding: '0 24px',
  },
  thRoot: {
    borderRadius: '15px',
  },
  priorityPaperRoot: {
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  tableRoot: {
    borderCollapse: 'inherit !important',
    borderSpacing: '2px',
  },
  p: {
    display: 'inline-block',
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  mobileButtonGridRootContainer: {
    marginBottom: '24px',
  },
  buttonGroup: {
    color: COLORS.ABBEY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '24px',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
}));

const PracticeTableMobile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    results,
    isTableLoading,
    pageSize,
  } = props;

  if (isTableLoading) {
    return <CircularProgress />;
  }
  function goToPracticeView(currentRow) {
    history.push(`/practices/${currentRow.acctManagerId}`, {
      currentRow,
    });
  }
  return (
    <>
      <div className={classes.root}>
        <TableContainer className={classes.root} component={Paper}>
          <Table
            classes={{
              root: classes.tableRoot,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {(pageSize > 0
                ? results.slice(0, pageSize)
                : results
              ).map((item) => (
                <TableRow
                  key={item.id}
                  classes={{
                    root: classes.row,
                  }}
                  onClick={() => goToPracticeView(item)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{
                      root: classes.thRoot,
                    }}
                  >
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Name:
                            {' '}
                            {item.acctManagerFirstName}
                            ,
                            {' '}
                            {item.acctManagerLastName}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Cell Phone:
                            {' '}
                            {item.acctManagerEmail}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Creation Date:
                            {' '}
                            {getInProcessFormattedDate(item.creationDate)}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    </>
  );
};
export default PracticeTableMobile;
