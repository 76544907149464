import { axiosInstance } from '../utils/AxiosConfiguration';

export function DownloadResultPdfAPI(resultId) {
  return axiosInstance.post('DownloadResultPdf', {
    resultId,
  });
}

export async function handleResultPdf(resultObject) {
  const { resultId, lastName } = resultObject;
  const response = await DownloadResultPdfAPI(resultId);
  const byteCharacters = atob(response.data);
  const byteNumbers = new Array(byteCharacters.length);
  [...byteCharacters].forEach((item, index) => {
    byteNumbers[index] = byteCharacters.charCodeAt(index);
  });
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type: 'application/pdf;base64',
  });
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `AlphaID_Report_${lastName}.pdf`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
