/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Hidden, makeStyles, Paper, Modal } from '@material-ui/core';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import GetPendingAccounts from '../api/GetPendingAccounts';
import PendingAccountsTable from './PendingAccountsTable';
import PendingAccountMobile from './PendingAccountsMobile';
import TablePagination from './Pagination';
import ButtonComponent from './common/ButtonComponent';
import ApprovePendingAccount from '../api/ApprovePendingAccount';
import DenyPendingAccount from '../api/DenyPendingAccount';
import { getAllActiveAndPendingAccounts } from '../api/AccountService';
import ExportButton from './ExportButton';
import { activeAccountsHeaders } from '../utils/headers';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    marginTop: '72px',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '40%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '180px',
    marginRight: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
  },
  exportContainer: {
    marginBottom: '26px',
  },
  errorMessage: {
    color: 'red',
    paddingTop: '1rem',
  },
}));

const Practices = () => {
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [currentAcctId, setCurrentAcctId] = useState();
  const [currentacctName, setCurrentAcctName] = useState();
  const [showApproveModal, setShowApproveModal] = useState();
  const [showApproveErrorText, setShowApproveErrorText] = useState(false);
  const [approveErrorText, setApproveErrorText] = useState(null);
  const [showDenyModal, setShowDenyModal] = useState();
  const [showDenyErrorText, setShowDenyErrorText] = useState(false);
  const [denyErrorText, setDenyErrorText] = useState(null);
  const [isLoaded, setLoadedState] = useState(false);
  const classes = useStyles();
  const [pendingAcctsResults, setPracticeResults] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [activeAccounts, setActiveAccounts] = useState([]);

  function toggleApproveModal({ id, name }) {
    setShowApproveModal(!showApproveModal);
    setApproveErrorText('');
    setCurrentAcctId(id);
    setCurrentAcctName(name);
  }

  function toggleDenyModal({ id, name }) {
    setShowDenyModal(!showDenyModal);
    setDenyErrorText('');
    setCurrentAcctId(id);
    setCurrentAcctName(name);
  }

  async function approveAccount(id) {
    try {
      await ApprovePendingAccount(id);
      setTriggerUseEffect(!triggerUseEffect);
      toggleApproveModal({
        id: null,
        name: null,
      });
      setShowApproveErrorText(false);
    } catch (e) {
      setApproveErrorText(e.response.data);
      setShowApproveErrorText(true);
      Error(e);
    }
  }

  async function denyAccount(id) {
    try {
      await DenyPendingAccount(id);
      setTriggerUseEffect(!triggerUseEffect);
      toggleDenyModal({
        id: null,
        name: null,
      });
      setShowDenyErrorText(false);
    } catch (e) {
      setDenyErrorText(e.response.data);
      setShowDenyErrorText(true);
      Error(e);
    }
  }

  useEffect(async () => {
    setLoadedState(true);
    setIsTableLoading(true);
    try {
      const pendingResultsResponse = await GetPendingAccounts(page + 1, pageSize);
      const activeAccountsResponse = await getAllActiveAndPendingAccounts();
      setActiveAccounts(activeAccountsResponse);
      setIsTableLoading(false);
      setTotalPages(pendingResultsResponse.totalPages);
      setTotalRecords(pendingResultsResponse.totalRecords);
      setPracticeResults(pendingResultsResponse.data);
    } catch (e) {
      Error(e);
    }
  }, [page, pageSize, triggerUseEffect]);

  if (!isLoaded) {
    return <CircularProgress />;
  }
  function handleViewButton(viewSize) {
    setPage(0);
    setPageSize(viewSize);
  }
  function handlePageChange(params) {
    setPage(params.page);
  }
  function getFirstRowNumber() {
    if (page === 0) {
      return 1;
    }
    if (pendingAcctsResults.length < pageSize) {
      return (pageSize * page) + 1;
    }
    return (pendingAcctsResults.length * page) + 1;
  }
  function getLastRowNumber() {
    if (page === 0) {
      if (pendingAcctsResults.length < pageSize) {
        return pendingAcctsResults.length;
      }
      return pageSize;
    }
    return (getFirstRowNumber() + pendingAcctsResults.length) - 1;
  }
  function handlePagination(selectedPage) {
    setPage(selectedPage);
  }
  return (
    <div>
      <h1 className={classes.pageHeader}>
        Pending Accounts
      </h1>
      <Hidden smDown>
        <div className={classes.exportContainer}>
          <ExportButton
            caller={ACCOUNT_TYPES.Approver}
            headers={activeAccountsHeaders}
            data={activeAccounts}
            text="EXPORT ALL HCPs"
          />
        </div>
        <div className={classes.buttonGroup}>
          <span>
            {' '}
            {totalRecords}
            {' '}
            results
            {' '}
          </span>
          <span className={classes.viewText}>View:</span>
          <button
            type="button"
            className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(15)}
          >
            15
          </button>
          <button
            type="button"
            className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(30)}
          >
            30
          </button>
          <button
            type="button"
            className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(45)}
          >
            45
          </button>
        </div>
        <PendingAccountsTable
          results={pendingAcctsResults}
          handleViewHandler={handleViewButton}
          handlePageChangeHandler={handlePageChange}
          isTableLoading={isTableLoading}
          toggleApproveModal={toggleApproveModal}
          toggleDenyModal={toggleDenyModal}
        />
        <TablePagination
          selectedPage={page}
          numbers={totalPages}
          handlePageChange={handlePagination}
          records={totalRecords}
          firstRow={getFirstRowNumber()}
          lastRow={getLastRowNumber()}
        />
      </Hidden>
      <Hidden mdUp>
        <PendingAccountMobile
          toggleApproveModal={toggleApproveModal}
          toggleDenyModal={toggleDenyModal}
          results={pendingAcctsResults}
          handleViewHandler={handleViewButton}
          totalRecords={totalRecords}
          queryFilters={{
          }}
        />
      </Hidden>
      <Modal
        onClose={() => setShowApproveModal(false)}
        open={showApproveModal}
        classeName={classes.modal}
      >
        <Paper className={classes.modalBackground}>
          <span className={classes.title}>
            Confirm Account Approval for
            {' '}
            {currentacctName}
          </span>
          <p>
            You are about to grant access to this user to log into the AlphaID
            Results Portal and view AlphaID screening results under this name.
            Do you wish to proceed?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.button}
              onClick={() => approveAccount(currentAcctId)}
            >
              YES
            </ButtonComponent>
            <ButtonComponent
              type="abbey"
              className={classes.button}
              onClick={() => toggleApproveModal({
                id: null,
                name: null,
              })}
            >
              NO
            </ButtonComponent>
          </div>
          <div>
            {showApproveErrorText && (
              <Typography className={classes.errorMessage}>
                {approveErrorText}
              </Typography>
            )}
          </div>
        </Paper>
      </Modal>
      <Modal
        onClose={() => setShowDenyModal(false)}
        open={showDenyModal}
        classeName={classes.modal}
      >
        <Paper className={classes.modalBackground}>
          <span className={classes.title}>
            Confirm Account Denial for
            {' '}
            {currentacctName}
          </span>
          <p>
            You are about to deny access to this user to log into the AlphaID Results Portal.
            If you decline this request, they will receive notice that their account was denied
            by a Grifols Admin and will have the ability to delete their account
            and resubmit their request.
            Do you wish to proceed?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.button}
              onClick={() => denyAccount(currentAcctId)}
            >
              YES
            </ButtonComponent>
            <ButtonComponent
              type="abbey"
              className={classes.button}
              onClick={() => toggleDenyModal({
                id: null,
                name: null,
              })}
            >
              NO
            </ButtonComponent>
          </div>
          <div>
            {showDenyErrorText && (
              <Typography className={classes.errorMessage}>
                {denyErrorText}
              </Typography>
            )}
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default Practices;
