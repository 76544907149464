import { axiosInstance } from '../utils/AxiosConfiguration';

async function getCurrentAccountRep() {
  let accountRepResponse;
  try {
    accountRepResponse = await axiosInstance.get('/AssociatedRep');
  } catch (e) {
    Error(e);
  }
  return accountRepResponse?.data;
}

export { getCurrentAccountRep };
