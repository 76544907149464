import React, { useState, useEffect } from 'react';
import { makeStyles, Button, Typography, InputLabel, CircularProgress, Grid, TextField, Box } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import UpdateRepMapping from '../api/UpdateRepMapping';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import ExportButton from '../components/ExportButton';
import DeghostAccountModal from '../components/DeghostAccountModal';
import { activeAccountsHeaders, migrationHeaders } from '../utils/headers';
// eslint-disable-next-line no-unused-vars
import { getAllActiveAndPendingAccounts, migrateHcpsAsync, migrateHcpToManager, deleteUser } from '../api/AccountService';
import { createGhostPractice } from '../api/PracticesService';
import ClipboardCopy from '../components/CopyToClipboard';

const useStyles = makeStyles(({
  myRepsRoot: {
    marginTop: '150px',
    height: 'calc(100vh - 300px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    display: 'none',
  },
  hcpToManagerTextField: {
    border: '1px solid #58595B !important',
    borderRadius: '5px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
    height: '95%',
    alignContent: 'center',
  },
  textFieldInput: {
    fontSize: '12px',
  },
  responseText: {
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Roboto Condensed',
    '&.copyMessage': {
      marginBottom: '40px',
      fontWeight: '600',
    },
  },
  spinnerText: {
    fontSize: '16px',
    fontFamily: 'Roboto Condensed',
  },
  messageContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
  },
  errorList: {
    overflow: 'auto',
    maxHeight: '190px',
    width: 'auto',
    maxWidth: '60%',
  },
  error: {
    '&:nth-of-type(odd)': {
      backgroundColor: COLORS.WILD_SAND,
    },
    fontFamily: 'Roboto',
  },
  errorResponseText: {
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'center',
    fontSize: '16px',
    color: 'red',
    fontFamily: 'Roboto Condensed',
    '&.copyMessage': {
      marginBottom: '40px',
      fontWeight: '600',
    },
  },
  spinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  uploadBtn: {
    backgroundColor: COLORS.AZURE_RAD,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
  migrationBtn: {
    backgroundColor: COLORS.ORANGE,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.WEB_ORANGE,
    },
  },
  btnDisabled: {
    backgroundColor: COLORS.ATHENS_GREY,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.ATHENS_GREY,
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  deghostAccountInput: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
  },
  inputLabel: {
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    color: '#58595B',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const AdminHome = () => {
  const classes = useStyles();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [file, setFile] = useState(null);
  const [migrationFile, setMigrationFile] = useState(null);
  const [ghostPracticeFile, setGhostPracticeFile] = useState(null);
  const [deghostModalShow, setDeghostModalShow] = useState(false);
  const [errors, setErrors] = useState(null);
  const [uploadVal, setUploadVal] = useState();
  const [hcpToManagerEmail, setHcpToManagerEmail] = useState('');
  const [isHcpToManagerSuccess, setIsHcpToManagerSuccess] = useState(false);
  const [hcpToManagerError, setHcpToManagerError] = useState(null);
  const [deleteUserEmail, setDeleteUserEmail] = useState('');
  const [isDeleteUserSuccess, setIsDeleteUserSuccess] = useState(false);
  const [deleteUserError, setDeleteUserError] = useState(null);
  const [migrationBtnUploadVal, setMigrationBtnUploadVal] = useState('');
  const [ghostPracticeBtnFileUploadVal, setGhostPracticeBtnFileUploadVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRepMapSuccess, setIsRepMapSuccess] = useState(false);
  const [isGhostPracticeSuccess, setIsGhostPracticeSuccess] = useState(false);
  const [ghostPracticeSuccessMessage, setGhostPracticeSuccessMessage] = useState(false);
  const [isRepsCreated, setIsRepsCreated] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [newRepsCreated, setNewRepsCreated] = useState({
    repsAdded: '',
    tempPassword: '',
    repsDeleted: '',
  });
  const [migratedHcpAccounts, setMigratedHcpAccounts] = useState([]);
  const [hasCompletedMigration, setHasCompletedMigration] = useState(false);

  const submitFile = async () => {
    let res;
    try {
      setIsLoading(true);
      setErrors(null);
      setIsRepMapSuccess(false);
      setHasCompletedMigration(false);
      res = await UpdateRepMapping(file);
      if (res.data !== null || res.data !== '') {
        const { repsAdded, tempPassword, repsDeleted } = res.data;
        setIsRepsCreated(true);
        setNewRepsCreated({
          repsAdded,
          tempPassword,
          repsDeleted,
        });
      }
      if (res.status === 200) {
        setIsRepMapSuccess(true);
        setErrors(null);
        setIsLoading(false);
        setFile(null);
        setUploadVal(null);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setIsUnauthorized(true);
      }
      setErrors(error.response.data);
      setIsRepMapSuccess(false);
      setIsLoading(false);
      setFile(null);
      setUploadVal(null);
      Error(error);
    }
  };

  const submitMigrationFile = async () => {
    if (migrationFile !== null) {
      let res;
      try {
        setIsLoading(true);
        setErrors(null);
        res = await migrateHcpsAsync(migrationFile);
        if (res.data !== null || res.data !== '') {
          setMigratedHcpAccounts(res.data.migratedHcpAccounts);
          setHasCompletedMigration(true);
        }
        if (res.status === 200) {
          setErrors(null);
          setIsLoading(false);
          setMigrationFile(null);
          setMigrationBtnUploadVal(null);
        }
      } catch (error) {
        setErrors(error.response.data);
        setIsLoading(false);
        setMigrationFile(null);
        setMigrationBtnUploadVal(null);
        Error(error);
      }
    }
  };

  const submitGhostPracticeFile = async () => {
    if (ghostPracticeFile !== null) {
      let res;
      try {
        setIsLoading(true);
        setErrors(null);
        res = await createGhostPractice(ghostPracticeFile);
        if (res.status === 200 && res.data.isSuccess) {
          setErrors(null);
          setGhostPracticeSuccessMessage(res.data.messages);
          setIsGhostPracticeSuccess(true);
          setIsLoading(false);
          setGhostPracticeFile(null);
          setGhostPracticeBtnFileUploadVal(null);
        }
      } catch (error) {
        setErrors(error.response.data.messages);
        setIsGhostPracticeSuccess(false);
        setIsLoading(false);
        setGhostPracticeFile(null);
        setGhostPracticeBtnFileUploadVal(null);
        Error(error);
      }
    }
  };

  const submitEmailToMigrateHcpToManager = async () => {
    let res;
    setIsLoading(true);
    setHcpToManagerError(null);
    setIsHcpToManagerSuccess(false);
    try {
      res = await migrateHcpToManager({
        Email: hcpToManagerEmail,
      });
      if (res.status === 200) {
        setIsHcpToManagerSuccess(true);
        setHcpToManagerError(null);
        setIsLoading(false);
        setHcpToManagerEmail('');
      } else {
        setIsLoading(false);
        setHcpToManagerError(res.data);
      }
    } catch (error) {
      setHcpToManagerError(error.message);
      setIsLoading(false);
      Error(error);
    }
  };

  const submitDeleteUserRequest = async () => {
    let res;
    setIsLoading(true);
    setDeleteUserError(null);
    setIsDeleteUserSuccess(false);
    try {
      res = await deleteUser(deleteUserEmail);
      if (res.status === 204) {
        setIsDeleteUserSuccess(true);
        setDeleteUserError(null);
        setIsLoading(false);
        setDeleteUserEmail('');
      } else {
        setIsLoading(false);
        setDeleteUserError(res.data);
      }
    } catch (error) {
      setDeleteUserError(error.message);
      setIsLoading(false);
      Error(error);
    }
  };

  useEffect(async () => {
    if (!isPageLoaded) {
      setIsPageLoaded(true);
    }
    if (migrationFile !== null) {
      submitMigrationFile();
    }
    const activeAccountsResponse = await getAllActiveAndPendingAccounts();
    setActiveAccounts(activeAccountsResponse);
    if (file !== null) {
      submitFile();
    }
    if (ghostPracticeFile !== null) {
      submitGhostPracticeFile();
    }
    setIsPageLoaded(false);
  }, [file, migrationFile, ghostPracticeFile]);

  if (isUnauthorized) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className={classes.myRepsRoot}>
      <div>
        <InputLabel htmlFor="Migrate-hcp-to-manager" className={classes.inputLabel}>MIGRATE HCP TO ACCOUNT MANAGER</InputLabel>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              id="emailInput"
              name="emailInput"
              variant="standard"
              value={hcpToManagerEmail}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.textFieldInput,
                },
              }}
              className={classes.hcpToManagerTextField}
              onChange={(e) => { setHcpToManagerEmail(e.target.value); }}
              placeholder="User Email"
            />
          </Grid>
          <Grid item x={3}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="contained" className={classes.migrationBtn} component="span" onClick={() => { submitEmailToMigrateHcpToManager(); }}> SUBMIT </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <br />
      <label htmlFor="Migration-button">
        <Button variant="contained" className={classes.migrationBtn} component="span">
          Migrate Hcps
        </Button>
        <input
          className={classes.input}
          type="file"
          accept=".csv"
          id="Migration-button"
          name="Migration-button"
          value={migrationBtnUploadVal}
          onChange={(e) => { setMigrationFile(e.target.files[0]); setMigrationBtnUploadVal(''); }}
        />
      </label>
      <br />
      <label htmlFor="Ghost-practice-button">
        <Button variant="contained" className={classes.migrationBtn} component="span">
          Create Ghost Practices
        </Button>
        <input
          className={classes.input}
          type="file"
          accept=".csv"
          id="Ghost-practice-button"
          name="Ghost-practice-button"
          value={ghostPracticeBtnFileUploadVal}
          onChange={(e) => { setGhostPracticeFile(e.target.files[0]); setGhostPracticeBtnFileUploadVal(''); }}
        />
      </label>
      <br />
      <ExportButton
        caller={ACCOUNT_TYPES.GrifolsAdmin}
        headers={activeAccountsHeaders}
        data={activeAccounts}
        text="EXPORT ALL HCPs"
      />
      <br />
      <Button variant="contained" className={classes.uploadBtn} component="span" onClick={() => { setDeghostModalShow(true); }}>
        Deghost Account
      </Button>
      <DeghostAccountModal
        modalOpen={deghostModalShow}
        setModalOpen={setDeghostModalShow}
      />
      <br />
      <label htmlFor="upload-button">
        <Button variant="contained" className={classes.uploadBtn} component="span">
          Upload Rep Mapping
        </Button>
        <input
          className={classes.input}
          type="file"
          accept=".csv"
          id="upload-button"
          name="upload-button"
          value={uploadVal}
          onChange={(e) => { setFile(e.target.files[0]); setUploadVal(''); }}
        />
      </label>
      <div className={classes.messageContainer}>
        {isLoading && (
          <div className={classes.spinnerContainer}>
            <CircularProgress />
            <Typography className={classes.spinnerText}>
              Please wait, this may take a minute...
            </Typography>
          </div>
        )}
        {isRepMapSuccess
          && (
            <Typography className={classes.responseText}>
              Success! The Representative Map was successfully updated.
            </Typography>
          )}
        {hasCompletedMigration
          && (
            <>
              <Typography className={classes.responseText}>
                Success! Migration is complete.
                {' '}
                <br />
                {' '}
                Please download the file containing temporary passwords before any further
                interactions with the portal.
              </Typography>
              <ExportButton
                caller={ACCOUNT_TYPES.GrifolsAdmin}
                headers={migrationHeaders}
                data={migratedHcpAccounts}
                text="Download Migrated Account Information"
              />
            </>
          )}
        {
          (isRepsCreated && !errors)
          && (
            <div>
              <Typography className={`${classes.responseText} copyMessage`}>
                Here are the new Reps that you just added to the AlphaID Results Portal and
                the temporary passwords that have been automatically generated for them.
                Please email these Reps directly with the temporary password they should
                use to log in for the first time before they can change it.
                We recommend copying these email addresses and temporary passwords before
                further interaction with the portal.
              </Typography>
              <ClipboardCopy textToCopy={newRepsCreated.repsAdded} />
              <Typography className={classes.responseText}>
                Temporary password
              </Typography>
              <ClipboardCopy textToCopy={newRepsCreated.tempPassword} />
              <Typography className={classes.responseText}>
                Deleted Accounts
              </Typography>
              <ClipboardCopy textToCopy={newRepsCreated.repsDeleted} />
            </div>
          )
        }
        {isGhostPracticeSuccess && (
          <ol className={classes.responseText}>
            { /* eslint-disable-next-line max-len, react/jsx-one-expression-per-line */}
            {ghostPracticeSuccessMessage.map((message) => <li key={message} className={classes.responseText}>{message}</li>)}
          </ol>
        )}
        {errors && (
          <div className={classes.errorContainer}>
            <Typography className={classes.responseText}>
              The uploaded document contained the following errors:
            </Typography>
            <ol className={classes.errorList}>
              {errors.map((error) => <li key={error} className={classes.error}>{error}</li>)}
            </ol>
          </div>
        )}
        {isHcpToManagerSuccess && (
          <Typography className={classes.responseText}>
            HCP Successful Migrated to Account Manager
          </Typography>
        )}
        {hcpToManagerError && (
          <div className={classes.errorContainer}>
            <Typography className={classes.errorResponseText}>
              {hcpToManagerError}
            </Typography>
          </div>
        )}
        {isDeleteUserSuccess && (
          <Typography className={classes.responseText}>
            Successfully Deleted User
          </Typography>
        )}
        {deleteUserError && (
          <div className={classes.errorContainer}>
            <Typography className={classes.errorResponseText}>
              {deleteUserError}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <InputLabel htmlFor="delete-user" className={classes.inputLabel}>DELETE USER FROM SYSTEM (B2C & HCP)</InputLabel>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              id="emailInput"
              name="emailInput"
              variant="standard"
              value={deleteUserEmail}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.textFieldInput,
                },
              }}
              className={classes.hcpToManagerTextField}
              onChange={(e) => { setDeleteUserEmail(e.target.value); }}
              placeholder="User Email to Delete"
            />
          </Grid>
          <Grid item x={3}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="contained" className={classes.migrationBtn} component="span" onClick={() => { submitDeleteUserRequest(); }}> DELETE </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default AdminHome;
