import React, { useState } from 'react';
import { Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer, FormControl, FormControlLabel,
  Grid,
  makeStyles, Radio, RadioGroup,
  Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { COLORS } from '../utils/applicationConstants';
import { ClearFilterIcon } from '../utils/Icons';

const useStyles = makeStyles(({
  cancelButton: {
    textTransform: 'none',
    fontSize: '1.25rem',
  },
  drawerPad: {
  },
  drawer: {
    marginTop: '1rem',
  },
  clearButton: {
    textTransform: 'none',
  },
  clearText: {
    marginRight: '0.3rem',
    fontSize: '1.1rem',
  },
  filterHeader: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
  filterHeaderContainer: {
    padding: '0.5rem',
    paddingTop: '1rem',
  },
  filterHeaderTextContainer: {
    paddingBottom: '2rem',
    paddingTop: '0.5rem',
  },
  divider: {
    borderTop: `1px solid ${COLORS.MISCHKA}`,
    padding: '0.5rem',
  },
  accordion: {
    borderBottom: `1px solid ${COLORS.MISCHKA}`,
    padding: '0.5rem',
  },
  radio: {
    '&$checked': {
      color: '#0B7AEF',
    },
  },
  checked: {
  },
  accordionContainer: {
    padding: '1rem',
  },
  applyFilteringButton: {
    backgroundColor: COLORS.AZURE_RAD,
    color: COLORS.WHITE,
    padding: '1rem 3.5rem',
    borderRadius: '1.75rem',
    alignSelf: 'center',
    marginBottom: '1rem',

    '&:hover': {
      backgroundColor: COLORS.AZURE,
      color: COLORS.WHITE,
      padding: '1rem 3.5rem',
      borderRadius: '1.75rem',
      alignSelf: 'center',
    },
  },
  applyFilteringText: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  searchInput: {
    backgroundColor: COLORS.WILD_SAND,
    margin: '1rem',
    marginBottom: '2rem',
    borderRadius: '30px',
    height: '50px',
    paddingLeft: '32px',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  searchIconRoot: {
    paddingRight: '32px',
  },
}));

const HcpMobileFiltering = (props) => {
  const {
    mobileFilterDrawerOpen,
    toggleFilterDrawer,
    handleQueryFilters,
    queryFilters,
  } = props;
  const classes = useStyles();
  const [expandedFilter, setExpandedFilter] = useState(false);
  const changeExpandedFilter = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : false);
  };
  const [selectedFilters, setSelectedFilters] = useState({
    firstNameOrder: queryFilters.firstName,
    lastNameOrder: queryFilters.lastName,
  });
  const handleSortChange = (e, sort) => {
    if (sort === 'firstName') {
      setSelectedFilters({
        firstNameOrder: e.target.value,
        lastNameOrder: null,
      });
    }
    if (sort === 'lastName') {
      setSelectedFilters({
        firstNameOrder: null,
        lastNameOrder: e.target.value,
      });
    }
    if (sort === 'clear') {
      setSelectedFilters({
        firstNameOrder: null,
        lastNameOrder: null,
      });
    }
  };
  const applyFiltering = (e) => {
    toggleFilterDrawer(e, null);
    handleQueryFilters({
      ...selectedFilters,
    });
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={mobileFilterDrawerOpen}
        anchor="bottom"
        onClose={(e) => toggleFilterDrawer(e, false)}
      >
        <div className={classes.filterHeaderContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.filterHeaderTextContainer}
          >
            <Grid
              item
              xs={4}
            >
              <Button
                className={classes.cancelButton}
                onClick={(e) => toggleFilterDrawer(e, false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={4}
            >
              <Typography className={classes.filterHeader}>Sort by:</Typography>
            </Grid>
            <Grid
              item
              xs={4}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              disableRipple
              className={classes.clearButton}
              onClick={(e) => handleSortChange(e, 'clear')}
            >
              <p className={classes.clearText}>Clear </p>
              <ClearFilterIcon color={COLORS.ABBEY} />
            </Button>
          </Grid>
        </div>
        <div className={classes.accordionContainer}>
          <Accordion
            className={classes.accordion}
            expanded={expandedFilter === 'firstName'}
            elevation={0}
            onChange={changeExpandedFilter('firstName')}
          >
            <AccordionSummary expandIcon={expandedFilter === 'firstName' ? <RemoveIcon /> : <AddIcon />}>
              <Typography className={classes.filterHeader}>First Name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="firstNameFilter"
                  name="First Name"
                  value={selectedFilters.firstNameOrder}
                  onChange={(e) => handleSortChange(e, 'firstName')}
                >
                  <FormControlLabel
                    value="asc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                      )}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                      )}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            expanded={expandedFilter === 'lastName'}
            elevation={0}
            onChange={changeExpandedFilter('lastName')}
          >
            <AccordionSummary expandIcon={expandedFilter === 'lastName' ? <RemoveIcon /> : <AddIcon />}>
              <Typography className={classes.filterHeader}>Last Name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="lastNameFilter"
                  name="Last Name"
                  value={selectedFilters.lastNameOrder}
                  onChange={(e) => handleSortChange(e, 'lastName')}
                >
                  <FormControlLabel
                    value="asc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                      )}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={(
                      <Radio classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      />
                      )}
                    label="Descending"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

        </div>
        <Button
          onClick={applyFiltering}
          className={classes.applyFilteringButton}
        >
          <Typography className={classes.applyFilteringText}>Apply Sorting</Typography>
        </Button>
      </Drawer>
    </>
  );
};

export default HcpMobileFiltering;
