import { axiosInstance } from '../utils/AxiosConfiguration';

export default function CreateNewUser(newUserForm) {
  return axiosInstance.post('CreateNewUser', {
    prefix: newUserForm.prefix,
    firstName: newUserForm.firstName,
    lastName: newUserForm.lastName,
    npi: newUserForm.npi,
    locations: newUserForm.locationIds,
    zip: newUserForm.zip,
    workPhone: newUserForm.workPhone,
    cellPhone: newUserForm.cellPhone,
    notificationStatus: newUserForm.notificationSettings,
    accountType: newUserForm.accountType,
  });
}
