import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { useTableStylesHcp } from '../styles/TableStylesHcp';
import { SortIcon } from '../utils/Icons';
import { COLORS } from '../utils/applicationConstants';
import getFormattedDate from '../utils/dateFormatter';

const useStyles = makeStyles(({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  columnHeader: {
    color: COLORS.WHITE,
    textTransform: 'none',
    height: '64px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    width: '100%',
    '&:disabled': {
      color: COLORS.WHITE,
    },
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  sortIcon: {
    padding: '0.5rem',
    paddingTop: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  filterIcon: {
    padding: '0.1rem',
    paddingTop: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  row: {
    width: '100% !important',
  },
}));

function TableSortIcon() {
  const classes = useTableStylesHcp();
  return (
    <span
      className={classes.statusIcon}
      style={{
        marginTop: '13px',
      }}
    >
      <SortIcon color={COLORS.WHITE} />
    </span>
  );
}

const HcpRepTable = (props) => {
  const classes = useTableStylesHcp();
  const styleClasses = useStyles();
  const {
    results, handlePageChangeHandler,
    queryFilters, handleQueryFilters, isTableLoading,
  } = props;
  const { totalRecords, pageSize, pageNumber } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;

  function CustNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.noRowsOverlay}>
          <Typography className={styleClasses.noRowsHeader}>
            There are currently no HCPs in your territories.
          </Typography>
          <Typography className={styleClasses.nowRowsParagraph}>
            Please check back later as more HCPs are added into the portal.
          </Typography>
        </div>
      </GridOverlay>
    );
  }

  function CustLoadingOverlay() {
    return (
      <GridOverlay>
        { /* eslint-disable-next-line object-curly-newline */}
        <div className={styleClasses.loadingOverlay}>
          <CircularProgress fontSize="large" />
        </div>
      </GridOverlay>
    );
  }

  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const handleSortChange = (sort) => {
    if (sort === 'firstName') {
      switch (queryFilters.firstNameOrder) {
        case undefined: handleQueryFilters({
          ...queryFilters,
          firstNameOrder: 'desc',
          lastNameOrder: null,
        }); break;
        case null: handleQueryFilters({
          ...queryFilters,
          firstNameOrder: 'desc',
          lastNameOrder: null,
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          firstNameOrder: 'desc',
          lastNameOrder: null,
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          firstNameOrder: 'asc',
          lastNameOrder: null,
        }); break;
        default: break;
      }
    }
    if (sort === 'lastName') {
      switch (queryFilters.lastNameOrder) {
        case undefined: handleQueryFilters({
          ...queryFilters,
          lastNameOrder: 'desc',
          firstNameOrder: null,
        }); break;
        case null: handleQueryFilters({
          ...queryFilters,
          lastNameOrder: 'desc',
          firstNameOrder: null,
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          lastNameOrder: 'desc',
          firstNameOrder: null,
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          lastNameOrder: 'asc',
          firstNameOrder: null,
        }); break;
        default: break;
      }
    }
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader} onClick={() => handleSortChange('firstName')}>
          <p> First Name </p>
          <div className={styleClasses.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader} onClick={() => handleSortChange('lastName')}>
          <p> Last Name </p>
          <div className={styleClasses.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
    },
    {
      field: 'email',
      headerName: 'E-Mail',
      sortable: false,
      width: 375,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p> E-Mail </p>
        </Button>
      ),
    },
    {
      field: 'cellPhone',
      headerName: 'Cell Phone',
      sortable: false,
      width: 125,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>Cell Phone</p>
        </Button>
      ),
    },
    {
      field: 'workPhone',
      headerName: 'Work Phone',
      sortable: false,
      width: 125,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>Work Phone</p>
        </Button>
      ),
    },
    {
      field: 'creationDate',
      headerName: 'Date Created',
      sortable: false,
      valueFormatter: getFormattedDate,
      width: 100,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p>Date Created</p>
        </Button>
      ),
    },
  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChangeHandler}
          hideFooterPagination
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          disableColumnResize
          disableExtendRowFullWidth
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            columnHeader: classes.columnHeader,
          }}
          rows={results}
          columns={columns}
          components={{
            ColumnSortedDescendingIcon: TableSortIcon,
            ColumnSortcendingIcon: TableSortIcon,
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};
export default HcpRepTable;
