/*
 * When the current status is abnormal, this helper renders the
 * message to show in the tooltip.
 * @param {*} currentRow
 * @returns String
 */
function abnormalValidator(currentRow, field) {
  if (field === 'genotype' && currentRow.testType === 'NGS') {
    return 'Abnormal:\nPlease refer to the Full Report for more details\n';
  }
  if (field === 'genotype' && currentRow.testType !== 'NGS') {
    return 'Abnormal:\nNormal Genotype is M/M \n';
  }
  // eslint-disable-next-line no-mixed-operators
  if (field === 'serumLevel' && currentRow.serumLevel < 90 || currentRow.serumLevel > 200 || currentRow.serumLevel === '<20' || currentRow.serumLevel === '>200') {
    return 'Abnormal:\nNormal range is 90 - 200 mg/dL \n';
  }
  return null;
}
function isAbnormalGenotype(currentRow) {
  if (currentRow.genotype === 'M/M' || currentRow.genotype === '--') {
    return false;
  }
  return true;
}

function isAbnormalSerumLevel(currentRow) {
  if ((currentRow.serumLevel > 90 && currentRow.serumLevel < 200) || currentRow.serumLevel === '--') {
    return false;
  }
  return true;
}
function getActionReqText(actionCode) {
  let statusCode = '';
  switch (actionCode) {
    case 'QNS': statusCode = ' because the quantity is not sufficient (QNS)';
      break;
    case 'QUALS': statusCode = ' due to stability issues with the sample (QUALS)';
      break;
    case 'QUAL': statusCode = ' due to a quality issue with the sample (QUAL)';
      break;
    case 'TNP': statusCode = '. The test was not performed (TNP)';
      break;
    case 'TNPEXP': statusCode = ' because the received testing kit is expired (TNP-EXP)';
      break;
    case 'OLD': statusCode = ' because the specimen received was out of stability';
      break;
    case 'TNP1': statusCode = ' because the specimen received had no requisition';
      break;
    case 'TNP2': statusCode = ' because the specimen received had missing patient identifiers';
      break;
    case 'TNP3': statusCode = ' due to a mismatch with the specimen barcode';
      break;
    case 'TNP4': statusCode = ' due to lack of identifier on test requisition';
      break;
    case 'TNP5': statusCode = ' because no physician information was provided';
      break;
    case 'TNP6': statusCode = ' due to lack of collection date on specimen';
      break;
    default: statusCode = '';
  }
  return `We cannot process your results at this time${statusCode}. Please submit another sample for testing.`;
}

export { abnormalValidator, getActionReqText, isAbnormalGenotype, isAbnormalSerumLevel };
