/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography,
  InputLabel,
  InputBase,
  NativeSelect,
  Button,
  useMediaQuery } from '@material-ui/core';
import UpdateAccountDetails from '../api/UpdateAccountDetails';
import GetUpdatableAccountDetails from '../api/GetUpdatableAccountDetails';
import { getUser } from '../api/AccountService';
import AccountContext from '../contexts/AccountContext';

const UpdateDetailsForm = () => {
  const { setAccount } = useContext(AccountContext);
  const med = useMediaQuery('(min-width:1060px)');
  const useStyles = makeStyles({
    updateDetailsInput: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    form: {
      width: med ? '470px' : '95%',
    },
    outerContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      width: '100%',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      width: '100%',
    },
    inputLabel: {
      paddingBottom: '0.5rem',
      paddingTop: '1rem',
      color: '#58595B',
    },
    select: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
      textDecoration: 'none',

      '&:focus': {
        backgroundColor: 'none',
      },
    },
    pageTitle: {
      fontWeight: '400',
      fontSize: '2rem',
      lineHeight: '34.5px',
      fontFamily: 'Roboto Condensed',
      marginTop: '4rem',
    },
    formHeader: {
      fontWeight: '700',
      fontSize: '22px',
      marginTop: '2rem',
      fontFamily: 'Roboto Condensed',
    },
    formText: {
      fontWeight: '300',
      fontSize: '20px',
      marginTop: '1rem',
      fontFamily: 'Roboto Condensed',
    },
    button: {
      padding: '15px 40px 15px 40px',
      backgroundColor: '#0B7AEF',
      color: 'white',
      borderRadius: '30px',
      marginTop: '1.5rem',

      '&:hover': {
        backgroundColor: '#0B7AEF',
        color: 'white',
      },

      '&:disabled': {
        backgroundColor: '#e7e7e7',
      },
    },
    errorMessage: {
      color: 'red',
      paddingTop: '1rem',
    },
    inputs: {
      marginTop: '1rem',
    },
  });

  const [showSuccessText, setShowSuccessText] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [formData, setFormData] = useState('');
  async function submitForm() {
    setIsSubmitted(true);
    try {
      const res = await UpdateAccountDetails(formData);
      if (res.status === 200) {
        const accountResponse = await getUser();
        setAccount(accountResponse);
        setShowSuccessText(true);
        setFormData('');
      }
    } catch (e) {
      setIsSubmitted(false);
    }
  }

  useEffect(async () => {
    try {
      const res = await GetUpdatableAccountDetails();
      setFormData({
        notificationSettings: res.data.notificationStatus,
        prefix: res.data.prefix,
      });
      setCurrentDetails(res.data);
    } catch (e) {
      Error(e);
    }
  }, []);

  useEffect(() => {
    if (formData !== null && formData !== '') {
      setShowSuccessText(false);
      setIsSubmitted(false);
    }
  }, [formData]);

  function handleOnChange(event) {
    const userInformationCopy = {
      ...formData,
    };
    userInformationCopy[event.target.name] = event.target.value;
    setFormData(userInformationCopy);
  }
  const test = {
    ...formData, notificationSettings: '', prefix: '',
  };
  const classes = useStyles();
  if (currentDetails !== null) {
    return (
      <form id="form" className={classes.formContainer}>
        <div className={classes.form}>
          <Typography className={classes.formHeader}>
            Update Account Information
          </Typography>
          <Typography className={classes.formText}>
            You may update your account details below.
            Should you need to update your zip code,
            please inform your regional Grifols rep to ensure
            you both understand whether there are changes to territory alignment.
          </Typography>
          <Typography className={classes.formText}>
            Should you need to reset your password, please sign out
            of the AlphaID Results Portal and click
            &quot;Forgot Password&quot; on your login screen.
          </Typography>
          <div className={classes.inputs}>
            <div className={classes.flexRow}>
              <div
                style={{
                  width: '25%',
                }}
                className={classes.inputContainer}
              >
                <InputLabel htmlFor="prefix" className={classes.inputLabel}>Prefix</InputLabel>
                <NativeSelect
                  style={{
                    width: '80%',
                  }}
                  name="prefix"
                  value={formData.prefix}
                  onChange={handleOnChange}
                  disableUnderline
                  className={classes.select}
                >
                  <option hidden value=""> </option>
                  <option value="Dr.">Dr.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </NativeSelect>
              </div>
              <div className={classes.inputContainer}>
                <InputLabel htmlFor="firstName" className={classes.inputLabel}>First name</InputLabel>
                <InputBase placeholder={currentDetails.firstName} required id="firstName" name="firstName" value={formData.firstName} onChange={handleOnChange} className={classes.updateDetailsInput} />
              </div>
            </div>

            <InputLabel htmlFor="lastName" className={classes.inputLabel}>Last name</InputLabel>
            <InputBase placeholder={currentDetails.lastName} id="lastName" name="lastName" value={formData.lastName} onChange={handleOnChange} className={classes.updateDetailsInput} />

            <InputLabel htmlFor="zip" className={classes.inputLabel}>Zip</InputLabel>
            <InputBase placeholder={currentDetails.zip} id="zip" name="zip" value={formData.zip} onChange={handleOnChange} className={classes.updateDetailsInput} />

            <InputLabel htmlFor="workPhone" className={classes.inputLabel}>Work phone</InputLabel>
            <InputBase placeholder={currentDetails.workPhone} id="workPhone" name="workPhone" value={formData.workPhone} onChange={handleOnChange} className={classes.updateDetailsInput} />

            <InputLabel htmlFor="cellPhone" className={classes.inputLabel}>Cell phone</InputLabel>
            <InputBase placeholder={currentDetails.cellPhone} id="cellPhone" name="cellPhone" value={formData.cellPhone} onChange={handleOnChange} className={classes.updateDetailsInput} />

            <Typography className={classes.formText}>
              Would you like to start receiving email notifications about your AlphaID
              test results when the notification feature becomes available?
            </Typography>
            <InputLabel htmlFor="notificationSettings" className={classes.inputLabel}>Notification settings</InputLabel>
            <NativeSelect
              name="notificationSettings"
              value={formData.notificationSettings}
              onChange={handleOnChange}
              disableUnderline
              className={classes.select}
            >
              <option hidden value=""> </option>
              { /* eslint-disable-next-line react/jsx-boolean-value */}
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </NativeSelect>
            { /* eslint-disable-next-line max-len */ }
            <Button disabled={(!Object.values(test).some((field) => field !== undefined && field !== '') && (formData ? formData.notificationSettings.toString() : null) == currentDetails.notificationStatus.toString() && formData.prefix == currentDetails.prefix) || isSubmitted} onClick={submitForm} className={classes.button}>Update Information</Button>
            {showSuccessText && (
              <Typography className={classes.formText}>
                Your acount information was successfully updated!
              </Typography>
            )}
          </div>
        </div>
      </form>
    );
  }

  return (
    <>
    </>
  );
};

export default UpdateDetailsForm;
