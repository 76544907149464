import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { COLORS } from '../utils/applicationConstants';
import HcpMobileFiltering from './HcpMobileFiltering';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    overflowX: 'visible',
  },
  row: {
    background: COLORS.MERCURY,
    borderRadius: '15px',
    border: 'none',
    height: '184px',
    padding: '0 24px',
  },
  thRoot: {
    borderRadius: '15px',
  },
  priorityPaperRoot: {
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  tableRoot: {
    borderCollapse: 'inherit !important',
    borderSpacing: '2px',
  },
  p: {
    display: 'inline-block',
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  mobileButtonGridRootContainer: {
    marginBottom: '24px',
  },
  buttonGroup: {
    color: COLORS.ABBEY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '24px',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
  removeButton: {
    color: 'red',
    fontFamily: 'Roboto',
  },
}));

const HcpTableMobile = (props) => {
  const classes = useStyles();
  const {
    results,
    isTableLoading,
    handleViewHandler,
    totalRecords,
    pageSize,
    handleQueryFilters,
    queryFilters,
    isManager,
    isLabManager,
    deleteModalHandler,
  } = props;
  const [mobileFilterDrawerOpen, setMobileFilterDrawerOpen] = useState(null);
  const toggleFilterDrawer = (event, open) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileFilterDrawerOpen(open);
  };
  if (isTableLoading) {
    return <CircularProgress />;
  }
  function renderRemoveField(item) {
    if (isLabManager) {
      return (
        <Grid item xs>
          <Paper className={classes.paper}>
            <p className={classes.pAsLabel}>
              Remove:
              {' '}
              <Button className={classes.removeButton} onClick={() => deleteModalHandler(item)}>
                Remove
              </Button>
            </p>
          </Paper>
        </Grid>
      );
    }
    return null;
  }
  function renderManagerHcpMobileTable() {
    return (
      <TableContainer className={classes.root} component={Paper}>
        <Table
          classes={{
            root: classes.tableRoot,
          }}
          aria-label="simple table"
        >
          <TableBody>
            {(pageSize > 0
              ? results.slice(0, pageSize)
              : results
            ).map((item) => (
              <TableRow
                key={item.id}
                classes={{
                  root: classes.row,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  classes={{
                    root: classes.thRoot,
                  }}
                >
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Name:
                          {' '}
                          {item.lastName}
                          ,
                          {' '}
                          {item.firstName}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          NPI:
                          {' '}
                          {item.npi}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          E-Mail:
                          {' '}
                          {item.email}
                        </p>
                      </Paper>
                    </Grid>
                    {renderRemoveField(item)}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderRepMobileTable() {
    return (
      <TableContainer className={classes.root} component={Paper}>
        <Table
          classes={{
            root: classes.tableRoot,
          }}
          aria-label="simple table"
        >
          <TableBody>
            {(pageSize > 0
              ? results.slice(0, pageSize)
              : results
            ).map((item) => (
              <TableRow
                key={item.id}
                classes={{
                  root: classes.row,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  classes={{
                    root: classes.thRoot,
                  }}
                >
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Name:
                          {' '}
                          {item.lastName}
                          ,
                          {' '}
                          {item.firstName}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Cell Phone:
                          {' '}
                          {item.cellPhone}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    <Grid item xs>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          E-Mail:
                          {' '}
                          {item.email}
                        </p>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                        <p className={classes.pAsLabel}>
                          Work Phone:
                          {' '}
                          {item.workPhone}
                        </p>
                      </Paper>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <>
      {!isManager ? (
        <Grid
          container
          classes={{
            root: classes.mobileButtonGridRootContainer,
          }}
        >
          <Grid
            item
            xs={9}
          >
            <Button
              className={classes.drawerToggleButtons}
              onClick={(e) => toggleFilterDrawer(e, true)}
            >
              Sort
              <KeyboardArrowDownIcon />
            </Button>
          </Grid>
        </Grid>
      ) : null}

      <div className={classes.root}>
        {!isManager ? (
          <div className={classes.buttonGroup}>
            <div>
              <span>
                {' '}
                {totalRecords}
                {' '}
                results
                {' '}
              </span>
            </div>
            <div>
              <span className={classes.viewText}>View:</span>
              <button
                type="button"
                className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(15)}
              >
                15
              </button>
              <button
                type="button"
                className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(30)}
              >
                30
              </button>
              <button
                type="button"
                className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewHandler(45)}
              >
                45
              </button>
            </div>
          </div>
        ) : null}

        {isManager ? renderManagerHcpMobileTable() : renderRepMobileTable()}
      </div>
      {!isManager ? (
        <HcpMobileFiltering
          mobileFilterDrawerOpen={mobileFilterDrawerOpen}
          toggleFilterDrawer={toggleFilterDrawer}
          handleQueryFilters={handleQueryFilters}
          queryFilters={queryFilters}
        />
      ) : null}

    </>
  );
};
export default HcpTableMobile;
