import { ACCOUNT_TYPES } from './applicationConstants';

const HCP_NAVIGATION = [{
  name: 'Dashboard',
  link: '/dashboard',
}, {
  name: 'Insights',
  link: '/insights',
},
{
  name: 'Learning Center',
  link: '/learning-center',
}, {
  name: 'My Account',
  link: '/my-account',
},
];

const ACCOUNT_MANAGER_NAVIGATION = [{
  name: 'Dashboard',
  link: '/dashboard',
}, {
  name: 'Insights',
  link: '/insights',
},
{
  name: 'Learning Center',
  link: '/learning-center',
}, {
  name: 'My Account',
  link: '/my-account',
},
{
  name: 'My Practice',
  link: '/my-practice',
},
];
const REP_NAVIGATION = [{
  name: 'Dashboard',
  link: '/dashboard',
}, {
  name: 'Insights',
  link: '/insights',
},
{
  name: 'Learning Center',
  link: '/learning-center',
},
{
  name: 'My HCPs',
  link: '/my-hcps',
},
];
const ADMIN_NAVIGATION = [
  {
    name: 'Admin Home',
    link: '/admin-home',
  },
  {
    name: 'Account Requests',
    link: '/account-requests',
  },
  {
    name: 'Practices',
    link: '/practices',
  },
];
const APPROVER_NAVIGATION = [
  {
    name: 'Account Requests',
    link: '/account-requests',
  },
  {
    name: 'Practices',
    link: '/practices',
  },
];

function getMainNavigation(account) {
  switch (account) {
    case ACCOUNT_TYPES.HCP:
      return HCP_NAVIGATION;
    case ACCOUNT_TYPES.AccountManager:
      return ACCOUNT_MANAGER_NAVIGATION;
    case ACCOUNT_TYPES.GrifolsRep:
      return REP_NAVIGATION;
    case ACCOUNT_TYPES.GrifolsAdmin:
      return ADMIN_NAVIGATION;
    case ACCOUNT_TYPES.Approver:
      return APPROVER_NAVIGATION;
    default:
      return [];
  }
}

export { getMainNavigation };
