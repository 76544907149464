import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Portal from '../pages/Portal';
import Success from '../pages/Success';
import AdditionalDetails from '../pages/AdditionalDetails';
import AdditionalDetailsSuccess from '../pages/AdditionalDetailsSuccess';
import AdminHome from '../pages/AdminHome';
import PrivateRoute from './PrivateRoute';
import Layout from '../layouts/Layout';
import LearningCenter from '../pages/LearningCenter';
import MyAccount from '../pages/MyAccount';
import RepDashboard from '../pages/RepDashboard';
import Practices from '../pages/Practices';
import ContactUs from '../pages/ContactUs';
import LabMyAccounts from '../pages/AccountRequests';
import AccountDenied from '../pages/AccountDenied';
import Insights from '../pages/Insights';
import MyPractice from '../pages/MyPractice';
import HcpRepView from '../pages/HcpRepView';
import SelectedPractice from '../pages/SelectedPractice';

const AppRouter = () => (
  <Layout>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/repdashboard">
        <RepDashboard />
      </Route>
      <Route exact path="/my-account">
        <MyAccount />
      </Route>
      <Route exact path="/account-requests">
        <LabMyAccounts />
      </Route>
      <Route exact path="/details">
        <AdditionalDetails />
      </Route>
      <Route exact path="/details/success">
        <AdditionalDetailsSuccess />
      </Route>
      <Route exact path="/denied">
        <AccountDenied />
      </Route>
      <Route exact path="/contact-us">
        <ContactUs />
      </Route>
      <PrivateRoute exact path="/insights">
        <Insights />
      </PrivateRoute>
      <PrivateRoute exact path="/portal">
        <Portal />
      </PrivateRoute>
      <PrivateRoute exact path="/success">
        <Success />
      </PrivateRoute>
      <PrivateRoute exact path="/insights">
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path="/learning-center">
        <LearningCenter />
      </PrivateRoute>
      <PrivateRoute exact path="/admin-home">
        <AdminHome />
      </PrivateRoute>
      <PrivateRoute exact path="/my-hcps">
        <HcpRepView />
      </PrivateRoute>
      <PrivateRoute exact path="/my-practice">
        <MyPractice />
      </PrivateRoute>
      <PrivateRoute exact path="/practices">
        <Practices />
      </PrivateRoute>
      <PrivateRoute exact path="/practices/:practiceId">
        <SelectedPractice />
      </PrivateRoute>
    </Switch>
  </Layout>
);

export default AppRouter;
