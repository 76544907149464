import React, { useState } from 'react';
import { InputLabel,
  InputBase,
  makeStyles,
  Modal,
  Paper } from '@material-ui/core';
import { COLORS } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';
import { getNpiInformation, addHcpToPractice, getPractice, addNewManagedAccountToPractice } from '../api/PracticesService';
import CreateManagedAccountForm from './CreateManagedAccountForm';
import { isValidNpi } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '100%',
  },
  inputLabel: {
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    color: '#58595B',
  },
  inputStyle: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
  error: {
    marginTop: '6px',
    color: 'red',
  },
}));

const AddUserToPracticeModal = (props) => {
  const classes = useStyles();
  const {
    modalOpen, setModalOpen,
    practiceId, setNewPracticeResults, accountIdsInPractice,
  } = props;
  const [npi, setNpi] = useState('');
  const [error, setError] = useState(null);
  const [npiExists, setNpiExists] = useState(null);
  const [npiInPractice, setNpiInPractice] = useState(false);
  const [addToPracticeClicked, setAddToPracticeClicked] = useState(false);
  const [hcpName, setHcpName] = useState(null);
  const [hcpId, setHcpId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  async function searchForNpi() {
    if (!isValidNpi(npi)) {
      setError('NPI must be 10 digits');
      return;
    }
    setError(null);
    const npiResponse = await getNpiInformation(npi);
    setNpiExists(npiResponse?.hcpExists);
    setHcpName(npiResponse?.hcpName);
    setHcpId(npiResponse?.accountId);
    if (npiResponse?.accountId && !!accountIdsInPractice.length) {
      setNpiInPractice(accountIdsInPractice.includes(npiResponse.accountId));
    }
  }

  async function addUserToPractice() {
    setIsSaving(true);
    const response = await addHcpToPractice(npi, hcpId, practiceId);
    if (response.status === 200) {
      const practiceResponse = await getPractice(practiceId);
      setNewPracticeResults(practiceResponse);
      setModalOpen(false);
    } else {
      setError(response.data);
      setAddToPracticeClicked(false);
    }
  }

  function handleModalClose() {
    setNpi('');
    setError(null);
    setNpiExists(null);
    setAddToPracticeClicked(false);
    setHcpName(null);
    setModalOpen(false);
  }

  async function submitManagedAccountCreation(formData) {
    setIsSaving(true);
    // eslint-disable-next-line max-len
    const response = await addNewManagedAccountToPractice(practiceId, npi, formData.firstName, formData.lastName, formData.zip);
    if (response.status === 200) {
      const practiceResponse = await getPractice(practiceId);
      setNewPracticeResults(practiceResponse);
      handleModalClose();
    } else {
      setError(response.data);
      setAddToPracticeClicked(false);
    }
  }

  function npiChange(evt) {
    // If we've already searched by NPI and are creating a managed account,
    // and the user starts changing the NPI, then we should reset the form
    if (!(npiExists === null || npiExists === undefined) && evt.target.value.trim() !== npi) {
      setNpiExists(null);
    }
    setNpi(evt.target.value);
  }

  function renderNpiEnteredFlow() {
    if (error) {
      return (
        <div className={classes.error}>
          {error}
        </div>
      );
    }
    if (npiExists === null || npiExists === undefined) {
      // No information to show since the user hasn't searched for an NPI yet
      return <> </>;
    }
    if (npiExists === false) {
      return (
        <>
          <p>
            No Active Portal Account with this NPI.
            You may enter information below to create a non-login, Managed Account for this HCP.
          </p>
          <CreateManagedAccountForm
            onSubmit={submitManagedAccountCreation}
            disableSubmit={isSaving}
          />
        </>
      );
    }
    if (npiExists === true && npiInPractice) {
      return (
        <p>
          <b>{hcpName}</b>
          {' '}
          is already a member of this practice
        </p>
      );
    }
    if (npiExists === true) {
      return (
        <>
          <p>
            You are about to add
            {' '}
            <b>{hcpName}</b>
            {' '}
            an existing HCP account to this practice. By doing so,
            their Account Manager will be able to view all of their AlphaID screening
            results on their behalf. If you do not add them to this practice, this HCP
            will still be able to view their own results, but their results will not
            be visible to the Account Manager of this Practice. Do you wish to
            proceed with adding this HCP to this Practice?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={addUserToPractice}
              disabled={isSaving}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleModalClose}
              disabled={isSaving}
            >
              no
            </ButtonComponent>
          </div>
        </>
      );
    }
    return null;
  }

  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <div className={classes.inputContainer}>
          <InputLabel htmlFor="npi" className={classes.inputLabel}>Enter an NPI Number</InputLabel>
          <InputBase
            name="npi"
            value={npi}
            disabled={addToPracticeClicked}
            error={!!error}
            onChange={npiChange}
            className={classes.inputStyle}
          />
        </div>
        <ButtonComponent
          onClick={searchForNpi}
        >
          Find HCP
        </ButtonComponent>
        {renderNpiEnteredFlow()}
      </Paper>
    </Modal>
  );
};

export default AddUserToPracticeModal;
