import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import Logo from '../images/logo.svg';
import { loginRequest } from '../utils/authConfig';

const useStyles = makeStyles(({
  homePageRoot: {
    height: 'calc(100vh - 150px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const Home = () => {
  const classes = useStyles();
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect(loginRequest);
  }, []);
  return (
    <div className={classes.homePageRoot}>
      <img src={Logo} alt="Main AlphaId Logo" />
    </div>
  );
};
export default Home;
