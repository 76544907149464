import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { abnormalValidator } from '../../utils/statusHelpers';
import { WarningIcon } from '../../utils/Icons';
import { COLORS } from '../../utils/applicationConstants';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
}));
const AbnormalStatusRenderer = ({ userRow, field }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip
          content={abnormalValidator(userRow, field)}
          icon="warning"
        >
          <WarningIcon color={COLORS.AZURE} />
        </Tooltip>
      </div>
    </>
  );
};

export default AbnormalStatusRenderer;
