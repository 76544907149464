import React, { useEffect, useState } from 'react';
import { makeStyles,
  Modal,
  Grid,
  Paper } from '@material-ui/core';
import { COLORS } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';
import { getPracticeRecommendedLocations, updateAccountMgrLocations } from '../api/PracticesService';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.CARDINAL,
    fontSize: '25px',
  },
  buttonSize: {
    width: '179px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '60px',
    paddingBottom: '70px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  modal: {
    overflow: 'scroll',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '100%',
  },
  inputLabel: {
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    color: '#58595B',
  },
  inputStyle: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
  error: {
    marginTop: '6px',
    color: 'red',
  },
  pAsLabel: {
    margin: 10,
    color: COLORS.BLACK,
    display: 'flex',
    alignItems: 'center',
    height: '10px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  thRoot: {
    borderRadius: '15px',
  },
}));

const RecommendedLocationsPopupModal = (props) => {
  const classes = useStyles();
  const {
    autoOpenOnHcpMngrDashboard, openModalOnClick, setOpenModalOnClick, setPopUpLocationAccepted,
  } = props;
  const [recommendedLocations, setRecommendedLocations] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [addLocationSelected, setAddLocationSelected] = useState(false);
  const [notMineSelected, setNotMineSelected] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentLocationShown, setCurrentLocationShown] = useState(null);
  const [currentLocationShownIndex, setCurrentLocationShownIndex] = useState(0);
  const [recommendedLocationsLength, setRecommendedLocationsLength] = useState(0);
  const [isAcceptedError, setIsAcceptedError] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [acceptedError, setAcceptedError] = useState(null);
  const [declinedError, setDeclinedError] = useState(null);
  const [nextLocationButton, setNextLocationButton] = useState(true);
  const [disableConfirmationButton, setDisableConfirmationButton] = useState(false);

  const badRequestErrorMessage = 'There was an error processing this request, please click out of popup and try again later';

  function handleModalClose() {
    setRecommendedLocations(null);
    setModalOpen(false);
    // This will only be defined when accessing from AddLocation Modal
    if (setOpenModalOnClick !== undefined) {
      setOpenModalOnClick(false);
    }
    setAddLocationSelected(false);
    setIsAcceptedError(false);
    setIsDeclinedError(false);
    setNotMineSelected(false);
    setDisableConfirmationButton(false);
  }

  async function getRecommendedLocations() {
    return getPracticeRecommendedLocations();
  }

  function handleGetRecommendedLocationsSuccessResponse(recommendedLocationsResponse) {
    setRecommendedLocations(recommendedLocationsResponse.data);
    setRecommendedLocationsLength(recommendedLocationsResponse.data.length);
    setCurrentLocationShown(recommendedLocationsResponse.data[0]);
    setCurrentLocationShownIndex(0);
    setModalOpen(true);
  }

  useEffect(async () => {
    if (autoOpenOnHcpMngrDashboard) {
      if (window.sessionStorage.getItem('showRecommendedLocationPrompt') === 'false') {
        return;
      }
      const recommendedLocationsResponse = await getRecommendedLocations();
      if (recommendedLocationsResponse.data.length !== 0
        && recommendedLocationsResponse.status === 200) {
        handleGetRecommendedLocationsSuccessResponse(recommendedLocationsResponse);
      }
      window.sessionStorage.setItem('showRecommendedLocationPrompt', 'false');
    }
  }, []);

  useEffect(async () => {
    if (openModalOnClick) {
      const recommendedLocationsResponse = await getRecommendedLocations();
      if (recommendedLocationsResponse.data.length !== 0
        && recommendedLocationsResponse.status === 200) {
        handleGetRecommendedLocationsSuccessResponse(recommendedLocationsResponse);
      } else {
        setModalOpen(true);
      }
    }
  }, [openModalOnClick]);

  function handleConfirmationClose() {
    setAddLocationSelected(false);
    setNotMineSelected(false);
    setSelectedLocation(null);
    setNextLocationButton(true);
    setDisableConfirmationButton(false);
  }

  async function addConfirmedLocation(location) {
    setDisableConfirmationButton(true);
    const acceptedResponse = await updateAccountMgrLocations(
      true, location.isUnifiedId, location.uiLocationId, location.practiceId,
    );
    if (acceptedResponse.status !== 200) {
      setIsAcceptedError(true);
      setAcceptedError(badRequestErrorMessage);
      return;
    }
    if (typeof setPopUpLocationAccepted === 'function') {
      setPopUpLocationAccepted(location);
    }
    const recommendedLocationsResponse = await getRecommendedLocations();
    if (recommendedLocationsResponse.data.length !== 0
      && recommendedLocationsResponse.status === 200) {
      handleGetRecommendedLocationsSuccessResponse(recommendedLocationsResponse);
    } else {
      setRecommendedLocationsLength(0);
      setRecommendedLocations(null);
    }
    handleConfirmationClose();
  }

  async function handleAddLocationClicked(location) {
    setSelectedLocation(location);
    setAddLocationSelected(true);
    setNextLocationButton(false);
  }

  async function handleNotMineClicked(location) {
    setSelectedLocation(location);
    setNotMineSelected(true);
    setNextLocationButton(false);
  }

  async function sendDeclinedUpdatedLocationResponse(location) {
    setDisableConfirmationButton(true);
    const declinedResponse = await updateAccountMgrLocations(
      false, location.isUnifiedId, location.uiLocationId, location.practiceId,
    );
    if (declinedResponse.status !== 200) {
      setIsDeclinedError(true);
      setDeclinedError(badRequestErrorMessage);
      return;
    }
    const recommendedLocationsResponse = await getRecommendedLocations();
    if (recommendedLocationsResponse.data.length !== 0
      && recommendedLocationsResponse.status === 200) {
      handleGetRecommendedLocationsSuccessResponse(recommendedLocationsResponse);
    } else {
      setRecommendedLocationsLength(0);
      setRecommendedLocations(null);
    }
    handleConfirmationClose();
  }

  function handleNextLocationClick() {
    if (currentLocationShownIndex < (recommendedLocationsLength - 1)) {
      const nextLocationToShowIndex = currentLocationShownIndex + 1;
      setCurrentLocationShownIndex(nextLocationToShowIndex);
      setCurrentLocationShown(recommendedLocations[nextLocationToShowIndex]);
    } else {
      setCurrentLocationShownIndex(0);
      setCurrentLocationShown(recommendedLocations[0]);
    }
  }

  function locationSelectionConfirmation() {
    if (addLocationSelected) {
      return (
        <>
          <p>
            You are about to add this new location
            to your account:
          </p>
          <b>
            {selectedLocation.address1}
          </b>
          <p>
            Do you wish to proceed with adding this location to your account?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={() => addConfirmedLocation(selectedLocation)}
              disabled={disableConfirmationButton}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleConfirmationClose}
              disabled={disableConfirmationButton}
            >
              no
            </ButtonComponent>
            {isAcceptedError && <p className={classes.error}>{acceptedError}</p>}
          </div>
        </>
      );
    }
    return null;
  }

  function locationDeclinednConfirmation() {
    if (notMineSelected) {
      return (
        <>
          <p>
            You have selected that this location is not
            a part of your account:
          </p>
          <b>
            {selectedLocation.address1}
          </b>
          <p>
            Do you still wish to proceed? Note: You will still be able
            to add this location in the My Practice tab
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={() => sendDeclinedUpdatedLocationResponse(selectedLocation)}
              disabled={disableConfirmationButton}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleConfirmationClose}
              disabled={disableConfirmationButton}
            >
              no
            </ButtonComponent>
            {isAcceptedError && <p className={classes.error}>{acceptedError}</p>}
          </div>
        </>
      );
    }
    return null;
  }

  if (!modalOpen) {
    return <></>;
  }

  return (
    <Modal
      className={classes.modal}
      open={modalOpen}
      onClose={handleModalClose}
    >
      <Paper className={classes.modalBackground}>
        <span className={classes.title}>
          We&apos;ve noticed some potential locations to add to your portal account
        </span>
        { recommendedLocationsLength !== 0
          ? (
            <>
              <p>
                Based on NPI&apos;s associated with
                your account, additional addresses may need to be
                added to display results from these locations. If you
                are managing results for a location that is listed here,
                please click &quot;add to my account&quot;.
                {' '}
              </p>
              <p>
                <b>
                  Please note: We recommend adding only the locations
                  where you manage the test results.
                </b>
                {' '}
                If other portal account users are managing test results for
                these locations within your account, please do not add them to
                your portal account since access can only be given to one
                account manager at a time.
              </p>
              <p>
                Recommended Location (
                {currentLocationShownIndex + 1}
                /
                {recommendedLocationsLength}
                )
              </p>
              <div>
                <Grid container spacing={7}>
                  <Grid item xs={5}>
                    <Paper className={classes.paper}>
                      <h2 className={classes.pAsLabel}>
                        HCP Name:
                        {' '}
                        {currentLocationShown.hcpName}
                      </h2>
                    </Paper>
                  </Grid>
                  <Grid item xs={7}>
                    <Paper className={classes.paper}>
                      <h2 className={classes.pAsLabel}>
                        Unconfirmed Address:
                        {' '}
                        {currentLocationShown.address1}
                      </h2>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <ButtonComponent
                      onClick={() => handleAddLocationClicked(currentLocationShown)}
                      disabled={addLocationSelected || notMineSelected}
                    >
                      Add to my account
                    </ButtonComponent>
                  </Grid>
                  <Grid item xs>
                    <ButtonComponent
                      onClick={() => handleNotMineClicked(currentLocationShown)}
                      disabled={notMineSelected || addLocationSelected}
                    >
                      Not Mine
                    </ButtonComponent>
                  </Grid>
                </Grid>
                {isDeclinedError && <p className={classes.error}>{declinedError}</p>}
              </div>
              { recommendedLocationsLength > 1 && nextLocationButton
                ? (
                  <ButtonComponent
                    onClick={() => handleNextLocationClick()}
                    type="secondary"
                    className={classes.secondaryButtonAsLink}
                    style={{ float: 'right' }}
                  >
                    {' '}
                    Next Location
                  </ButtonComponent>
                ) : '' }
              {addLocationSelected && locationSelectionConfirmation()}
              {notMineSelected && locationDeclinednConfirmation()}
            </>
          )
          : (
            <p>
              <b>
                There are no more recommended locations at this time. Please check again later.
              </b>
            </p>
          )}
      </Paper>
    </Modal>

  );
};

export default RecommendedLocationsPopupModal;
