import { axiosInstance } from '../utils/AxiosConfiguration';

export default function getResults(pageNumber, pageSize, queryFilters, accountType) {
  return axiosInstance.post('/Results', {
    accountType,
    paginationFilter: {
      pageNumber,
      pageSize,
    },
    queryFilters,
  });
}
