import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../utils/applicationConstants';

const useTableStyles = makeStyles({
  root: {
    border: 'none',
    width: 'inherit',
    '& .MuiDataGrid-main': {
      width: 'inherit !important',
      overflow: 'visible',
    },
    '& .MuiDataGrid-windowContainer': {
      height: '96%',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderRadius: '15px',
      background: COLORS.AZURE,
      position: 'absolute',
      color: COLORS.WHITE,
      fontSize: '13px',
    },
    '& .MuiDataGrid-viewport': {
      overflow: 'unset',
    },
    '& MuiDataGrid-iconButtonContainer ': {
      display: 'none',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
      color: COLORS.WHITE,
      opacity: '1',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '9px',
      minHeight: '54px',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
      overflow: 'visible',
      '&:focus': {
        outline: 'none',
      },
    },
    '& .status-col': {
      borderRight: 'none',
      borderRadius: '15px',
    },
    '& .MuiDataGrid-menuIcon': {
      display: 'none',
    },
    '& .desktop-status': {
      display: 'inline-block',
    },
    '& .status-icon': {
      marginTop: '16px',
      marginLeft: '6px',
    },
    '& .MuiDataGrid-renderingZone': {
      marginTop: '3px',
      '&:focus': {
        background: '#F4F4F4 !important',
      },
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      width: '100%',
    },
    '& .status-header': {
      borderRight: 'none',
      minWidth: '176px !important',
    },
    '&  .MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
      display: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      background: COLORS.MERCURY,
      fontWeight: '700',
      color: COLORS.BLACK,
      '&:hover': {
        background: COLORS.MERCURY,
      },
    },
    '& .priority-row': {
      background: COLORS.MERCURY,
    },
  },
  cell: {
    border: 'none',
    borderRight: `2px solid ${COLORS.MISCHKA}`,
    fontSize: '13px',
    position: 'relative',
  },
  row: {
    background: COLORS.WILD_SAND,
    borderRadius: '15px',
    color: COLORS.ABBEY,
    marginTop: '2px',
    height: '56px',
    width: '100%',
  },
  columnHeader: {
    borderRight: `2px solid ${COLORS.WHITE}`,
    padding: '0px !important',
  },
  iconWrapper: {
    marginTop: '-30px',
    marginLeft: '6px',
  },
  viewText: {
    marginLeft: '15px',
  },
  abnormalIconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  headerButton: {
    color: COLORS.WHITE,
    textTransform: 'none',
    fontSize: '13px',
    fontFamily: 'Roboto',
    '&:disabled': {
      color: COLORS.WHITE,
    },
  },
  headerFilterOn: {
    background: COLORS.NAVY,
  },
  headerFilterOff: {
    background: COLORS.AZURE,
  },
  sortIcon: {
    padding: '0.5rem',
    paddingTop: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  filterIcon: {
    padding: '0.1rem',
    paddingTop: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  radio: {
    '&$checked': {
      color: '#0B7AEF',
    },
  },
  checked: {
  },
  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  clearButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    color: COLORS.ABBEY,
    margin: 0,
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'inherit',
    },
  },
});

export { useTableStyles };
