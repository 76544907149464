import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AcctDetailsSide from '../images/AcctDetailsSide.svg';

const useStyles = makeStyles({
  container: {
    width: '30%',
    display: 'flex',
    paddingLeft: '7rem',
    flexDirection: 'column',
    paddingTop: '3rem',
  },
  mobileContainer: {
    paddingLeft: '1rem',
    paddingTop: '7rem',
    width: '90%',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
  },
  sidePanelImg: {
    backgroundColor: '#E2EFFD',
    height: 'form.offsetHeight',
  },
  header: {
    fontWeight: 400,
    fontSize: '32px',
    fontFamily: 'Roboto Condensed',
  },
  subheader: {
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: 'Roboto Condensed',
  },
  paragraph: {
    marginTop: '1rem',
  },
});

const AdditionalDetailsSuccess = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:768px)');

  if (isDesktop) {
    return (
      <>
        <div className={classes.flexRow}>
          <div className={classes.sidePanelImg}>
            <img alt="" src={AcctDetailsSide} />
          </div>
          <div className={classes.container}>
            <div>
              <Typography className={classes.header}>AlphaID™ </Typography>
            </div>

            <Typography className={classes.header}>Results Portal</Typography>
            <Typography className={classes.subheader}>Thank you for registering!</Typography>
            <Typography className={classes.paragraph}>
              Sit tight; your request for a new portal account has been sent to a Grifols Admin
              for their review, and they will approve your request within 48 hours.
            </Typography>
            <Typography className={classes.paragraph}>
              Please log back in within 48 hours to review
              the status of your request.
              Once in, you will be able to see the status of all the tests you have
              submitted and their final results, as well as overall insights about
              your patient population.
            </Typography>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.mobileContainer}>
        <div>
          <Typography className={classes.header}>AlphaID™ </Typography>
        </div>

        <Typography className={classes.header}>Results Portal</Typography>
        <Typography className={classes.subheader}>Thank you for registering!</Typography>
        <Typography className={classes.paragraph}>
          Sit tight; your request for a new portal account has been sent to a Grifols Admin
          for their review, and they will approve your request within 48 hours.
        </Typography>
        <Typography className={classes.paragraph}>
          Please log back in within 48 hours to review
          the status of your request.
          Once in, you will be able to see the status of all the tests you have
          submitted and their final results, as well as overall insights about
          your patient population.
        </Typography>
      </div>
    </>
  );
};

export default AdditionalDetailsSuccess;
