import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles(({
  box: {
    backgroundColor: COLORS.ATHENS_GREY,
    '&:hover': {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
}));

const ClipboardCopy = (props) => {
  const classes = useStyles();
  const { textToCopy } = props;
  const [copiedText, setCopiedText] = useState();
  return (
    <>
      <CopyToClipboard
        text={textToCopy}
        onCopy={() => setCopiedText('textIsCopied')}
      >
        <Tooltip
          title={
            copiedText === 'textIsCopied'
              ? <h2>This was Copied!</h2>
              : <h2>CLICK TO COPY!</h2>
          }
          placement="bottom"
          arrow
        >
          <Box
            className={classes.box}
            component="button"
            fontFamily="inherit"
            fontSize="18px"
            fontWeight="400"
            lineHeight="1.25"
            width="100%"
            padding="24px"
            color={COLORS.BLACK}
            textAlign="left"
            border="0"
            type="button"
            display="flex"
            justifyContent="center"
            m={2}
          >
            <div>
              <span>{textToCopy}</span>
            </div>
          </Box>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

export default ClipboardCopy;
