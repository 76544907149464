import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../utils/applicationConstants';

export const useMobileTableStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    overflowX: 'visible',
  },
  cellContainer: {
    marginBottom: '5px',
    background: COLORS.MERCURY,
    borderRadius: '15px',
  },
  row: {
    borderRadius: '15px',
    border: 'none',
    height: '184px',
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    },
  },
  priorityPaperRoot: {
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  tableRoot: {
  },
  p: {
    display: 'inline-block',
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '33px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    marginRight: '0.2rem',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  buttonGroup: {
    color: COLORS.ABBEY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'space-between',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  resultsGroup: {
    display: 'flex',
    flexFlow: 'column',
  },
  clearButton: {
    minWidth: '120px',
    paddingLeft: '32px',
    justifyContent: 'flex-end',
    color: COLORS.ABBEY,
    '& p': {
      margin: '11px 0',
    },
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'inherit',
    },
  },
}));
