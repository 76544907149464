import React from 'react';
import { makeStyles, Modal, Paper } from '@material-ui/core';
import { COLORS } from '../../utils/applicationConstants';
import ButtonComponent from './ButtonComponent';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
}));

const InterstitialPrompt = (props) => {
  const classes = useStyles();
  const { title, copy, siteUrl, modalOpen, setModalOpen } = props;
  return (
    <Modal
      onClose={() => setModalOpen(false)}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <span className={classes.title}>{title}</span>
        <p>
          {copy}
        </p>
        <div className={classes.buttonContainer}>
          <ButtonComponent
            href={siteUrl}
            className={classes.buttonSize}
          >
            Ok
          </ButtonComponent>
          <ButtonComponent
            type="secondary"
            className={classes.secondaryButtonAsLink}
            onClick={() => setModalOpen(false)}
          >
            Close
          </ButtonComponent>
        </div>
      </Paper>
    </Modal>
  );
};

export default InterstitialPrompt;
