import React from 'react';
import { COLORS } from '../../utils/applicationConstants';

const RadioIcon = (props) => {
  const { type, isChecked } = props;

  function getIconColorFromType() {
    if (type === 'actionRequired' || type === 'grifolsRep') {
      return COLORS.CARDINAL;
    }
    if (type === 'priority' || type === 'acctManager') {
      return COLORS.ORANGE;
    }
    if (type === 'abnormal' || type === 'hcp') {
      return COLORS.AZURE_RAD;
    }
    return COLORS.SAZERAC;
  }

  function renderUnChecked() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="white" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
          fill={getIconColorFromType()}
        />
      </svg>
    );
  }

  function renderChecked() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="white" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
          fill={getIconColorFromType()}
        />
        <g clipPath="url(#clip0)">
          <path
            d="M10.5888 16.4722C10.3152 16.4722 10.0464 16.3666 9.84004 16.1602L6.31204 12.6322C5.89924 12.2194 5.89924 11.5474 6.31204 11.1346C6.72484 10.7218 7.39684 10.7218 7.80964 11.1346L10.5888 13.9138L16.1904 8.31219C16.6032 7.89939 17.2752 7.89939 17.688 8.31219C18.1008 8.72499 18.1008 9.39699 17.688 9.80979L11.3376 16.1602C11.1312 16.3666 10.8576 16.4722 10.5888 16.4722Z"
            fill={getIconColorFromType()}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="12" height="8.472" fill="white" transform="translate(6 8)" />
          </clipPath>
        </defs>
      </svg>

    );
  }

  function renderIcon() {
    if (isChecked) {
      return renderChecked();
    }
    return renderUnChecked();
  }

  return renderIcon();
};

export default RadioIcon;
