import React from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink } from 'react-router-dom';
import { loginRequest } from '../utils/authConfig';

const Portal = () => {
  const { instance } = useMsal();
  function handleLogout() {
    instance.logoutRedirect(loginRequest);
  }

  return (
    <>
      <h1>Portal</h1>
      <button type="button" onClick={handleLogout}>Sign Out</button>
      <NavLink to="/details">Click here</NavLink>
      <div />
      <NavLink to="/dashboard?actionRequired=true&abnormal=true">dashboard</NavLink>
    </>
  );
};

export default Portal;
