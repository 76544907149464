import React from 'react';
import { makeStyles,
  Modal,
  Paper } from '@material-ui/core';
import { COLORS } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
}));

const RemoveUserFromPracticeModal = (props) => {
  const classes = useStyles();
  const {
    modalOpen,
    setModalOpen,
    deleteHandler,
    hcpRow,
  } = props;

  function handleModalClose() {
    setModalOpen(false);
  }
  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <>
          <span className={classes.title}>Are You Sure?</span>
          <p>
            You are about to remove
            {' '}
            <b>
              {hcpRow?.firstName}
              {' '}
              {hcpRow?.lastName}
            </b>
            {' '}
            from this practice and also delete their account from the
            AlphaID Results Portal system. If you delete this user&apos;s
            account and they need to continue using the AlphaID Results
            Portal with a different practice, they will need to create a
            brand new account and request that a Grifols Admin add them to a
            different practice. However, this HCP&apos;s historical results will
            still be available to the Account Manager of this practice. Do
            you wish to proceed with deleting this user&apos;s account?
          </p>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={deleteHandler}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleModalClose}
            >
              no
            </ButtonComponent>
          </div>
        </>
      </Paper>
    </Modal>
  );
};

export default RemoveUserFromPracticeModal;
