import { axiosInstance } from '../utils/AxiosConfiguration';

export default function UpdateAccountDetails(newUserForm) {
  return axiosInstance.post('UpdateAccountDetails', {
    prefix: newUserForm.prefix,
    firstName: newUserForm.firstName,
    lastName: newUserForm.lastName,
    npi: newUserForm.npi,
    locationName: newUserForm.locationName,
    address1: newUserForm.address1,
    address2: newUserForm.address2,
    city: newUserForm.city,
    state: newUserForm.state,
    zip: newUserForm.zip,
    workPhone: newUserForm.workPhone,
    cellPhone: newUserForm.cellPhone,
    timeZone: newUserForm.timeZone,
    notificationStatus: newUserForm.notificationSettings,
  });
}
