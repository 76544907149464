import React from 'react';
import { makeStyles,
  Modal,
  Paper } from '@material-ui/core';
import { COLORS } from '../utils/applicationConstants';
import ButtonComponent from './common/ButtonComponent';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  warningText: {
    color: 'red',
    fontFamily: 'Roboto',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
}));

const RemoveLocationFromPracticeModal = (props) => {
  const classes = useStyles();
  const {
    modalOpen,
    setModalOpen,
    deleteHandler,
    locationRow,
    willHideResults,
  } = props;

  function handleModalClose() {
    setModalOpen(false);
  }
  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <>
          <span className={classes.title}>Are You Sure?</span>
          <p>
            You are about to remove
            {' '}
            <b>
              {locationRow?.address}
              {', '}
              {locationRow?.city}
              {', '}
              {locationRow?.state}
              {', '}
              {locationRow?.zipCode}
            </b>
            {' '}
            from this practice. By doing so, this
            practice will no longer be able to see results associated to
            this location in their AlphaID Results Portal if their
            HCPs are members of multiple practices. Only delete this location if
            it was added to a practice by mistake. If a practice has simply changed
            locations, previous locations should NOT be removed from a practice,
            so that historical results can still be viewed within the portal.
            Do you wish to proceed with removing this location from this practice?
          </p>
          {willHideResults
            ? (
              <p className={classes.warningText}>
                WARNING: Removing this location will remove access
                to one or more results on this practice&apos;s results dashboard
              </p>
            ) : (
              ''
            )}
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSize}
              onClick={deleteHandler}
            >
              yes
            </ButtonComponent>
            <ButtonComponent
              type="secondary"
              className={classes.secondaryButtonAsLink}
              onClick={handleModalClose}
            >
              no
            </ButtonComponent>
          </div>
        </>
      </Paper>
    </Modal>
  );
};

export default RemoveLocationFromPracticeModal;
