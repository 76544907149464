import { axiosInstance } from '../utils/AxiosConfiguration';

export default async function GetInsights(insightsYear) {
  let response;
  try {
    response = await axiosInstance.post('GetInsights', insightsYear);
  } catch (e) {
    Error(e);
  }
  return response.data;
}
