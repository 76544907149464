import React, { useState, useEffect, useContext } from 'react';
import { Input, InputAdornment, Hidden, CircularProgress, Button, Grid } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import { Search } from '@material-ui/icons';
import querystring from 'query-string';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../utils/applicationConstants';
import getResults from '../api/getResults';
import TablePagination from '../components/Pagination';
import FilterCard from '../components/common/FilterCard';
import { ClearFilterIcon } from '../utils/Icons';
import ExportButton from '../components/ExportButton';
import { useDashboardStyles } from '../styles/DashboardStyles';
import RepDataTable from '../components/RepDataTable';
import RepDataTableMobile from '../components/RepDataTableMobile';
import RepMobileFiltering from '../components/RepMobileFiltering';
import { csvRepHeaders } from '../utils/headers';
import AccountContext from '../contexts/AccountContext';

const RepDashboard = () => {
  const history = useHistory();
  function parseUrlString() {
    const { search } = window.location;
    const parsedQuery = querystring.parse(search);
    return parsedQuery;
  }
  const classes = useDashboardStyles();
  const [searchBar, setSearchBar] = useState(
    parseUrlString().search ? parseUrlString().search : '',
  );
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [statusCounts, setStatusCounts] = useState({
  });
  const [userWelcomeInformation, setUserWelcomeInformation] = useState(null);
  const filterLists = {
    accountStatus: ['Pending', 'Active', 'Denied'],
    role: ['HCP', 'Account Manager'],
  };
  const [mobileFilterDrawerOpen, setMobileFilterDrawerOpen] = useState(null);
  const toggleFilterDrawer = (event, open) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileFilterDrawerOpen(open);
  };
  const [queryFilters, setQueryFilters] = useState({
    ...parseUrlString(),
  });
  const [exportData, setExportData] = useState([]);
  const [headersList, setHeadersList] = useState({
    repAccountStatus: false,
    repAccountType: false,
  });
  /**
   * Querying the table results,
   * forEach loop is a one liner to remove any falsey object from the queryfilters
   * before setting it to the current url
   */
  // eslint-disable-next-line no-shadow
  async function getTableResults(pageNumber, pageSize, queryFilters) {
    const queryFilterClone = {
      ...queryFilters,
    };
    let response;
    try {
      response = await getResults(pageNumber, pageSize, queryFilters);
    } catch (e) {
      Error(e);
    }
    // eslint-disable-next-line max-len
    Object.keys(queryFilterClone).forEach((k) => !queryFilterClone[k] && delete queryFilterClone[k]);
    const newQueryString = querystring.stringify(queryFilterClone);
    history.push(`?${newQueryString}`);
    return response != null ? response.data : [];
  }

  function updateRowPriorityRendering(id) {
    let currentRow = results.find((row) => row.id === id);
    currentRow = {
      ...currentRow, isPriority: !currentRow.isPriority,
    };
    const index = results.findIndex((row) => row.id === id);
    const tempResults = [].concat(results);
    tempResults.splice(index, 1, currentRow);
    setResults(tempResults);
    return currentRow;
  }
  const { account } = useContext(AccountContext);
  useEffect(async () => {
    if (account.accountType !== undefined) {
      setIsTableLoading(true);
      setUserWelcomeInformation({
        firstName: account.firstName,
      });
      const response = await getTableResults(page + 1, pageSize, queryFilters);
      setResults(response.paginatedResponse.data);
      setTotalPages(response.paginatedResponse.totalPages);
      setTotalRecords(response.paginatedResponse.totalRecords);
      setStatusCounts(response.statusCounts);
      setExportData(response.unpaginatedResponse.data);
      setIsPageLoading(false);
      setIsTableLoading(false);
    }
  }, [page, pageSize, queryFilters]);

  function handlePagination(selectedPage) {
    setPage(selectedPage);
  }
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleQueryFilters = (params) => {
    setQueryFilters({
      ...params,
    });
  };

  const handleHeaderList = (params) => {
    setHeadersList(params);
  };

  function handleViewButton(viewSize) {
    setPage(0);
    setPageSize(viewSize);
  }
  function currentSearchInputHandler(event) {
    setSearchBar(event.target.value);
  }

  async function handleClearFilters() {
    setSearchBar('');
    setQueryFilters({
      accountStatus: null,
      role: 0,
      hcpOrManagerNameOrder: null,
      accountManagerNameOrder: null,
      search: null,
    });
    setHeadersList({
      repAccountStatus: false,
      repAccountType: false,
    });
  }

  async function handleSearch() {
    setQueryFilters({
      ...queryFilters,
      search: searchBar,
    });
  }
  const handleEnterToSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };
  function quickFilterHandler(quickFilterType) {
    const newFilters = {
      ...queryFilters,
    };
    newFilters[quickFilterType] = !newFilters[quickFilterType];
    if (!newFilters[quickFilterType]) {
      delete newFilters[quickFilterType];
    }
    setQueryFilters({
      ...newFilters,
    });
  }
  function getFirstRowNumber() {
    if (page === 0) {
      return 1;
    }
    if (results.length < pageSize) {
      return (pageSize * page) + 1;
    }
    return (results.length * page) + 1;
  }
  function getLastRowNumber() {
    if (page === 0) {
      if (results.length < pageSize) {
        return results.length;
      }
      return pageSize;
    }
    return (getFirstRowNumber() + results.length) - 1;
  }
  function incrementPriority(inc) {
    setStatusCounts(
      {
        actionReqCount: statusCounts.actionReqCount,
        priorityCount: statusCounts.priorityCount + inc,
      },
    );
  }

  if (isPageLoading) {
    return (
      <div className={classes.globalSpinnerContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className={classes.welcomeRow}>
        <h1 className={classes.welcomeHeader}>
          Welcome
          {' '}
          {userWelcomeInformation.firstName}
        </h1>
        <p className={classes.subHeader}>
          View the HCPs and Account Managers in your territories.
        </p>
      </div>
      <div>
        <div className={classes.filterCardRow}>
          <FilterCard
            isActive={queryFilters.actionRequired}
            count={statusCounts.actionReqCount}
            filterHandler={() => quickFilterHandler('actionRequired')}
            type="actionRequired"
          />
          <FilterCard
            isActive={queryFilters.priority}
            count={statusCounts.priorityCount}
            filterHandler={() => quickFilterHandler('priority')}
            type="priority"
          />
          <Hidden smDown>
            <FilterCard type="insights" />
          </Hidden>
        </div>
        <div className={classes.dashboardRow}>
          <ExportButton
            headers={csvRepHeaders}
            data={exportData}
            caller="rep"
            text="EXPORT HCPs"
          />
          <div className={classes.searchContainer}>
            <Hidden smDown>
              <Input
                placeholder="Search by First Name or Last Name"
                className={classes.searchInput}
                value={searchBar}
                onChange={currentSearchInputHandler}
                onKeyPress={handleEnterToSubmit}
                endAdornment={(
                  <InputAdornment
                    position="end"
                    onClick={handleSearch}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    <Search classes={{
                      root: classes.searchIconRoot,
                    }}
                    />
                    {' '}
                  </InputAdornment>
                )}
              />
            </Hidden>
          </div>
        </div>
        <Hidden smDown>
          <div className={classes.dashboardRow}>
            <div className={classes.viewButtonsContainer}>
              <div className={classes.viewText}>
                <span>
                  {' '}
                  {totalRecords}
                  {' '}
                  results
                  {' '}
                </span>
                <span className="view">View:</span>
              </div>
              <button
                type="button"
                className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(15)}
              >
                15
              </button>
              <button
                type="button"
                className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(30)}
              >
                30
              </button>
              <button
                type="button"
                className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
                onClick={() => handleViewButton(45)}
              >
                45
              </button>
            </div>
            <div className={classes.searchContainer}>
              <Button
                className={classes.clearButton}
                disabled={isTableLoading}
                disableRipple
                onClick={() => handleClearFilters()}
              >
                <p className={classes.clearText}>RESET ALL SEARCH, FILTERS, & SORTING</p>
                <ClearFilterIcon color={COLORS.ABBEY} />
              </Button>
            </div>
          </div>
          <RepDataTable
            results={results}
            handleViewHandler={handleViewButton}
            handlePageChangeHandler={handlePageChange}
            handleQueryFilters={handleQueryFilters}
            handleHeaderList={handleHeaderList}
            headersList={headersList}
            queryFilters={queryFilters}
            filterLists={filterLists}
            incrementPriority={incrementPriority}
            isTableLoading={isTableLoading}
            updateRowPriorityRendering={updateRowPriorityRendering}
          />
          <TablePagination
            selectedPage={page}
            numbers={totalPages}
            handlePageChange={handlePagination}
            records={totalRecords}
            firstRow={getFirstRowNumber()}
            lastRow={getLastRowNumber()}
          />
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            classes={{
              root: classes.mobileButtonGridRootContainer,
            }}
          >
            <Grid
              item
              xs={9}
            >
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'filter')}>
                Filter
                <KeyboardArrowDownIcon />
              </Button>
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'sort')}>
                Sort
                <KeyboardArrowDownIcon />
              </Button>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="flex-end"
            >
              <Button className={classes.drawerToggleButtons} onClick={(e) => toggleFilterDrawer(e, 'search')}><SearchIcon /></Button>
            </Grid>
          </Grid>
          <RepDataTableMobile
            results={results}
            handleViewHandler={handleViewButton}
            handlePageChangeHandler={handlePageChange}
            queryFilters={queryFilters}
            filterLists={filterLists}
            isTableLoading={isTableLoading}
            totalRecords={totalRecords}
            incrementPriority={incrementPriority}
            pageSize={pageSize}
            updateRowPriorityRendering={updateRowPriorityRendering}
            handleClearFilters={handleClearFilters}
          />
          <TablePagination
            type="Mobile"
            selectedPage={page}
            numbers={totalPages}
            handlePageChange={handlePagination}
            records={totalRecords}
            firstRow={getFirstRowNumber()}
            lastRow={getLastRowNumber()}
          />
          <RepMobileFiltering
            mobileFilterDrawerOpen={mobileFilterDrawerOpen}
            toggleFilterDrawer={toggleFilterDrawer}
            queryFilters={queryFilters}
            filterLists={filterLists}
            handleQueryFilters={handleQueryFilters}
          />
        </Hidden>
      </div>
    </>
  );
};
export default RepDashboard;
