import { axiosInstance } from '../utils/AxiosConfiguration';

function UpdatePriority(resultId) {
  return axiosInstance.post('/ToggleResultPriority', {
    ResultId: resultId,
  });
}

export default async function UpdateResultPriority(resultId) {
  try {
    await UpdatePriority(resultId);
  } catch (e) {
    Error(e);
  }
}
