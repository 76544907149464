import React from 'react';
import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    fontWeight: 'normal',
    marginTop: '72px',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
}));
const PageHeader = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <h1 className={classes.pageHeader}>{children}</h1>
  );
};

export default PageHeader;
