import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { useTableStylesHcp } from '../styles/TableStylesHcp';
import { COLORS, ACCOUNT_TYPES } from '../utils/applicationConstants';
import AccountContext from '../contexts/AccountContext';

const useStyles = makeStyles(({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  columnHeader: {
    color: COLORS.WHITE,
    textTransform: 'none',
    height: '64px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    width: '100%',
    '&:disabled': {
      color: COLORS.WHITE,
    },
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  row: {
    width: '150% !important',
  },
  removeButton: {
    color: 'red',
    fontFamily: 'Roboto',
  },
}));

const PracticeLocationTable = (props) => {
  const classes = useTableStylesHcp();
  const styleClasses = useStyles();
  const {
    results,
    handlePageChangeHandler,
    isTableLoading,
    deleteModalHandler,
  } = props;
  const { pageNumber } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;
  const { account } = useContext(AccountContext);
  function CustNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.noRowsOverlay}>
          <Typography className={styleClasses.noRowsHeader}>
            There are currently no locations aligned to this practice.
          </Typography>
          <Typography className={styleClasses.nowRowsParagraph}>
            To add locations to this practice, click &quot;
            Add a Location to this Practice&quot; above.
          </Typography>
        </div>
      </GridOverlay>
    );
  }

  function CustLoadingOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.loadingOverlay}>
          <CircularProgress fontSize="large" />
        </div>
      </GridOverlay>
    );
  }
  function renderRemoveCell() {
    if (account.accountType !== ACCOUNT_TYPES.GrifolsAdmin
      && account.accountType !== ACCOUNT_TYPES.AccountManager) {
      return {
      };
    }
    return {
      field: 'remove',
      headerName: 'Remove',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Button className={styleClasses.removeButton} onClick={() => deleteModalHandler(row)}>
            Remove
          </Button>
        );
      },
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p>Remove</p>
        </Button>
      ),
    };
  }
  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const columns = [
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      width: 400,
      renderHeader: () => (
        <Button className={styleClasses.columnHeader}>
          <p> Address </p>
        </Button>
      ),
    },
    {
      field: 'city',
      headerName: 'City',
      sortable: false,
      width: 200,
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p> City </p>
        </Button>
      ),
    },
    {
      field: 'zipCode',
      headerName: 'Zip Code',
      sortable: false,
      width: 150,
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p> Zip Code </p>
        </Button>
      ),
    },
    {
      field: 'state',
      headerName: 'State',
      sortable: false,
      width: 150,
      renderHeader: () => (
        <Button disabled disableRipple className={styleClasses.columnHeader}>
          <p> State </p>
        </Button>
      ),
    },
    renderRemoveCell(),
  ];

  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          rowsPerPageOptions={[10, 15, 20]}
          pageSize={100}
          onPageChange={(newPage) => handlePageChangeHandler(newPage)}
          pagination
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          disableColumnResize
          disableExtendRowFullWidth
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            renderingZone: styleClasses.managerViewRenderingZone,
            columnHeader: classes.columnHeader,
          }}
          sortingOrder={['desc', 'asc']}
          rows={results}
          columns={columns}
          components={{
            ColumnSortedDescendingIcon: () => <></>,
            ColumnSortedAscendingIcon: () => <></>,
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};
export default PracticeLocationTable;
