/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, InputLabel, InputBase, useMediaQuery, Modal, Paper } from '@material-ui/core';
import { deghostAccount } from '../api/AccountService';
import ButtonComponent from './common/ButtonComponent';

const DeghostAccountModal = (props) => {
  const { modalOpen, setModalOpen } = props;
  const med = useMediaQuery('(min-width:600px)');
  const useStyles = makeStyles({
    deghostAccountInput: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    form: {
      width: med ? '470px' : '95%',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      width: '100%',
    },
    inputLabel: {
      paddingBottom: '0.5rem',
      paddingTop: '1rem',
      color: '#58595B',
    },
    pageTitle: {
      fontWeight: '400',
      fontSize: '2rem',
      lineHeight: '34.5px',
      fontFamily: 'Roboto Condensed',
      marginTop: '4rem',
    },
    formHeader: {
      fontWeight: '700',
      fontSize: '22px',
      marginTop: '2rem',
      fontFamily: 'Roboto Condensed',
    },
    formText: {
      fontWeight: '300',
      fontSize: '20px',
      marginTop: '1rem',
      fontFamily: 'Roboto Condensed',
    },
    button: {
      padding: '15px 40px 15px 40px',
      backgroundColor: '#0B7AEF',
      color: 'white',
      borderRadius: '30px',
      marginTop: '1.5rem',

      '&:hover': {
        backgroundColor: '#0B7AEF',
        color: 'white',
      },

      '&:disabled': {
        backgroundColor: '#e7e7e7',
      },
    },
    errorMessage: {
      color: 'red',
      paddingTop: '1rem',
    },
    inputs: {
      marginTop: '1rem',
    },
  });

  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState('');
  async function submitForm() {
    setIsSubmitted(true);
    try {
      const res = await deghostAccount(formData);
      if (res.status === 200 && res.data.isSuccess) {
        setSuccessText(res.data.errorMessage);
        setShowSuccessText(true);
        setFormData('');
      } else {
        setErrorText(res.data.errorMessage);
        setShowErrorText(true);
      }
    } catch (e) {
      setIsSubmitted(false);
    }
  }

  useEffect(() => {
    if (formData !== null && formData !== '') {
      setShowSuccessText(false);
      setIsSubmitted(false);
    }
  }, [formData]);

  function handleOnChange(event) {
    const deghostCopy = {
      ...formData,
    };
    deghostCopy[event.target.name] = event.target.value;
    setFormData(deghostCopy);
  }

  function handleModalClose() {
    setModalOpen(false);
  }
  const test = {
    ...formData, notificationSettings: '', prefix: '',
  };
  const classes = useStyles();
  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <form id="form" className={classes.formContainer}>
          <div className={classes.form}>
            <Typography className={classes.formHeader}>
              Deghost Account
            </Typography>
            <Typography className={classes.formText}>
              This form enables conversion of ghost accounts (which can&apos;t be
              used to log into HCP Portal) to full HCP accounts.
            </Typography>
            <div className={classes.inputs}>
              <InputLabel htmlFor="npi" className={classes.inputLabel}>NPI</InputLabel>
              <InputBase
                id="npi"
                name="npi"
                value={formData.npi}
                onChange={handleOnChange}
                className={classes.deghostAccountInput}
              />

              <InputLabel htmlFor="zipCode" className={classes.inputLabel}>Zip</InputLabel>
              <InputBase
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleOnChange}
                className={classes.deghostAccountInput}
              />

              <InputLabel htmlFor="newAccountEmailAddress" className={classes.inputLabel}>
                New Account Email Address
              </InputLabel>
              <InputBase
                id="newAccountEmailAddress"
                name="newAccountEmailAddress"
                value={formData.newAccountEmailAddress}
                onChange={handleOnChange}
                className={classes.deghostAccountInput}
              />

              { /* eslint-disable-next-line max-len */}

              <ButtonComponent disabled={!Object.values(test).some((field) => field !== undefined && field !== '') || isSubmitted} onClick={submitForm} className={classes.button}>
                Deghost Account
              </ButtonComponent>
              <br />
              <div>
                {showSuccessText && (
                  <Typography className={classes.formText}>
                    {successText}
                  </Typography>
                )}
                {showErrorText && (
                  <Typography className={classes.errorMessage}>
                    {errorText}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};

export default DeghostAccountModal;
