const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_POLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AUTHORITY,
    },
  },
};
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorities.signUpSignIn.authority],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

export const loginRequest = {
  scopes: [''],
};
