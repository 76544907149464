import React from 'react';
import { getInProcessFormattedDate } from '../../utils/dateFormatter';
import Tooltip from '../tooltip';
import { ClockIcon, FlagIcon } from '../../utils/Icons';
import { COLORS } from '../../utils/applicationConstants';
import { getActionReqText } from '../../utils/statusHelpers';

const StatusRendererMobile = ({ userRow }) => {
  const { status, exceptionMessage, labReceiptDate } = userRow;
  function getLabDate(date) {
    return `Date received: ${getInProcessFormattedDate(date)}`;
  }
  switch (status) {
    case 'In Process':
      return (
        <>
          <p>{status}</p>
          <span className="status-icon">
            <Tooltip content={getLabDate(labReceiptDate)} icon="clock">
              <ClockIcon color={COLORS.ABBEY} />
            </Tooltip>
          </span>
        </>
      );
    case 'Action Required':
      return (
        <>
          <p>{status}</p>
          <span className="status-icon">
            <Tooltip
              content={getActionReqText(exceptionMessage)}
              icon="flag"
              style={{ zIndex: 20 }}
            >
              <FlagIcon color={COLORS.CARDINAL} />
            </Tooltip>
          </span>
        </>
      );
    default:
      return (
        <>
          {status}
        </>
      );
  }
};

export default StatusRendererMobile;
