import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, CircularProgress, Button } from '@material-ui/core';
import { COLORS, ACCOUNT_TYPES } from '../utils/applicationConstants';
import AccountContext from '../contexts/AccountContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    overflowX: 'visible',
  },
  row: {
    background: COLORS.MERCURY,
    borderRadius: '15px',
    border: 'none',
    height: '184px',
    padding: '0 24px',
  },
  thRoot: {
    borderRadius: '15px',
  },
  priorityPaperRoot: {
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'left',
    background: 'inherit',
    color: COLORS.BLACK,
    boxShadow: 'none',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
  },
  tableRoot: {
    borderCollapse: 'inherit !important',
    borderSpacing: '2px',
  },
  p: {
    display: 'inline-block',
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  buttonGroup: {
    color: COLORS.ABBEY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '24px',
  },
  pAsLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  drawerToggleButtons: {
    backgroundColor: COLORS.WILD_SAND,
    color: COLORS.ABBEY,
    padding: '1rem 1rem',
    borderRadius: '1rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.WILD_SAND,
      color: COLORS.ABBEY,
      padding: '1rem 1rem',
      borderRadius: '1rem',
      alignSelf: 'center',
    },
  },
  removeButton: {
    color: 'red',
    fontFamily: 'Roboto',
  },
}));

const PracticeLocationTableMobile = (props) => {
  const classes = useStyles();
  const {
    results,
    isTableLoading,
    pageSize,
    deleteModalHandler,
  } = props;
  const { account } = useContext(AccountContext);
  if (isTableLoading) {
    return <CircularProgress />;
  }
  function renderRemoveField(item) {
    if (account.accountType !== ACCOUNT_TYPES.GrifolsAdmin
      && account.accountType !== ACCOUNT_TYPES.AccountManager) {
      return null;
    }
    return (
      <Grid item xs>
        <Paper className={classes.paper}>
          <p className={classes.pAsLabel}>
            Remove:
            {' '}
            <Button className={classes.removeButton} onClick={() => deleteModalHandler(item)}>
              Remove
            </Button>
          </p>
        </Paper>
      </Grid>
    );
  }
  function renderPracticeLocationMobileTable() {
    return (
      <>
        <TableContainer className={classes.root} component={Paper}>
          <Table
            classes={{
              root: classes.tableRoot,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {(pageSize > 0
                ? results.slice(0, pageSize)
                : results
              ).map((item) => (
                <TableRow
                  key={item.id}
                  classes={{
                    root: classes.row,
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{
                      root: classes.thRoot,
                    }}
                  >
                    <Grid container spacing={7}>
                      <Grid item xs={6}>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Address:
                            {' '}
                            {item.address}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            City:
                            {' '}
                            {item.city}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            Zip Code:
                            {' '}
                            {item.zipCode}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs>
                        <Paper className={classes.paper}>
                          <p className={classes.pAsLabel}>
                            State:
                            {' '}
                            {item.state}
                          </p>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={7}>
                      {renderRemoveField(item)}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <div className={classes.root}>
        {renderPracticeLocationMobileTable()}
      </div>
    </>
  );
};
export default PracticeLocationTableMobile;
