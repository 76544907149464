import * as React from 'react';
import { COLORS } from './applicationConstants';

function Icon(props) {
  const { color, path, path2, size, className, viewBox = 27 } = props;
  return (
    <svg width={size} height={size} viewBox={`0 0 ${viewBox} ${viewBox}`} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fill={color} d={path} />
      <path fill={color} d={path2} />
    </svg>
  );
}

function SortIcon(props) {
  const { color, size = 24, className } = props;
  return (
    <Icon
      className={className}
      color={color || COLORS.AZURE}
      path="M4.03997 0.190002L0.149971 4.44C-0.180029 4.81 0.0799714 5.4 0.569971 5.4H8.34997C8.84997 5.4 9.10997 4.81 8.76997 4.45L4.87997 0.190002C4.65997 -0.0599976 4.26997 -0.0599976 4.03997 0.190002Z"
      path2="M4.88005 12.72L8.77005 8.46001C9.11005 8.10001 8.85005 7.51001 8.35005 7.51001H0.570049C0.0700489 7.51001 -0.189951 8.10001 0.150049 8.46001L4.04005 12.72C4.27005 12.97 4.66005 12.97 4.88005 12.72Z"
      size={size}
    />
  );
}

function WarningIcon(props) {
  const { color, size = 24 } = props;
  return (
    <Icon
      color={color || COLORS.AZURE}
      path="M24.8215 18.2885L13.478 0.530581C13.0295 -0.173015 11.9702 -0.173015 11.5217 0.530581L0.172773 18.2885C-0.3028 19.0341 0.248432 20.0003 1.15094 20.0003H23.8379C24.7458 20.0003 25.2971 19.0341 24.8215 18.2885ZM11.3649 6.91021H13.6726C13.7536 6.91021 13.8239 6.97847 13.8185 7.05723L13.5158 12.3867C13.5104 12.4602 13.451 12.5232 13.3699 12.5232H11.6622C11.5865 12.5232 11.5217 12.4655 11.5163 12.3867L11.2136 7.05723C11.2136 6.97847 11.2839 6.91021 11.3649 6.91021ZM13.4672 16.5138C13.2078 16.7396 12.8835 16.8498 12.4944 16.8498C12.1053 16.8498 11.7811 16.7396 11.5217 16.5138C11.2623 16.288 11.1326 16.0045 11.1326 15.6579C11.1326 15.3114 11.2623 15.0226 11.5217 14.802C11.7811 14.5763 12.1053 14.466 12.4944 14.466C12.8835 14.466 13.2078 14.5763 13.4672 14.802C13.7266 15.0278 13.8563 15.3114 13.8563 15.6579C13.8563 16.0045 13.7266 16.288 13.4672 16.5138Z"
      size={size}
    />
  );
}

function ClockIcon(props) {
  const { color, size = 24 } = props;
  return (
    <Icon
      color={color || COLORS.AZURE}
      path="M7.47795 16.7322V15.6795C3.31099 15.4117 0 12.009 0 7.85361C0 3.52281 3.58848 0 8 0C12.4115 0 16 3.52281 16 7.85361C16 9.60348 15.4262 11.261 14.3398 12.6461C14.0247 13.0478 13.4321 13.1263 13.0182 12.8123C12.6044 12.4984 12.5291 11.9212 12.8489 11.5149C13.6814 10.4576 14.1188 9.18794 14.1188 7.849C14.1188 4.54318 11.3721 1.84682 8 1.84682C4.62787 1.84682 1.88125 4.54318 1.88125 7.85361C1.88125 10.9932 4.34568 13.5695 7.47795 13.8327V12.9139C7.47795 12.6831 7.75544 12.563 7.92475 12.72L10.0506 14.6268C10.1681 14.733 10.1681 14.9131 10.0506 15.0147L7.92475 16.9215C7.75074 17.0831 7.47795 16.9631 7.47795 16.7322Z"
      path2="M4.65595 7.67822H8.26325L9.44373 4.84334C9.64126 4.3724 10.1915 4.14617 10.6712 4.34008C11.0334 4.48783 11.2544 4.82949 11.2544 5.19424C11.2544 5.30967 11.2309 5.42971 11.1839 5.54514L9.52368 9.52504H4.65595C4.13861 9.52504 3.71533 9.1095 3.71533 8.60163C3.71533 8.09375 4.13391 7.67822 4.65595 7.67822Z"
      size={size}
    />
  );
}

function FlagIcon(props) {
  const { color, size = 24 } = props;
  return (
    <Icon
      color={color || COLORS.AZURE}
      path="M13.1056 1.38957H7.89444C7.85556 1.38957 7.81667 1.40435 7.77778 1.41174V0.85C7.77778 0.384348 7.37333 0 6.88333 0H0.894444C0.645556 0 0.427778 0.096087 0.264444 0.251304C0.101111 0.406522 0 0.613478 0 0.85V9.57913V16.1426C0 16.6157 0.404444 17 0.894444 17C1.39222 17 1.78889 16.6157 1.78889 16.15V10.4365H6.87556C6.91444 10.4365 6.95333 10.4217 6.99222 10.4143V10.9761C7 11.4417 7.40444 11.8261 7.89444 11.8261H13.1056C13.6033 11.8261 14 11.4417 14 10.9761V2.23957C14 1.77391 13.5956 1.38957 13.1056 1.38957Z"
      size={size}
    />
  );
}

function StarIcon(props) {
  const { color } = props;
  return (
    <Icon
      color={color || COLORS.WEB_ORANGE}
      path="M9.16264 0.466472L10.953 5.11047C11.0555 5.37624 11.2915 5.55342 11.5632 5.57673L16.3376 5.92643C16.9745 5.97305 17.2373 6.81233 16.7474 7.24129L13.0819 10.4585C12.8726 10.6404 12.7835 10.9341 12.8503 11.2092L14.0083 16.0677C14.1642 16.7204 13.4828 17.2333 12.9394 16.879L8.88205 14.2213C8.65045 14.0721 8.35651 14.0721 8.12936 14.2213L4.07199 16.879C3.52863 17.2333 2.84721 16.7158 3.00309 16.0677L4.16106 11.2092C4.22787 10.9341 4.13434 10.6404 3.92947 10.4585L0.264025 7.24129C-0.225888 6.81233 0.0324295 5.97305 0.673771 5.92643L5.4482 5.57673C5.71988 5.55808 5.95593 5.37624 6.05837 5.11047L7.83987 0.466472C8.08037 -0.15366 8.92213 -0.15366 9.16264 0.466472Z"
      size={24}
    />
  );
}

function ExportIcon(props) {
  const { color, size } = props;
  return (
    <Icon
      color={color || COLORS.WHITE}
      path="M6.1238 13.3389C6.34927 13.5849 6.66699 13.7233 7.00008 13.7233C7.33317 13.7233 7.65088 13.5849 7.87636 13.3389L13.1853 7.51757C13.6209 7.041 13.5799 6.30308 13.0982 5.87262C12.6165 5.44217 11.8683 5.48316 11.4327 5.95974L8.15308 9.5571V0.989019C8.15308 0.440703 7.63551 0 7.00008 0C6.36465 0 5.84708 0.440703 5.84708 0.989019V9.5571L2.56743 5.95974C2.13185 5.48316 1.38368 5.44217 0.901981 5.87262C0.415158 6.30308 0.379287 7.041 0.814866 7.51757L6.1238 13.3389Z"
      path2="M12.9495 15.153H1.05051C0.471449 15.153 0 15.6705 0 16.306C0 16.9414 0.471449 17.459 1.05051 17.459H12.9495C13.5286 17.459 14 16.9414 14 16.306C14 15.6705 13.5337 15.153 12.9495 15.153Z"
      size={size}
    />
  );
}

function ClearFilterIcon(props) {
  const { color } = props;
  return (
    <Icon
      color={color || COLORS.AZURE}
      path="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
      size={24}
      viewBox={24}
    />
  );
}

function CloseIcon(props) {
  const { color, size, className } = props;
  return (
    <Icon
      color={color || COLORS.AZURE}
      path="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
      size={size}
      className={className}
    />
  );
}

export {
  WarningIcon,
  ClockIcon,
  FlagIcon,
  SortIcon,
  StarIcon,
  ExportIcon,
  ClearFilterIcon,
  CloseIcon,
};
