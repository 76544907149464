function getFormattedDate(params) {
  if (!params || params.value === null) {
    return ('--');
  }
  if (params.value === '--') {
    return params.value;
  }
  return new Date(params.value).toLocaleDateString('en-US');
}

export function getInProcessFormattedDate(date) {
  if (date === null || date === '--') {
    return ('--');
  }
  return new Date(date).toLocaleDateString('en-US');
}

export default getFormattedDate;
