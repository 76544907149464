import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../utils/applicationConstants';
import { ClockIcon, FlagIcon, WarningIcon } from '../utils/Icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: '180px',
    background: COLORS.WHITE,
    boxShadow: '6px 6px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    whiteSpace: 'break-spaces',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: '-20px',
  },
  divider: {
    border: 'none',
    borderTop: `1px solid ${COLORS.MISCHKA}`,
  },
}));

export const TooltipIcon = (props) => {
  switch (props.icon) {
    case 'clock': return <ClockIcon color={COLORS.ABBEY} />;
    case 'flag': return <FlagIcon color={COLORS.CARDINAL} />;
    case 'warning': return <WarningIcon color={COLORS.AZURE} />;
    default: return <span />;
  }
};

export default function Tooltip(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { content, icon } = props;
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>

      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <TooltipIcon icon={icon} />

      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TooltipIcon icon={icon} />
        <hr className={classes.divider} />
        <p style={{
          lineHeight: 'normal',
        }}
        >
          {content}
        </p>
      </Popover>
    </div>
  );
}
