import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import { AxiosConfiguration } from '../utils/AxiosConfiguration';
import Footer from '../components/Footer';
import AccountContext from '../contexts/AccountContext';
import { ACCOUNT_TYPES } from '../utils/applicationConstants';
import Navigation from '../components/Navigation';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      marginTop: '100px',
      width: 'auto',
      padding: '0 23px',
    },
    '&.manager': {
      maxWidth: '1280px',
      [theme.breakpoints.down('md')]: {
        marginTop: '100px',
        width: '100%',
      },
    },
  },
  globalSpinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
}));
// eslint-disable-next-line no-unused-vars
const
  Layout = (props) => {
    const location = useLocation();
    const classes = useStyles();
    const { account } = useContext(AccountContext);
    const { children } = props;
    const { accounts, instance, inProgress } = useMsal();
    const [firstLayoutLoad, setFirstLayoutLoad] = useState(true);
    const [currentAccessToken, setCurrentAccessToken] = useState(null);

    function conditionalLayoutForLargeDataTable() {
      if ((account.accountType === ACCOUNT_TYPES.AccountManager || account.accountType === ACCOUNT_TYPES.GrifolsRep) && window.location.pathname === '/dashboard') {
        return `${classes.pageContainer} manager`;
      }
      return classes.pageContainer;
    }
    const [showLayout, setShowLayout] = useState();
    useEffect(() => {
      if (window.location.pathname === '/details') {
        setShowLayout(false);
        return;
      }
      if (window.location.pathname === '/details/success') {
        setShowLayout(false);
        return;
      }
      if (window.location.pathname === '/denied') {
        setShowLayout(false);
        return;
      }
      setShowLayout(true);
    }, [location]);

    useEffect(async () => {
      const accessTokenRequest = {
        scopes: [''],
        account: accounts[0],
      };
      if (firstLayoutLoad && inProgress === InteractionStatus.None) {
        if (accounts) {
          let accessTokenResponse;
          try {
            accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          } catch (error) {
            Error(error);
          }
          setCurrentAccessToken(accessTokenResponse?.idToken);
          setFirstLayoutLoad(false);
        }
      }
    }, [instance, accounts, inProgress]);

    if (currentAccessToken === null) {
      return (
        <>
          <div className={classes.globalSpinnerContainer}>
            <CircularProgress />
          </div>
          <Footer />
        </>
      );
    }
    if (!showLayout) {
      return (
        <>
          <AxiosConfiguration msalId={currentAccessToken} />
          <Navigation />
          {children}
        </>
      );
    }
    if (firstLayoutLoad && window.location.pathname !== '/') {
      return (
        <>
          <Navigation />
          <div className={classes.globalSpinnerContainer}>
            <CircularProgress />
          </div>
          <Footer />
        </>
      );
    }
    return (
      <>
        <AxiosConfiguration msalId={currentAccessToken} />
        <Navigation />
        <div className={conditionalLayoutForLargeDataTable()}>
          {children}
        </div>
        <Footer />
      </>
    );
  };

export default Layout;
