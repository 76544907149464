const VALID_ZIPCODE_LENGTH = 5;
const VALID_NPI_LENGTH = 10;
const VALID_PHONE_LENGTH = 10;

function activeTabHandler(navigationList) {
  const currentPathName = window.location.pathname;
  let selectedTab;
  navigationList.forEach((item, index) => {
    if (item.link === currentPathName) {
      selectedTab = index;
    }
  });
  return selectedTab;
}

function isDigitsOnly(value) {
  return /^\d+$/.test(value);
}

function isProperLength(value, length) {
  return value.trim().length === length;
}

function isValidZipCode(zip) {
  return isProperLength(zip, VALID_ZIPCODE_LENGTH) && isDigitsOnly(zip);
}

function isValidPhoneNumber(phone) {
  return isProperLength(phone, VALID_PHONE_LENGTH) && isDigitsOnly(phone);
}

function isValidNpi(npi) {
  return isProperLength(npi, VALID_NPI_LENGTH) && isDigitsOnly(npi);
}

function isGhostNpi(npiEmail) {
  const split = npiEmail.split('@');
  return split.length > 1 && split[1].toLowerCase() === 'ghost.hcp';
}

// from https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function isValidEmail(email) {
  return email.match(EMAIL_REGEX) !== null;
}

export {
  activeTabHandler,
  isValidNpi,
  isValidZipCode,
  isValidPhoneNumber,
  isGhostNpi,
  isValidEmail,
};
