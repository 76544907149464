import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import RepDashboard from './RepDashboard';
import HCPMngrDashboard from './HCPMngrDashboard';
import AccountContext from '../contexts/AccountContext';
import { ACCOUNT_TYPES } from '../utils/applicationConstants';

export default function Dashboard() {
  const { account } = useContext(AccountContext);

  if (account.accountType !== null) {
    if (account.accountType === ACCOUNT_TYPES.GrifolsRep) {
      return (
        <RepDashboard />
      );
    }
    return (
      <HCPMngrDashboard />
    );
  }
  return (
    <CircularProgress />
  );
}
