import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { InputLabel, InputBase } from '@material-ui/core';
import ButtonComponent from './common/ButtonComponent';
import { isValidZipCode } from '../utils/helpers';

const CreateManagedAccountForm = (props) => {
  const { disableSubmit, onSubmit } = props;
  const useStyles = makeStyles({
    additionalDetailsInput: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'flex-start',
      width: '100%',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      width: '100%',
    },
    inputLabel: {
      paddingBottom: '0.5rem',
      paddingTop: '1rem',
      color: '#58595B',
    },
  });
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    zip: '',
  });

  function handleOnChange(event) {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    const userInformationCopy = {
      ...formData,
    };
    userInformationCopy[inputName] = inputValue;
    setFormData(userInformationCopy);
  }

  return (

    <form id="form" className={classes.formContainer}>
      <div className={classes.inputContainer}>
        <InputLabel htmlFor="zip" className={classes.inputLabel}>Zip Code</InputLabel>
        <InputBase
          id="zip"
          name="zip"
          value={formData.zip}
          onChange={handleOnChange}
          className={classes.additionalDetailsInput}
          placeholder="5 digits (e.g., 75001)"
          error={() => formData.zip}
        />

        <InputLabel htmlFor="firstName" className={classes.inputLabel}>First Name</InputLabel>
        <InputBase
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleOnChange}
          className={classes.additionalDetailsInput}
        />

        <InputLabel htmlFor="lastName" className={classes.inputLabel}>Last Name</InputLabel>
        <InputBase
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleOnChange}
          className={classes.additionalDetailsInput}
        />

        <ButtonComponent
          disabled={
            disableSubmit
            || formData.firstName.trim().length === 0
            || formData.lastName.trim().length === 0
            || !isValidZipCode(formData.zip)
          }
          onClick={() => onSubmit(formData)}
        >
          Create Managed Account
        </ButtonComponent>
      </div>
    </form>
  );
};

export default CreateManagedAccountForm;
