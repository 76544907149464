import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../utils/applicationConstants';
import actionRequiredInCircle from '../../images/icons/actionRequiredInCircle.svg';
import priorityInCircle from '../../images/icons/priorityInCircle.svg';
import abnormalInCircle from '../../images/icons/abnormalInCircle.svg';
import chartInCircle from '../../images/icons/chartInCircle.svg';
import lineChart from '../../images/lineChart.svg';
import RadioIcon from './RadioIcon';

const useStyles = makeStyles((theme) => ({
  filterCardRoot: {
    borderRadius: '30px',
    border: 'none',
    maxWidth: '206px',
    width: '100%',
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: 'auto',
      margin: '0 0 15px 0',
    },

  },
  insightsCardRoot: {
    backgroundColor: COLORS.SOLITUDE,
    background: `url(${lineChart}) no-repeat center`,
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
    height: '137px',
  },
  labelContainer: {
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'left',
    '& span': {
      color: COLORS.ABBEY,
    },
  },
  filterByLabel: {
    fontSize: '13px',
    lineHeight: '15px',
  },
  statusCount: {
    color: COLORS.ABBEY,
    fontWeight: 'bold',
    fontSize: '60px',
    lineHeight: '48px',
  },
  cardTitle: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '26px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignAtEnd: {
    alignItems: 'flex-end',
  },
  alignAtCenter: {
    justifyContent: 'center',
  },
  primaryButton: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.AZURE_RAD,
    border: 'none',
    padding: '15px 40px',
    borderRadius: '30px',
    lineHeight: '19px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: '0.2s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
}));
const FilterCard = (props) => {
  const classes = useStyles();
  const { filterHandler, type, count, isActive } = props;

  function cardBorderSet() {
    if (!isActive) {
      return '3px solid transparent';
    }
    if (type === 'actionRequired') {
      return `3px solid ${COLORS.CARDINAL}`;
    }
    if (type === 'priority') {
      return `3px solid ${COLORS.ORANGE}`;
    }
    if (type === 'abnormal') {
      return `3px solid ${COLORS.AZURE_RAD}`;
    }
    return '';
  }

  function baseCard(icon, title, cardColor) {
    return (
      <div
        role="button"
        onKeyDown={filterHandler}
        tabIndex={0}
        onClick={filterHandler}
        className={classes.filterCardRoot}
        style={{
          backgroundColor: cardColor,
          border: cardBorderSet(),
          cursor: 'pointer',
        }}
      >
        <div className={classes.buttonContent}>
          <div className={classes.buttonRow}>
            <div className={classes.labelContainer}>
              <span className={classes.filterByLabel}>Filter By</span>
              <span className={classes.cardTitle}>{title}</span>
            </div>
            <div className={classes.iconContainer}>
              <img alt="" role="presentation" src={icon} />
            </div>
          </div>
          <div className={`${classes.buttonRow} ${classes.alignAtEnd}`}>
            <span className={classes.statusCount}>{count}</span>
            <RadioIcon type={type} isChecked={isActive} />
          </div>
        </div>
      </div>
    );
  }
  function renderInsightsCard() {
    return (
      <div
        className={`${classes.filterCardRoot} ${classes.insightsCardRoot}`}
      >
        <div className={classes.buttonContent}>
          <div className={classes.buttonRow}>
            <div className={classes.labelContainer}>
              <span className={classes.filterByLabel}>See Your</span>
              <span className={classes.cardTitle}>Insights</span>
            </div>
            <div className={classes.iconContainer}>
              <img alt="chart icon" src={chartInCircle} />
            </div>
          </div>
          <div className={`${classes.buttonRow} ${classes.alignAtCenter}`}>
            <NavLink to="/insights">
              <button className={classes.primaryButton} type="button">
                See All
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
  function renderCardFromType() {
    if (type === 'actionRequired') {
      return baseCard(actionRequiredInCircle, 'Action Required', COLORS.TUTU);
    }
    if (type === 'priority') {
      return baseCard(priorityInCircle, 'Priority', COLORS.SAZERAC);
    }
    if (type === 'abnormal') {
      return baseCard(abnormalInCircle, 'Abnormal', COLORS.PATTENS_BLUE);
    }
    if (type === 'insights') {
      return renderInsightsCard();
    }
    return null;
  }
  return renderCardFromType();
};

export default FilterCard;
