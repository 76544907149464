import React, { useEffect, useState } from 'react';
import { CircularProgress, Hidden, makeStyles } from '@material-ui/core';
import querystring from 'query-string';
import HcpRepTable from '../components/HcpRepTable';
import { getMyHcps } from '../api/HcpService';
import HcpTableMobile from '../components/HcpTableMobile';
import TablePagination from '../components/Pagination';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    marginTop: '72px',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
}));

const HcpRepView = () => {
  const [isLoaded, setLoadedState] = useState(false);
  const classes = useStyles();
  function parseUrlString() {
    const { search } = window.location;
    const parsedQuery = querystring.parse(search);
    return parsedQuery;
  }
  const [queryFilters, setQueryFilters] = useState({
    ...parseUrlString(),
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [hcpResults, setHcpResults] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(async () => {
    setLoadedState(true);
    setIsTableLoading(true);
    const hcpResultsResponse = await getMyHcps(page + 1, pageSize, queryFilters);
    setIsTableLoading(false);
    setTotalPages(hcpResultsResponse.totalPages);
    setTotalRecords(hcpResultsResponse.totalRecords);
    setHcpResults(hcpResultsResponse.data);
  }, [page, pageSize, queryFilters]);
  if (!isLoaded) {
    return <CircularProgress />;
  }
  function handleViewButton(viewSize) {
    setPage(0);
    setPageSize(viewSize);
  }
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handleQueryFilters = (params) => {
    setQueryFilters(params);
  };
  function getFirstRowNumber() {
    if (page === 0) {
      return 1;
    }
    if (hcpResults.length < pageSize) {
      return (pageSize * page) + 1;
    }
    return (hcpResults.length * page) + 1;
  }
  function getLastRowNumber() {
    if (page === 0) {
      if (hcpResults.length < pageSize) {
        return hcpResults.length;
      }
      return pageSize;
    }
    return (getFirstRowNumber() + hcpResults.length) - 1;
  }
  function handlePagination(selectedPage) {
    setPage(selectedPage);
  }
  return (
    <div>
      <h1 className={classes.pageHeader}>
        My HCPs
      </h1>
      <Hidden smDown>
        <div className={classes.buttonGroup}>
          <span>
            {' '}
            {totalRecords}
            {' '}
            results
            {' '}
          </span>
          <span className={classes.viewText}>View:</span>
          <button
            type="button"
            className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(15)}
          >
            15
          </button>
          <button
            type="button"
            className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(30)}
          >
            30
          </button>
          <button
            type="button"
            className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(45)}
          >
            45
          </button>
        </div>
        <HcpRepTable
          results={hcpResults}
          handleViewHandler={handleViewButton}
          handlePageChangeHandler={handlePageChange}
          handleQueryFilters={handleQueryFilters}
          queryFilters={queryFilters}
          isTableLoading={isTableLoading}
        />
        <TablePagination
          selectedPage={page}
          numbers={totalPages}
          handlePageChange={handlePagination}
          records={totalRecords}
          firstRow={getFirstRowNumber()}
          lastRow={getLastRowNumber()}
        />
      </Hidden>
      <Hidden mdUp>
        <HcpTableMobile
          results={hcpResults}
          handleViewHandler={handleViewButton}
          handlePageChangeHandler={handlePageChange}
          handleQueryFilters={handleQueryFilters}
          queryFilters={queryFilters}
          isTableLoading={isTableLoading}
          pageSize={pageSize}
          totalRecords={totalRecords}
        />
        <TablePagination
          type="Mobile"
          selectedPage={page}
          numbers={totalPages}
          handlePageChange={handlePagination}
          records={totalRecords}
          firstRow={getFirstRowNumber()}
          lastRow={getLastRowNumber()}
        />
      </Hidden>
    </div>
  );
};

export default HcpRepView;
