/**
 * @file This is the account context file.
 * @description Just a note, this context is being set in two places.
 * Once in the Success.jsx file for successful routing,
 * and another in the axiosConfiguraton.jsx file to handle
 * page refreshes/auth token issues.
 */

import React, { createContext, useState } from 'react';

const AccountContext = createContext();

export const AccountContextProvider = (props) => {
  const { children } = props;
  const [account, setAccount] = useState('');
  return (
    <AccountContext.Provider value={{
      account, setAccount,
    }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContext;
