import React, { useContext, useState, useEffect } from 'react';
import { makeStyles,
  AppBar,
  IconButton,
  Drawer,
  ListItem,
  List,
  ListItemIcon } from '@material-ui/core';
import { ChevronRight, Close, Menu } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { COLORS, UTILITY_NAVIGATION } from '../utils/applicationConstants';
import Logo from '../images/logo.svg';
import MobileIcon from '../images/mobileIcon.svg';
import { loginRequest } from '../utils/authConfig';
import AccountContext from '../contexts/AccountContext';
import { getMainNavigation } from '../utils/NavigationLinksByRole';
import InterstitialPrompt from './common/InterstitialPrompt';

const useStyles = makeStyles(({
  mobileNavRoot: {
    width: '100%',
  },
  appBarRoot: {
    height: '67px',
    backgroundColor: COLORS.WHITE,
    boxShadow: 'none',
    borderBottom: `1px solid ${COLORS.ZIRCON}`,
  },
  hamburgerIconRoot: {
    color: COLORS.ABBEY,
  },
  headerContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
  },
  logoRoot: {
    width: '108px',
  },
  drawerPaper: {
    height: '100%',
    padding: '0 24px',
  },
  closeIconRoot: {
    color: COLORS.ABBEY,
  },
  iconButtonRoot: {
    width: '50px',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  mobileLogo: {
    marginBottom: '32px',
  },
  listIconRoot: {
    justifyContent: 'flex-end',
    color: COLORS.ABBEY,
  },
  listItemRoot: {
    justifyContent: 'space-between',
    fontSize: '16px',
    color: COLORS.ABBEY,
    lineHeight: '19px',
    fontWeight: 'bold',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    marginBottom: '24px',
  },
  listRoot: {
    borderBottom: `1px solid ${COLORS.ZIRCON}`,
  },
  utilityNavigationRoot: {
    marginTop: '24px',
  },
}));

const MobileNavigation = () => {
  const windowLoc = useLocation();
  const [showMainNav, setShowMainNav] = useState(false);
  useEffect(() => {
    if (window.location.pathname === '/details') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/details/success') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/denied') {
      setShowMainNav(false);
      return;
    }
    if (window.location.pathname === '/success') {
      setShowMainNav(false);
      return;
    }
    setShowMainNav(true);
  }, [windowLoc]);
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawer] = useState(false);
  const [utilityNavigationModal, setUtilityNavigationModal] = useState(false);
  const { account } = useContext(AccountContext);
  const currentMainNavigation = getMainNavigation(account.accountType);
  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileDrawer(open);
  };
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  function handleLogout() {
    instance.logoutRedirect(loginRequest);
  }
  if (!showMainNav) {
    return (
      <>
        <div className={classes.mobileNavRoot}>
          <AppBar classes={{
            root: classes.appBarRoot,
          }}
          >
            <div className={classes.headerContainer}>
              <UnauthenticatedTemplate>
                <a href="/">
                  <img className={classes.logoRoot} src={Logo} alt="Navigation Logo" />
                </a>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <a href={currentMainNavigation[0]?.link}>
                  <img className={classes.logoRoot} src={Logo} alt="Navigation Logo" />
                </a>
              </AuthenticatedTemplate>
              <IconButton
                onClick={() => setMobileDrawer(true)}
              >
                <Menu classes={{
                  root: classes.hamburgerIconRoot,
                }}
                />
              </IconButton>
            </div>
            <Drawer
              anchor="top"
              open={mobileDrawerOpen}
              classes={{
                paperAnchorTop: classes.drawerPaper,
              }}
              onClose={toggleDrawer('top', false)}
              onOpen={toggleDrawer('top', true)}
            >
              <div className={classes.closeButtonContainer}>
                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  onClick={() => { setMobileDrawer(false); }}
                >
                  <Close classes={{
                    root: classes.closeIconRoot,
                  }}
                  />
                </IconButton>
              </div>
              <div className={classes.mobileLogo}>
                <img src={MobileIcon} alt="Alpha Id Icon" />
              </div>
              <div>
                <List classes={{
                  root: classes.utilityNavigationRoot,
                }}
                >
                  {UTILITY_NAVIGATION.map((item) => {
                    if (item.interstitial) {
                      return (
                        <ListItem
                          disableGutters
                          classes={{
                            root: classes.listItemRoot,
                          }}
                          key={item.name}
                          onClick={() => setUtilityNavigationModal(true)}
                        >
                          {item.name}
                        </ListItem>
                      );
                    }
                    return (
                      <ListItem
                        disableGutters
                        classes={{
                          root: classes.listItemRoot,
                        }}
                        button
                        component={Link}
                        onClick={() => setMobileDrawer(false)}
                        to={item.link}
                      >
                        {item.name}
                      </ListItem>
                    );
                  })}
                  <UnauthenticatedTemplate>
                    <ListItem
                      disableGutters
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      button
                      onClick={handleLogin}
                    >
                      Login
                    </ListItem>
                  </UnauthenticatedTemplate>
                  <AuthenticatedTemplate>
                    <ListItem
                      disableGutters
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      button
                      onClick={handleLogout}
                    >
                      Log Out
                    </ListItem>
                  </AuthenticatedTemplate>
                </List>
              </div>
            </Drawer>
          </AppBar>
        </div>
        <InterstitialPrompt
          modalOpen={utilityNavigationModal}
          setModalOpen={setUtilityNavigationModal}
          siteUrl="https://www.prolastin.com/en/hcp"
          copy='You will be directed to a product website to learn more about a treatment option for Alpha-1 antitrypsin deficiency. If you would like to continue to the site, click "OK."If you do not wish to leave this site, select "Close."'
          title="You are leaving the AlphaID Results Portal."
        />
      </>
    );
  }
  return (
    <>
      <div className={classes.mobileNavRoot}>
        <AppBar classes={{
          root: classes.appBarRoot,
        }}
        >
          <div className={classes.headerContainer}>
            <UnauthenticatedTemplate>
              <a href="/">
                <img className={classes.logoRoot} src={Logo} alt="Navigation Logo" />
              </a>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <a href={currentMainNavigation[0]?.link}>
                <img className={classes.logoRoot} src={Logo} alt="Navigation Logo" />
              </a>
            </AuthenticatedTemplate>
            <IconButton
              onClick={() => setMobileDrawer(true)}
            >
              <Menu classes={{
                root: classes.hamburgerIconRoot,
              }}
              />
            </IconButton>
          </div>
          <Drawer
            anchor="top"
            open={mobileDrawerOpen}
            classes={{
              paperAnchorTop: classes.drawerPaper,
            }}
            onClose={toggleDrawer('top', false)}
            onOpen={toggleDrawer('top', true)}
          >

            <div className={classes.closeButtonContainer}>
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                onClick={() => { setMobileDrawer(false); }}
              >
                <Close classes={{
                  root: classes.closeIconRoot,
                }}
                />
              </IconButton>
            </div>
            <div className={classes.mobileLogo}>
              <img src={MobileIcon} alt="Alpha Id Icon" />
            </div>
            <div>
              <List classes={{
                root: classes.listRoot,
              }}
              >
                {currentMainNavigation.map((item) => (
                  <ListItem
                    disableGutters
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    button
                    component={Link}
                    onClick={() => setMobileDrawer(false)}
                    to={item.link}
                    key={item.name}
                  >
                    {item.name}
                    <ListItemIcon classes={{
                      root: classes.listIconRoot,
                    }}
                    >
                      <ChevronRight />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
              <List classes={{
                root: classes.utilityNavigationRoot,
              }}
              >
                {UTILITY_NAVIGATION.map((item) => {
                  if (item.interstitial) {
                    return (
                      <ListItem
                        disableGutters
                        classes={{
                          root: classes.listItemRoot,
                        }}
                        key={item.name}
                        onClick={() => setUtilityNavigationModal(true)}
                      >
                        {item.name}
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem
                      disableGutters
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      button
                      component={Link}
                      onClick={() => setMobileDrawer(false)}
                      to={item.link}
                    >
                      {item.name}
                    </ListItem>
                  );
                })}
                <UnauthenticatedTemplate>
                  <ListItem
                    disableGutters
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    button
                    onClick={handleLogin}
                  >
                    Login
                  </ListItem>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <ListItem
                    disableGutters
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    button
                    onClick={handleLogout}
                  >
                    Log Out
                  </ListItem>
                </AuthenticatedTemplate>
              </List>
            </div>
          </Drawer>
        </AppBar>
      </div>
      <InterstitialPrompt
        modalOpen={utilityNavigationModal}
        setModalOpen={setUtilityNavigationModal}
        siteUrl="https://www.prolastin.com/en/hcp"
        copy='You will be directed to a product website to learn more about a treatment option for Alpha-1 antitrypsin deficiency. If you would like to continue to the site, click "OK."If you do not wish to leave this site, select "Close."'
        title="You are leaving the AlphaID Results Portal."
      />
    </>
  );
};

export default MobileNavigation;
