import React, { useEffect, useState } from 'react';
import { CircularProgress, Hidden, InputAdornment, makeStyles, Input } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import querystring from 'query-string';
import { COLORS } from '../utils/applicationConstants';
import { getPractices } from '../api/PracticesService';
import PracticeTable from '../components/PracticeTable';
import PracticeTableMobile from '../components/PracticeTableMobile';
import TablePagination from '../components/Pagination';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    marginTop: '72px',
    color: COLORS.ABBEY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  displayButton: {
    backgroundColor: 'inherit',
    color: COLORS.ABBEY,
    width: '30px',
    height: '19px',
    left: '296px',
    top: '697px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&:focus': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    '&.selected': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  searchContainer: {
    display: 'flex',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginBottom: '24px',
    },
  },
  searchInput: {
    font: '13px',
    backgroundColor: COLORS.WILD_SAND,
    borderRadius: '30px',
    width: '100%',
    height: '50px',
    paddingLeft: '32px',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  searchIconRoot: {
    paddingRight: '32px',
  },
}));

const Practices = () => {
  function parseUrlString() {
    const { search } = window.location;
    const parsedQuery = querystring.parse(search);
    return parsedQuery;
  }
  const [isLoaded, setLoadedState] = useState(false);
  const classes = useStyles();
  const [practiceResults, setPracticeResults] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [searchBar, setSearchBar] = useState(
    parseUrlString().search ? parseUrlString().search : '',
  );
  const [emailFilter, setEmailFilter] = useState('');

  useEffect(async () => {
    setLoadedState(true);
    setIsTableLoading(true);
    const practiceResultsResponse = await getPractices(page + 1, pageSize, emailFilter);
    setIsTableLoading(false);
    setTotalPages(practiceResultsResponse.totalPages);
    setTotalRecords(practiceResultsResponse.totalRecords);
    setPracticeResults(practiceResultsResponse.data);
  }, [page, pageSize, emailFilter]);

  if (!isLoaded) {
    return <CircularProgress />;
  }
  function handleViewButton(viewSize) {
    setPage(0);
    setPageSize(viewSize);
  }
  function handlePageChange(params) {
    setPage(params.page);
  }
  function getFirstRowNumber() {
    if (page === 0) {
      return 1;
    }
    if (practiceResults.length < pageSize) {
      return (pageSize * page) + 1;
    }
    return (practiceResults.length * page) + 1;
  }
  function getLastRowNumber() {
    if (page === 0) {
      if (practiceResults.length < pageSize) {
        return practiceResults.length;
      }
      return pageSize;
    }
    return (getFirstRowNumber() + practiceResults.length) - 1;
  }
  function handlePagination(selectedPage) {
    setPage(selectedPage);
  }
  function currentSearchInputHandler(event) {
    setSearchBar(event.target.value);
  }
  async function handleSearch() {
    setEmailFilter(searchBar);
  }
  const handleEnterToSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };
  return (
    <div>
      <h1 className={classes.pageHeader}>
        My Practices
      </h1>
      <p>
        Below is a list of all Group Account Managers who have an
        active portal account. By clicking on any of the users
        listed below, you can open a list of all HCPs under their
        practice and either add or remove users from that practice.
      </p>
      <div
        className={classes.searchContainer}
        style={{ float: 'right' }}
      >
        <Hidden smDown>
          <Input
            placeholder="Search by Account Manager email"
            className={classes.searchInput}
            value={searchBar}
            onChange={currentSearchInputHandler}
            onKeyPress={handleEnterToSubmit}
            endAdornment={(
              <InputAdornment
                position="end"
                onClick={handleSearch}
                style={{
                  cursor: 'pointer',
                }}
              >
                {' '}
                <Search classes={{
                  root: classes.searchIconRoot,
                }}
                />
                {' '}
              </InputAdornment>
            )}
          />
        </Hidden>
      </div>
      <Hidden smDown>
        <div className={classes.buttonGroup}>
          <span>
            {' '}
            {totalRecords}
            {' '}
            results
            {' '}
          </span>
          <span className={classes.viewText}>View:</span>
          <button
            type="button"
            className={pageSize === 15 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(15)}
          >
            15
          </button>
          <button
            type="button"
            className={pageSize === 30 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(30)}
          >
            30
          </button>
          <button
            type="button"
            className={pageSize === 45 ? `${classes.displayButton} selected` : classes.displayButton}
            onClick={() => handleViewButton(45)}
          >
            45
          </button>
        </div>
        <PracticeTable
          results={practiceResults}
          handleViewHandler={handleViewButton}
          handlePageChangeHandler={handlePageChange}
          isTableLoading={isTableLoading}
        />
        <TablePagination
          selectedPage={page}
          numbers={totalPages}
          handlePageChange={handlePagination}
          records={totalRecords}
          firstRow={getFirstRowNumber()}
          lastRow={getLastRowNumber()}
        />
      </Hidden>
      <Hidden mdUp>
        <PracticeTableMobile
          results={practiceResults}
          queryFilters={{
          }}
        />
      </Hidden>
    </div>
  );
};

export default Practices;
