import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, ACCOUNT_TYPES } from '../utils/applicationConstants';
import { StarIcon } from '../utils/Icons';
import UpdateResultPriority from '../api/UpdatePriority';
import AccountContext from '../contexts/AccountContext';

const PriorityButton = (props) => {
  const { account } = useContext(AccountContext);
  const useStyles = makeStyles((theme) => ({
    priority: {
      right: '10%',
      marginTop: '-5px',
      marginRight: '-20px',
      position: 'absolute',
      background: COLORS.WILD_SAND,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
      border: 'none',
      borderRadius: '20px',
      height: '30px',
      width: '30px',
      [theme.breakpoints.down('sm')]: {
        right: account.accountType === ACCOUNT_TYPES.HCP ? '32px' : '-14px',
        width: '30px',
        border: 'none',
        height: '30px',
        position: 'absolute',
        background: '#F4F4F4',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 15%)',
        marginLeft: '96%',
        borderRadius: '20px',
        marginTop: '5px',
        paddingLeft: '6px',
        paddingTop: '1px',
      },
      '&.rep': {
        right: '-21%',
      },
    },
    starIconDiv: {
      marginTop: '6px',
      marginLeft: '2px',
    },
  }));
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const { isPriority, handleFilterCard, updateRowPriorityRendering, rowId, display } = props;
  // eslint-disable-next-line no-unused-vars
  const [priority, setPriority] = useState(isPriority);
  const handlePriorityClick = async () => {
    if (priority) {
      if (active) {
        handleFilterCard(1);
      } else {
        handleFilterCard(-1);
      }
    } else if (active) {
      handleFilterCard(-1);
    } else {
      handleFilterCard(1);
    }
    updateRowPriorityRendering(rowId);
    setActive(!active);
    await UpdateResultPriority(props.rowId);
  };

  const handleChangeColor = () => {
    if (priority) {
      if (active) {
        return COLORS.SILVER;
      }
      return COLORS.WEB_ORANGE;
    }
    if (active) {
      return COLORS.WEB_ORANGE;
    }
    return COLORS.SILVER;
  };

  return (

    <button
      type="button"
      tabIndex={-1}
      onKeyDown={handlePriorityClick}
      className={display === 'priority-desktop-rep' ? `${classes.priority} rep` : classes.priority}
      onClick={handlePriorityClick}
    >
      <div className={classes.starIconDiv}>
        <StarIcon color={handleChangeColor()} size={22} />
      </div>
    </button>
  );
};

export default PriorityButton;
