import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { useTableStylesHcp } from '../styles/TableStylesHcp';
import { COLORS } from '../utils/applicationConstants';
import { SortIcon } from '../utils/Icons';

const useStyles = makeStyles(({
  iconWrapper: {
    marginTop: '10px',
    marginLeft: '6px',
  },
  columnHeader: {
    color: COLORS.WHITE,
    textTransform: 'none',
    height: '64px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    width: '100%',
    '&:disabled': {
      color: COLORS.WHITE,
    },
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  menu: {
    padding: '1rem',
    marginTop: '1rem',
  },
  noRowsOverlay: {
    paddingTop: '2rem',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noRowsHeader: {
    fontSize: '22px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
  },
  nowRowsParagraph: {
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    zIndex: 20,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  row: {
    width: '100% !important',
  },
  managerViewRenderingZone: {
    width: '100% !important',
  },
  approve: {
    backgroundColor: COLORS.AZURE_RAD,
    color: 'white',
    marginRight: '0.5rem',
    '&:hover': {
      backgroundColor: COLORS.AZURE,
    },
  },
  actionRequired: {
    width: '100%',
  },
  locations: {
    lineHeight: '10 px',
  },
}));

const PendingAccountsTable = (props) => {
  const classes = useTableStylesHcp();
  const styleClasses = useStyles();
  const {
    results, handlePageChangeHandler,
    isTableLoading, toggleApproveModal, toggleDenyModal,
  } = props;
  const { pageNumber, pageSize, totalRecords } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;

  function TableSortIcon() {
    const hcpTableStyles = useTableStylesHcp();
    return (
      <span
        className={hcpTableStyles.statusIcon}
        style={{
          marginTop: '13px',
        }}
      >
        <SortIcon color={COLORS.WHITE} />
      </span>
    );
  }
  const RenderNpis = (params) => (
    <div>
      {params.row.npi}
      {params.row.npi !== '' && params.row.npIs !== null && ','}
      {params.row.npIs}
    </div>
  );
  const PendingAccountActionRender = (params) => (
    <div>
      <Button
        className={styleClasses.approve}
        onClick={() => toggleApproveModal(
          {
            id: params.row.id,
            name: params.row.name,
          },
        )}
      >
        Approve
      </Button>
      <Button
        className={classes.btn}
        onClick={() => toggleDenyModal(
          {
            id: params.row.id,
            name: params.row.name,
          },
        )}
      >
        Deny
      </Button>
    </div>
  );
  function CustNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.noRowsOverlay}>
          <Typography className={styleClasses.noRowsHeader}>
            There are currently no pending accounts.
          </Typography>
          <Typography className={styleClasses.nowRowsParagraph}>
            Please check back at a later time to check for new accounts.
          </Typography>
        </div>
      </GridOverlay>
    );
  }

  function CustLoadingOverlay() {
    return (
      <GridOverlay>
        <div className={styleClasses.loadingOverlay}>
          <CircularProgress fontSize="large" />
        </div>
      </GridOverlay>
    );
  }
  const RenderLocation = (params) => (
    <div>
      {params.row.locations.map((loc) => (
        <li key={loc}>
          {loc}
        </li>
      ))}
    </div>
  );

  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      width: 150,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p>Name</p>
        </Button>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      width: 250,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p>Email</p>
        </Button>
      ),
    },
    {
      field: 'npi',
      headerName: 'NPI',
      sortable: false,
      width: 110,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> NPI(s) </p>
        </Button>
      ),
      renderCell: RenderNpis,
    },
    {
      field: 'role',
      headerName: 'Role',
      sortable: false,
      width: 110,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> Role </p>
        </Button>
      ),
    },
    {
      field: 'zip',
      headerName: 'Zip',
      sortable: false,
      width: 70,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> Zip </p>
        </Button>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: false,
      width: 110,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> Phone </p>
        </Button>
      ),
    },
    {
      field: 'locations',
      headerName: 'Locations',
      sortable: false,
      minWidth: 200,
      resizable: true,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> Locations </p>
        </Button>
      ),
      renderCell: RenderLocation,
    },
    {
      field: 'action',
      headerName: 'action',
      sortable: false,
      width: 200,
      renderCell: PendingAccountActionRender,
      renderHeader: () => (
        <Button disabled className={styleClasses.columnHeader}>
          <p> Action </p>
        </Button>
      ),
    },
  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), maxWidth: '1280px', width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChangeHandler}
          hideFooterPagination
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          disableSelectionOnClick
          disableColumnResize
          disableExtendRowFullWidth
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            columnHeader: classes.columnHeader,
          }}
          rows={results}
          columns={columns}
          components={{
            ColumnSortedDescendingIcon: TableSortIcon,
            ColumnSortcendingIcon: TableSortIcon,
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
        />
      </div>
    </>
  );
};
export default PendingAccountsTable;
