import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { InputLabel, InputBase, Paper, Modal } from '@material-ui/core';
import ButtonComponent from './common/ButtonComponent';
import { isValidZipCode } from '../utils/helpers';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '100%',
  },
  inputLabel: {
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    color: '#58595B',
  },
  inputStyle: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '100%',
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
  error: {
    marginTop: '6px',
    color: 'red',
  },
}));

const EditManagedAccountModal = (props) => {
  const { hcpRow, modalOpen, setModalOpen, editHandler } = props;

  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    zip: '',
  });

  function handleOnChange(event) {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    const userInformationCopy = {
      ...formData,
    };
    userInformationCopy[inputName] = inputValue;
    setFormData(userInformationCopy);
  }

  function handleModalClose() {
    setFormData({
      firstName: '',
      lastName: '',
      zip: '',
    });
    setModalOpen(false);
  }

  return (
    <Modal
      onClose={handleModalClose}
      open={modalOpen}
    >
      <Paper className={classes.modalBackground}>
        <div className={classes.inputContainer}>
          <InputLabel htmlFor="zip" className={classes.inputLabel}>Zip Code</InputLabel>
          <InputBase
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleOnChange}
            className={classes.inputStyle}
            placeholder={hcpRow.zip}
            error={formData.zip.trim().length !== 0 && !isValidZipCode(formData.zip)}
          />

          <InputLabel htmlFor="firstName" className={classes.inputLabel}>First Name</InputLabel>
          <InputBase
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleOnChange}
            className={classes.inputStyle}
            placeholder={hcpRow.firstName}
          />

          <InputLabel htmlFor="lastName" className={classes.inputLabel}>Last Name</InputLabel>
          <InputBase
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleOnChange}
            className={classes.inputStyle}
            placeholder={hcpRow.lastName}
          />

          <ButtonComponent
            disabled={
            (
            // disable if all of the fields are empty, or if there is a zip code that is invalid
              formData.firstName.trim().length === 0
              && formData.lastName.trim().length === 0
              && formData.zip.trim().length === 0)
              || (formData.zip.trim().length !== 0 && !isValidZipCode(formData.zip))
            }
            onClick={() => editHandler(formData)}
          >
            Update Managed Account
          </ButtonComponent>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditManagedAccountModal;
