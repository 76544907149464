import React from 'react';
import { makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { COLORS } from '../utils/applicationConstants';
import LearningCenterImage from '../images/learningCenterImage.jpg';
import AccordionComponent from '../components/common/AccordionComponent';
import ButtonComponent from '../components/common/ButtonComponent';
import FaqQuestionCopy from '../api/copy/FaqQuestionCopy.json';

const useStyles = makeStyles((theme) => ({
  learningCenterRoot: {
    color: COLORS.ABBEY,
  },
  pageHeader: {
    fontFamily: 'Roboto Condensed',
    fontSize: '46px',
    lineHeight: '54px',
    color: COLORS.ABBEY,
    fontWeight: 'normal',
  },
  pageHeaderRoot: {
    borderBottom: `1px solid ${COLORS.ATHENS_GREY}`,
    paddingBottom: '48px',
  },
  subHeader: {
    fontWeight: 'normal',
  },
  sectionTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '37px',
  },
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '75px',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  learningCenterImage: {
    borderRadius: '15px',
    width: '100%',
  },
  sectionCopy: {
    lineHeight: ' 30px',
    maxWidth: '570px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  figCaption: {
    fontSize: '13px',
    paddingTop: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '54px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  downloadButtonGroup: {
    marginTop: '0',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonSetWidth: {
    width: '260px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
const LearningCenter = () => {
  const classes = useStyles();
  return (
    <div className={classes.learningCenterRoot}>
      <div className={classes.pageHeaderRoot}>
        <h1 className={classes.pageHeader}>Learning Center</h1>
        <p className={classes.subHeader}>
          Learn about AlphaID Screening and browse our Frequently Asked Questions.
        </p>
      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.leftPanel}>
          <div>
            <h2 className={classes.sectionTitle}>AlphaID Screening</h2>
            <p className={classes.sectionCopy}>
              AlphaID merges simple sample collection together with a comprehensive, FDA-cleared
              AAT deficient genotype detecting assay to identify known clinically-relevant
              genetic variations. AlphaID is provided with compliments of
              Grifols. Analysis is conducted by a certified, independent laboratory
              free of charge and may not be billed to your patients or their insurers.
              Results are strictly confidential and only shared with the ordering clinician.
            </p>
            <p className={classes.sectionCopy}>
              AlphaID detects most common, rare, and null alleles associated with AAT Deficiency
              disease. If a positive result is found using the swab, a follow-up test is available,
              AlphaID Confirm. The Confirm kit uses a finger stick and dry blood spot card to assess
              A1AT protein levels as well as confirm the genotype from the initial swab result.
              Occasionally, there are triggers from the lab and/or requests from the physician to
              run next-generation sequencing (NGS) to detect rare alleles outside of the
              current 14-allele assay.
            </p>
            <p className={classes.sectionCopy}>
              Visit AlphaID.com to learn more about AlphaID or AAT deficiency.
              To order additional swab kits, please contact your local representative
              or visit the link below. Please contact your Grifols representative
              for AlphaID Confirm Kits.
            </p>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonComponent
              className={classes.buttonSetWidth}
              href="//www.alphaid.com/en/hcp/home"
            >
              Visit AlphaId
            </ButtonComponent>
            <ButtonComponent
              className={classes.buttonSetWidth}
              type="secondary"
              href="https://www.alphaid.com/en/hcp/order-free-alphaid-screening-kits"
            >
              Order More Swab Kits
            </ButtonComponent>
          </div>
        </div>
        <div className={classes.rightPanel}>
          <figure>
            <img
              className={classes.learningCenterImage}
              src={LearningCenterImage}
              alt="learning center placeholder"
            />
            <figcaption className={classes.figCaption}> Photo by Drew Hays (Unsplash)</figcaption>
          </figure>
        </div>
      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.leftPanel}>
          <div>
            <h2 className={classes.sectionTitle}>Patient Resources</h2>
            <p className={classes.sectionCopy}>
              Download AlphaID resources to learn more about Alpha-1 and
              genetic testing with AlphaID.
            </p>
          </div>
          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              component="a"
              download
              href="/resources/Patient_Genetic_Counseling_Program_Guide_for_Patients.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              Genetic Counseling Program Guide for Patients
            </ButtonComponent>
            <ButtonComponent
              component="a"
              download
              href="/resources/Patient_A_Guide_for_the_Recently_Diagnosed.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              A Guide for the Recently Diagnosed
            </ButtonComponent>
          </div>
          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              mb
              component="a"
              download
              href="/resources/Patient_What_is_Alpha-1.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              What is Alpha-1?
            </ButtonComponent>
            <ButtonComponent
              component="a"
              download
              href="/resources/Patient_What is Alpha-1_(Spanish_version).pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              What is Alpha-1? (Spanish version)
            </ButtonComponent>
          </div>
          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              component="a"
              download
              href="/resources/Patient_It’s_All_in_the_Family.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              It’s All in the Family
            </ButtonComponent>
            <ButtonComponent
              component="a"
              download
              href="/resources/Patient_Am_I_an_Alpha-1_Carrier.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              Am I an Alpha-1 Carrier?
            </ButtonComponent>
          </div>
        </div>

      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.leftPanel}>
          <div>
            <h2 className={classes.sectionTitle}>Provider Resources</h2>
            <p className={classes.sectionCopy}>
              Download AlphaID resources to share with your patient during their next visit
            </p>
          </div>
          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              component="a"
              download
              href="/resources/Provier_Genetic_Counseling_Program_Guide_for_HCPs.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              Genetic Counseling Program Guide for HCPs
            </ButtonComponent>
            <ButtonComponent
              component="a"
              download
              href="/resources/Provider_AlphaID_Detail_Aid.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              AlphaID Detail Aid
            </ButtonComponent>
          </div>
          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              component="a"
              download
              href="/resources/Provider_AlphaID 14_Genetic_Variants_Brochure_2021.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              AlphaID 14 Genetic Variants Brochure
            </ButtonComponent>
          </div>

          <div className={`${classes.buttonContainer} ${classes.downloadButtonGroup}`}>
            <ButtonComponent
              component="a"
              download
              href="/resources/Provider_AlphaID_FAQ.pdf"
              type="secondary"
              endIcon={<GetApp />}
            >
              AlphaID FAQ
            </ButtonComponent>
          </div>
        </div>
      </div>

      <div className={classes.faqSectionRoot}>
        <h2 className={classes.sectionTitle}>Frequently Asked Questions</h2>
        {FaqQuestionCopy.faqs.map((item) => (
          <AccordionComponent
            key={item.title}
            title={item.title}
            copy={item.copy}
          />
        ))}
      </div>
    </div>
  );
};

export default LearningCenter;
