import { axiosInstance } from '../utils/AxiosConfiguration';

async function getUser() {
  let user;
  try {
    user = await axiosInstance.get('GetUser');
  } catch (e) {
    Error(e);
  }
  return user.data;
}

async function getAllActiveAndPendingAccounts() {
  let accountRepResponse;
  try {
    accountRepResponse = await axiosInstance.get('/ActiveAccounts');
  } catch (e) {
    Error(e);
  }
  return accountRepResponse.data;
}

export default function migrateHcpsAsync(file) {
  const bodyFormData = new FormData();
  bodyFormData.append('hcp_migration', file);
  return axiosInstance.post('MigrateHcps', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function deghostAccount(formData) {
  let deghostResponse;
  try {
    deghostResponse = await axiosInstance.post('/DeghostAccount', {
      npi: formData.npi,
      zipCode: formData.zipCode,
      newAccountEmailAddress: formData.newAccountEmailAddress,
    });
  } catch (e) {
    return e.response;
  }

  return deghostResponse;
}

async function updateManagedAccountDetails(data) {
  let response;
  try {
    response = await axiosInstance.post('/UpdateManagedAccountDetails', data);
  } catch (e) {
    return e.response;
  }

  return response;
}

async function updateAccountManagerDetails(data) {
  let response;
  try {
    response = await axiosInstance.post('/UpdateAccountManagerDetails', data);
  } catch (e) {
    return e.response;
  }

  return response;
}

async function migrateHcpToManager(data) {
  let response;
  try {
    response = await axiosInstance.post('/MigrateHcpToAccountManager', data);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function deleteUser(email) {
  let response;
  try {
    response = await axiosInstance.delete(`/DeleteUser?email=${email}`);
  } catch (e) {
    return e.response;
  }

  return response;
}

export {
  getUser,
  getAllActiveAndPendingAccounts,
  migrateHcpsAsync,
  deghostAccount,
  updateManagedAccountDetails,
  updateAccountManagerDetails,
  migrateHcpToManager,
  deleteUser,
};
