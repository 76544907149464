import React from 'react';
import { makeStyles, Hidden, MenuItem, MenuList } from '@material-ui/core';
import Logo from '../images/logo.svg';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    width: '100%',
    borderTop: `1px solid ${COLORS.ZIRCON}`,
    paddingTop: '48px',
    marginTop: '200px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& p': {
      fontSize: '13px',
      lineHeight: '20px',
      color: COLORS.ABBEY,
      [theme.breakpoints.up('md')]: {
        width: '65%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '48px',
      },
    },
  },
  imageLogo: {
    width: '170px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '42px',
      marginLeft: '25px',
    },
  },
  mobileLogoContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginBottom: '40px',
  },
  mobileFooterDetailContainer: {
    textAlign: 'center',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  versionText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: COLORS.ABBEY,
    letterSpacing: '0.03em',
    [theme.breakpoints.up('md')]: {
      marginRight: '80px',
    },
  },
  footerLink: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
    justifyContent: 'center',
    color: COLORS.ABBEY,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  desktopFooterRow: {
    maxWidth: '1200px',
    margin: '0 auto',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  desktopLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const Footer = () => {
  const classes = useStyles();
  function renderLogos() {
    return (
      <>
        <img className={classes.imageLogo} src={Logo} alt="AlphaId Logo" />
      </>
    );
  }
  return (
    <div className={classes.footerRoot}>
      <Hidden smDown>
        <div className={classes.desktopFooterRow}>
          <div className={classes.desktopLogoContainer}>
            {renderLogos()}
          </div>
          <div className={classes.flexRow}>
            <MenuList>
              <MenuItem
                classes={{
                  root: classes.footerLink,
                }}
                component="a"
                href="https://www.alphaid.com/en/hcp/privacy-policy"
              >
                Privacy Policy
              </MenuItem>
            </MenuList>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileLogoContainer}>
          {renderLogos()}
        </div>
        <div className={classes.mobileFooterDetailContainer}>
          <MenuList>
            <MenuItem
              classes={{
                root: classes.footerLink,
              }}
              component="a"
              href="https://www.alphaid.com/en/hcp/privacy-policy"
            >
              Privacy Policy
            </MenuItem>
          </MenuList>
        </div>
      </Hidden>
    </div>
  );
};
export default Footer;
