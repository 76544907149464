import axios from 'axios';
import React, { useEffect, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import AccountContext from '../contexts/AccountContext';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URI,
});

const AxiosConfiguration = ({ msalId }) => {
  const { instance } = useMsal();
  const { account, setAccount } = useContext(AccountContext);

  /**
   * This is the method to set the account context at the axios configuration level.
   * This has to be broken away from the getUser service to avoid dependency
   * cycle issues.
   */
  async function accountContextSetting() {
    let user;
    if (account === '') {
      try {
        user = await axiosInstance.get('GetUser');
      } catch (e) {
        Error(e);
      }
      setAccount(user.data);
    }
  }

  useEffect(async () => {
    if (msalId) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${msalId}`;

      await accountContextSetting();
    }
  }, []);

  axiosInstance.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 403 && window.location.pathname !== '/contact-us') {
      instance.loginRedirect(loginRequest);
    }
    return Promise.reject(error);
  });
  return (
    <div />
  );
};

export { AxiosConfiguration, axiosInstance };
