import React, { useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { COLORS } from '../../utils/applicationConstants';
import lineChart from '../../images/lineChart.svg';
import RadioIcon from './RadioIcon';

const useStyles = makeStyles(() => ({
  filterCardRoot: {
    borderRadius: '15px',
    border: 'none',
    maxWidth: '467px',
    width: '100%',
    padding: '16px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',

  },
  insightsCardRoot: {
    backgroundColor: COLORS.SOLITUDE,
    background: `url(${lineChart}) no-repeat center`,
  },
  cardTitle: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '26px',
    marginLeft: '16px',
    color: COLORS.ABBEY,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignAtEnd: {
    alignItems: 'flex-end',
  },
  alignAtCenter: {
    justifyContent: 'center',
  },
  primaryButton: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.AZURE_RAD,
    border: 'none',
    padding: '15px 40px',
    borderRadius: '30px',
    lineHeight: '19px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  detailsText: {
    color: COLORS.ABBEY,
    textDecoration: 'underline',
    marginLeft: '1rem',
    fontSize: '13px',
    zIndex: 5,
  },
  tooltip: {
    backgroundColor: COLORS.WHITE,
    fontSize: '16px',
    color: COLORS.ABBEY,
    borderColor: COLORS.ABBEY,
    borderWidth: '1px',
    borderStyle: 'solid',
    fontWeight: 300,
  },
  tooltipHeader: {
    fontWeight: 'bold',
  },
}));
const AdditionalDetailsRadio = (props) => {
  const classes = useStyles();
  const { type, isActive, updateAccountType } = props;
  const [showTooltip, setShowToolTip] = useState(false);
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShowToolTip(false);
    } else if (event.key === 'Escape') {
      setShowToolTip(false);
    }
  }
  function handleDetailsClickClose() {
    setShowToolTip(false);
  }
  function cardBorderSet() {
    if (!isActive) {
      return '3px solid transparent';
    }
    if (type === 'grifolsRep') {
      return `3px solid ${COLORS.CARDINAL}`;
    }
    if (type === 'acctManager') {
      return `3px solid ${COLORS.ORANGE}`;
    }
    if (type === 'hcp') {
      return `3px solid ${COLORS.AZURE_RAD}`;
    }
    return '';
  }

  function getAccountTypeId() {
    if (type === 'hcp') {
      return 1;
    }
    if (type === 'acctManager') {
      return 2;
    }
    if (type === 'grifolsRep') {
      return 3;
    }
    return 1;
  }

  function baseCardAccountIdKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      updateAccountType(getAccountTypeId());
    } else if (event.key === 'Escape') {
      updateAccountType(getAccountTypeId());
    }
  }
  function handleDetailsClick() {
    setShowToolTip(!showTooltip);
  }
  function getDetailsTooltipText() {
    if (type === 'hcp') {
      return (
        <>
          <div className={classes.tooltipHeader}>Individual Account</div>
          <span>
            I am a healthcare provider who offers the AlphaID test
            for my patients. I may work alone or in a group. For now,
            I want to see results for my patients only. In the future,
            I may choose to allow someone I work with to view AlphaID results
            on my behalf, as well as those results that belong to other
            healthcare providers in my group.
          </span>
        </>
      );
    }
    if (type === 'acctManager') {
      return (
        <>
          <div className={classes.tooltipHeader}>Account Manager</div>
          <span>
            I would like to manage results for a group of healthcare providers.
            I work with a group of HCPs and need to view test results for multiple
            providers in the same group. I would like to have a list of all users in
            my practice, and either request that new users be added to my group as the
            practice grows, or remove those users who should no longer roll up to my practice.
          </span>
        </>
      );
    }
    return '';
  }

  function baseCard(title, cardColor) {
    return (
      <div
        role="button"
        onClick={() => updateAccountType(getAccountTypeId())}
        onKeyDown={baseCardAccountIdKeyDown}
        tabIndex={0}
        className={classes.filterCardRoot}
        style={{
          backgroundColor: cardColor,
          border: cardBorderSet(),
          cursor: 'pointer',
        }}
      >
        <RadioIcon type={type} isChecked={isActive} />
        <span className={classes.cardTitle}>{title}</span>
        <Tooltip
          placement="right"
          classes={{
            tooltip: classes.tooltip,
          }}
          leaveTouchDelay={6000}
          open={showTooltip}
          onClose={handleDetailsClickClose}
          title={getDetailsTooltipText()}
        >
          <span
            role="button"
            onClick={handleDetailsClick}
            onKeyDown={handleListKeyDown}
            tabIndex={0}
            className={classes.detailsText}
          >
            Details...
          </span>
        </Tooltip>
      </div>
    );
  }

  function renderCardFromType() {
    if (type === 'grifolsRep') {
      return baseCard('AlphaID Sales Representative', COLORS.TUTU);
    }
    if (type === 'acctManager') {
      return baseCard('Group Account Manager', COLORS.SAZERAC);
    }
    if (type === 'hcp') {
      return baseCard('Individual Account', COLORS.PATTENS_BLUE);
    }
    return null;
  }
  return renderCardFromType();
};

export default AdditionalDetailsRadio;
