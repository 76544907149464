import { axiosInstance } from '../utils/AxiosConfiguration';

async function getMyHcps(pageNumber, pageSize, queryFilters) {
  let response;
  try {
    response = await axiosInstance.post('/MyHCPs', {
      paginationFilter: {
        pageNumber,
        pageSize,
      },
      queryFilters,
    });
  } catch (e) {
    Error(e);
  }
  response.data.data.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    item.id = index + 1;
  });
  return response.data;
}

export { getMyHcps };
