import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { COLORS } from '../utils/applicationConstants';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    '&  .MuiPagination-ul': {
      background: '#FFFFFF',
      boxSizing: 'border- box',
    },
    '& .MuiPaginationItem-outlined': {
      border: '1px solid #DEDEDE',
      borderRadius: '0px',
      margin: '0px',
      width: '32px',
      height: '23px',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      background: '#58595B',
      color: 'white',
    },
  },
  paginationContainer: {
    paddingRight: '15px',
    paddingLeft: '10px',
    textAlign: 'right',
    fontSize: '13px',
    lineHeight: '15px',
    color: COLORS.ABBEY,
  },
  mobilepaginationContainer: {
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '15px',
    color: COLORS.ABBEY,
    '& .MuiPaginationItem-outlined': {
      margin: '0px',
      width: '42px',
      height: '42px',
    },
  },
  desktopRowCount: {
    Display: 'inline',
    float: 'left',
  },
  mobileRowCount: {
    margin: '32px 32px 15px 32px',
  },
  mobileLabelContainer: {
    marginBottom: '15px',
  },
});

const TablePagination = (props) => {
  const { numbers, selectedPage, handlePageChange, records, type, firstRow, lastRow } = props;
  const classes = useStyles();

  return (

    <div className={type === 'Mobile' ? classes.mobilepaginationContainer : classes.paginationContainer}>
      <div className={type === 'Mobile' ? classes.mobileRowCount : classes.desktopRowCount}>
        <div className={classes.mobileLabelContainer}>
          Showing
          {' '}
          {firstRow}
          {' '}
          -
          {' '}
          {lastRow}
          {' '}
          of
          {' '}
          {records}
        </div>
        <span>
          {' '}
          Page:
          {' '}
          {selectedPage + 1}
          /
          {numbers}
        </span>
      </div>
      <Hidden smDown>
        <Pagination
          className={classes.root}
          count={numbers}
          variant="outlined"
          shape="rounded"
          page={selectedPage + 1}
          boundaryCount={1}
          siblingCount={1}
          onChange={(event, value) => handlePageChange(value - 1)}
        />
      </Hidden>
      <Hidden mdUp>
        <Pagination
          className={classes.root}
          count={numbers}
          variant="outlined"
          shape="rounded"
          page={selectedPage + 1}
          boundaryCount={1}
          siblingCount={0}
          onChange={(event, value) => handlePageChange(value - 1)}
        />
      </Hidden>
    </div>
  );
};

export default TablePagination;
