import React, { useContext } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { GridOverlay } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { handleResultPdf } from '../api/DownloadResultPdf';
import Tooltip from '../components/tooltip';
import { useTableStyles } from '../styles/TableStyles';
import { COLORS, ACCOUNT_TYPES } from './applicationConstants';
import { WarningIcon } from './Icons';
import { abnormalValidator, isAbnormalGenotype } from './statusHelpers';
import AccountContext from '../contexts/AccountContext';

function getFullName(params) {
  if (params.row.lastName === '--' || params.row.firstName === '--') {
    return '--';
  }
  return `${params.row.lastName || ''},  ${params.row.firstName || ''}`;
}

function CustNoRowsOverlay() {
  const classes = useTableStyles();
  const { account } = useContext(AccountContext);
  return (
    <GridOverlay>
      <div className={classes.noRowsOverlay}>
        <SearchIcon fontSize="large" />
        <Typography className={classes.noRowsHeader}>
          Sorry, there are no results for your search.
        </Typography>
        { account.accountType === ACCOUNT_TYPES.AccountManager && (
        <Typography className={classes.nowRowsParagraph}>
          Please make sure you have entered your search correctly and verify
          that you have added the correct HCPs and locations to your practice
          under the My Practice tab. If you still cannot find your result, visit
          the Learning Center to learn about shelf life and turnaround time.
          If you continue to experience issues, visit Contact Us.
        </Typography>
        )}
        { account.accountType === ACCOUNT_TYPES.HCP && (
        <Typography className={classes.nowRowsParagraph}>
          Please make sure you have entered your search correctly. If you
          have entered the correct format and cannot find your result, please
          visit the Learning Center to learn about shelf life and turnaround time
        </Typography>
        )}
        { account.accountType === ACCOUNT_TYPES.GrifolsRep && (
          <Typography className={classes.nowRowsParagraph}>
            Please make sure you have entered your search correctly. If you
            have entered the correct format and cannot find your result, please
            visit the Learning Center to learn about shelf life and turnaround time
          </Typography>
        )}
      </div>
    </GridOverlay>
  );
}

function CustLoadingOverlay() {
  const classes = useTableStyles();
  return (
    <GridOverlay>
      <div className={classes.loadingOverlay}>
        <CircularProgress fontSize="large" />
      </div>
    </GridOverlay>
  );
}

const abnormalRenderer = (params) => {
  const classes = useTableStyles();
  return (
    <>
      {params.value}
      <div className={classes.abnormalIconWrapper}>
        <Tooltip
          content={abnormalValidator(params.row, params.field)}
          icon="warning"
        >
          <WarningIcon color={COLORS.AZURE} />
        </Tooltip>
      </div>
    </>
  );
};

function abnormalGenotypeRenderer(params) {
  if (isAbnormalGenotype(params.row)) {
    return (
      abnormalRenderer(params)
    );
  }
  if (params.value == null || params.value === '') {
    return '--';
  }
  return params.value;
}

function fullReportRender(params) {
  if (params.row.hasPdf) {
    return (
      <Button
        onClick={() => handleResultPdf(
          {
            resultId: params.row.id, lastName: params.row.lastName,
          },
        )}
      >
        <PictureAsPdfIcon />
      </Button>
    );
  }
  return (
    <div>--</div>
  );
}

function checkIfLocationEmpty(params) {
  if (params.row.locationAddress === (null || '')) {
    return '--';
  }
  return params.row.locationAddress;
}

export { getFullName, CustNoRowsOverlay, CustLoadingOverlay, abnormalRenderer,
  abnormalGenotypeRenderer, fullReportRender, checkIfLocationEmpty };
