import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from './router/AppRouter';
import { AccountContextProvider } from './contexts/AccountContext';

function App({ pca }) {
  return (
    <>
      <AccountContextProvider>
        <MsalProvider instance={pca}>
          <AppRouter />
        </MsalProvider>
      </AccountContextProvider>
    </>
  );
}

export default App;
