import React, { useEffect, useState } from 'react';
import { Button, Menu, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
// eslint-disable-next-line no-unused-vars
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTableStyles } from '../styles/TableStyles';
import { ClearFilterIcon, SortIcon } from '../utils/Icons';
import { ACCOUNT_TYPES, COLORS } from '../utils/applicationConstants';
import UpdateResultPriority from '../api/UpdatePriority';
import PriorityButton from './PriorityButton';
import { CustLoadingOverlay, CustNoRowsOverlay, getFullName } from '../utils/dataTableHelpers';

// eslint-disable-next-line no-unused-vars
const RepDataTable = (props) => {
  const classes = useTableStyles();
  // eslint-disable-next-line max-len
  const { results, handlePageChangeHandler, queryFilters, handleQueryFilters, filterLists, isTableLoading, incrementPriority, updateRowPriorityRendering, headersList, handleHeaderList } = props;
  const { totalRecords, pageSize, pageNumber } = results;
  const lengthOfResults = results.length;
  const [tableHeight, setTableHeight] = useState(500);
  const cellHeight = 54;
  const [anchorAccountStatus, setAnchorAccountStatus] = useState();
  const [anchorAccountType, setAnchorAccountType] = useState();

  useEffect(async () => {
    const tHeight = ((lengthOfResults + 1) * cellHeight);
    setTableHeight(tHeight);
  }, [results]);

  const handleSortChange = (sort) => {
    if (sort === 'hcpOrManagerName') {
      switch (queryFilters.hcpOrManagerNameOrder) {
        case null: handleQueryFilters({
          ...queryFilters,
          hcpOrManagerNameOrder: 'desc',
        }); break;
        case undefined: handleQueryFilters({
          ...queryFilters,
          hcpOrManagerNameOrder: 'desc',
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          hcpOrManagerNameOrder: 'desc',
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          hcpOrManagerNameOrder: 'asc',
        }); break;
        default: break;
      }
    }
    if (sort === 'accountManagerName') {
      switch (queryFilters.accountManagerNameOrder) {
        case null: handleQueryFilters({
          ...queryFilters,
          accountManagerNameOrder: 'desc',
        }); break;
        case undefined: handleQueryFilters({
          ...queryFilters,
          accountManagerNameOrder: 'desc',
        }); break;
        case 'asc': handleQueryFilters({
          ...queryFilters,
          accountManagerNameOrder: 'desc',
        }); break;
        case 'desc': handleQueryFilters({
          ...queryFilters,
          accountManagerNameOrder: 'asc',
        }); break;
        default: break;
      }
    }
  };

  const handleCloseMenus = () => {
    setAnchorAccountStatus(null);
    setAnchorAccountType(null);
  };
  const handleFilterClose = (filter) => {
    if (filter === 'rep-account-status') {
      setAnchorAccountStatus(null);
    }
    if (filter === 'rep-account-type') {
      setAnchorAccountType(null);
    }
  };
  const handleFilterChange = (e, filter) => {
    if (filter === 'rep-account-status') {
      handleQueryFilters({
        ...queryFilters,
        accountStatus: e.target.value,
      });
      handleHeaderList({
        ...headersList,
        repAccountStatus: true,
      });
    }
    if (filter === 'rep-account-type') {
      switch (e.target.value) {
        case 'HCP':
          handleQueryFilters({
            ...queryFilters,
            role: ACCOUNT_TYPES.HCP,
          });
          break;
        case 'Account Manager':
          handleQueryFilters({
            ...queryFilters,
            role: ACCOUNT_TYPES.AccountManager,
          });
          break;
        default: handleQueryFilters({
          ...queryFilters,
          role: 0,
        });
      }
      handleHeaderList({
        ...headersList,
        repAccountType: true,
      });
    }
  };
  const handleAnchorAccountStatus = (event) => {
    setAnchorAccountStatus(event.currentTarget);
  };
  const handleAnchorAccountType = (event) => {
    setAnchorAccountType(event.currentTarget);
  };

  const AccountManagerRenderer = (params) => {
    const { row } = params;
    return row.accountManager || '--';
  };

  const columns = [
    {
      field: 'accountName',
      width: 230,
      renderCell: getFullName,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={() => handleSortChange('hcpOrManagerName')}>
          <p> Name </p>
          <div className={classes.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
      sortable: false,
    },
    {
      field: 'zip',
      headerName: 'Zip',
      headerClassName: classes.columnHeader,
      width: 140,
      sortable: false,
    },
    {
      field: 'accountTypeDescription',
      headerName: 'Account Type',
      headerClassName: headersList.repAccountType ? classes.headerFilterOn
        : classes.headerFilterOff,
      width: 170,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorAccountType}>
          <p> Account Type </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
      sortable: false,
    },
    {
      field: 'accountManager',
      width: 200,
      renderCell: AccountManagerRenderer,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={() => handleSortChange('accountManagerName')}>
          <p> Account Manager </p>
          <div className={classes.sortIcon}><SortIcon color={COLORS.WHITE} /></div>
        </Button>
      ),
      sortable: false,
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
      headerClassName: headersList.repAccountStatus ? classes.headerFilterOn
        : classes.headerFilterOff,
      width: 170,
      renderHeader: () => (
        <Button className={classes.headerButton} onClick={handleAnchorAccountStatus}>
          <p> Account Status </p>
          <div className={classes.filterIcon}><KeyboardArrowDownIcon /></div>
        </Button>
      ),
      sortable: false,
    },
    {
      field: 'quantityPosted',
      headerName: 'Quantity Posted',
      width: 150,
      sortable: false,
    },
    {
      field: 'quantityActionReq',
      headerName: 'Quantity Action Required',
      width: 160,
      sortable: false,
      headerClassName: 'status-header',
      cellClassName: 'status-col',
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <p>{row.quantityActionReq}</p>
            <PriorityButton updateRowPriorityRendering={updateRowPriorityRendering} handleFilterCard={incrementPriority} isPriority={row.isPriority} rowId={row.id} display="priority-desktop-rep" />
          </>
        );
      },
    },

  ];
  return (
    <>
      <div
        className={classes.root}
        style={{
          height: (`${tableHeight}px`), width: '100%', minHeight: (results.length === 0 ? '300px' : `${results.length * 54 + 80}px`),
        }}
      >
        <DataGrid
          page={pageNumber}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChangeHandler}
          hideFooterPagination
          checkboxSelection
          selectionModel={results.map((res) => (res.isPriority ? res.id : 0))}
          hideFooter
          loading={isTableLoading}
          disableColumnSelector
          classes={{
            root: classes.root,
            cell: classes.cell,
            row: classes.row,
            columnHeader: classes.columnHeader,
          }}
          rows={results}
          columns={columns}
          components={{
            NoRowsOverlay: CustNoRowsOverlay,
            LoadingOverlay: CustLoadingOverlay,
          }}
          disableSelectionOnClick
          onRowSelected={(e) => UpdateResultPriority(e.data.id)}
        />
        <Menu
          id="rep-account-status-menu"
          anchorEl={anchorAccountStatus}
          open={Boolean(anchorAccountStatus)}
          onClose={() => handleFilterClose('rep-account-status')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="rep-account-status-filter"
              name="rep-account-status Filter"
              value={queryFilters.accountStatus}
              onChange={(e) => handleFilterChange(e, 'rep-account-status')}
            >
              {filterLists.accountStatus.map((option) => (
                <FormControlLabel
                  value={option}
                  // eslint-disable-next-line max-len
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'rep-account-status');
                handleHeaderList({
                  ...headersList,
                  repAccountStatus: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
        <Menu
          id="rep-account-type-menu"
          anchorEl={anchorAccountType}
          open={Boolean(anchorAccountType)}
          onClose={() => handleFilterClose('rep-account-type')}
        >
          <FormControl component="fieldset" className={classes.menu}>
            <FormLabel component="legend">Filter by:</FormLabel>
            <RadioGroup
              aria-label="rep-account-type-filter"
              name="rep-account-type Filter"
              value={queryFilters.accountType}
              onChange={(e) => handleFilterChange(e, 'rep-account-type')}
            >
              {filterLists.role.map((option) => (
                <FormControlLabel
                  value={option}
                  // eslint-disable-next-line max-len
                  control={(
                    <Radio classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    />
                  )}
                  label={(option === null || option === '') ? 'None' : option}
                />
              ))}
            </RadioGroup>
            <Button
              disabled={isTableLoading}
              className={classes.clearButton}
              onClick={(e) => {
                handleCloseMenus();
                handleFilterChange(e, 'rep-account-type');
                handleHeaderList({
                  ...headersList,
                  repAccountType: null,
                });
              }}
            >
              <ClearFilterIcon color={COLORS.ABBEY} />
              <p className={classes.clearText}>Clear filter</p>
            </Button>
          </FormControl>
        </Menu>
      </div>
    </>
  );
};
export default RepDataTable;
