/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { Chart, registerables } from 'chart.js';
import { COLORS } from '../../../utils/applicationConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  canvasWrapper: {
    width: '400px',
    minWidth: '200px',
    [theme.breakpoints.only('md')]: {
      width: 'auto',
      maxWidth: '50%',
      marginLeft: '25%',
      marginRight: '25%',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'auto',
      maxWidth: '75%',
      marginLeft: '12.5%',
      marginRight: '12.5%',
    },
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
    },
  },
  legendText: {
    textAlign: 'center',
    color: COLORS.ABBEY,
    fontWeight: 'bold',
  },
}));

const DoughnutChart = (props) => {
  const isDesktop = useMediaQuery('(min-width:768px)');
  const classes = useStyles();
  const { dataSet } = props;
  const { data, title } = dataSet;
  const chartId = 'donut';
  const canvasRef = useRef();
  Chart.register(...registerables);
  useEffect(() => {
    const cutoutVal = isDesktop ? 90 : 55;
    const ctx = canvasRef.current.getContext('2d');
    canvasRef.current.style.backgroundColor = 'transparent';
    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'doughnut',
      data,
      options: {
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              family: 'Roboto Condensed',
              weight: 'normal',
              size: '22px',
            },
          },
          tooltip: {
            callbacks: {
              label(context) {
                return `${context.formattedValue}% ${context.label} Results`;
              },
            },
          },
        },
        cutout: cutoutVal,
      },
    });
  }, [chartId]);

  return (
    <div className={classes.container}>
      <div id="pie-chart" className={classes.canvasWrapper}>
        <canvas ref={canvasRef} id={chartId} />
      </div>
      <p className={classes.legendText}>
        {data.datasets[0].data[0]}% {data.labels[0]} Results
      </p>
      <p className={classes.legendText}>
        {data.datasets[0].data[1]}% {data.labels[1]} Results
      </p>

    </div>
  );
};

export default DoughnutChart;
